/*!

=========================================================
* Light Bootstrap Dashboard React - v1.3.0
=========================================================

* Product Page: https://www.creative-tim.com/product/light-bootstrap-dashboard-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/light-bootstrap-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React, { Component } from "react";
import { Route, Switch } from "react-router-dom";
import NotificationSystem from "react-notification-system";
import { Button, NavDropdown, MenuItem } from "react-bootstrap";

import AdminNavbar from "components/Navbars/AdminNavbar";
import Sidebar from "components/Sidebar/Sidebar";
import TabRouter from "./TabRouter";

import { style } from "variables/Variables.jsx";

import routes from "routes.js";
import image from "assets/img/sidebar-4.jpg";
import styles from './styles';
import ConfirmDeleteDB from "components/Modal/ConfirmDeleteDB";


class Admin extends Component {
  constructor(props) {
    super(props);
    this.state = {
      image: image,
      color: "blue",
      hasImage: true,
      fixedClasses: "dropdown show-dropdown open",
      //delete variables
      showModalDeleteDB: false,
    };
    const userData = JSON.parse(localStorage.getItem('USER'));

    this.userName = `${userData.name} ${userData.last_name}`;
    this.institution_id = userData.institution_id
  }

  getRoutes = routes => {
    return routes.map((prop, key) => {
      if (prop.layout === "/admin") {
        return (

          /* prop.path.includes('conf') && <div>AAAAAA</div> */
          <Route
            path={ prop.layout + prop.path }
            render={ props => (
              <prop.component
                { ...props }
                handleClick={ this.handleNotificationClick }
              />
            ) }
            key={ key }
          />
        );
      } else {
        return null;
      }
    });
  };
  getBrandText = path => {
    for (let i = 0; i < routes.length; i++) {
      if (
        this.props.location.pathname.indexOf(
          routes[i].layout + routes[i].path
        ) !== -1
      ) {
        return routes[i].name;
      }
    }
    return "Brand";
  };
  handleImageClick = image => {
    this.setState({ image: image });
  };
  handleColorClick = color => {
    this.setState({ color: color });
  };
  handleHasImage = hasImage => {
    this.setState({ hasImage: hasImage });
  };
  handleFixedClick = () => {
    if (this.state.fixedClasses === "dropdown") {
      this.setState({ fixedClasses: "dropdown show-dropdown open" });
    } else {
      this.setState({ fixedClasses: "dropdown" });
    }
  };

  componentDidUpdate(e) {
    if (
      window.innerWidth < 993 &&
      e.history.location.pathname !== e.location.pathname &&
      document.documentElement.className.indexOf("nav-open") !== -1
    ) {
      document.documentElement.classList.toggle("nav-open");
    }
  }


  render() {
    const { showModalDeleteDB } = this.state;
    return (
      <div className="wrapper">
        <NotificationSystem ref="notificationSystem" style={ style } />
        <Sidebar { ...this.props } routes={ routes } image={ this.state.image }
          color={ this.state.color }
          hasImage={ this.state.hasImage } />
        <div id="main-panel" className="main-panel" ref="mainPanel">
          <AdminNavbar
            { ...this.props }
            brandText="Aplicación Escolar"
            userName={ this.userName }
          />
          { this.props.location.pathname.includes('/admin/conf') && (
            <TabRouter
              title="Configuraciones"
              defaultActiveKey="/admin/conf/profile"
              activeKey={ this.props.location.pathname }
              history={ this.props.history }
              tabs={ [
                {
                  route: '/admin/conf/profile',
                  label: 'Mi perfil',
                },
                {
                  route: '/admin/conf/school',
                  label: 'Configuración de escuela',
                },
                {
                  route: '/admin/conf/pass',
                  label: 'Cambiar contraseña',
                },
                {
                  route: '/admin/conf/levels',
                  label: 'Niveles, Grados y Grupos',
                },
                {
                  route: '/admin/conf/cycles',
                  label: 'Configuración de ciclos',
                },
              ] }
            />
          ) }
          { this.props.location.pathname.includes('/admin/users-parents') && (
            <TabRouter
              title="Usuarios"
              defaultActiveKey="/admin/users-parents/users-list"
              activeKey={ this.props.location.pathname }
              history={ this.props.history }
              tabs={ [
                {
                  route: '/admin/users-parents/load-database',
                  label: 'Cargar Bases de Datos',
                },
                {
                  route: '/admin/users-parents/users-list',
                  label: 'Listar Usuarios',
                },
                {
                  route: '/admin/users-parents/roles-list',
                  label: 'Listar Roles',
                },
                {
                  route: '/admin/users-parents/parents-list',
                  label: 'Listar Padres',
                },
                {
                  route: '/admin/users-parents/students-list',
                  label: 'Listar Estudiantes',
                },
                {
                  route: '/admin/users-parents/load-photos',
                  label: 'Cargar fotografías',
                },
                {
                  route: '/admin/users-parents/delete-db',
                  label: 'Delete Database',
                  customTabComponent: (
                    <Button
                      bsStyle="danger"
                      style={ styles.navButtom }
                      simple
                      bsSize="lg"
                      title="Eliminar toda la base de datos"
                      onClick={ () => this.setState({ showModalDeleteDB: true }) }
                    >
                      <i className="fa fa-trash"></i>
                    </Button>
                  )
                },
              ] }
            />
          ) }
          {
            showModalDeleteDB &&
            <ConfirmDeleteDB
              onClose={ () => this.setState({ showModalDeleteDB: false }) }
              show={ showModalDeleteDB }
              institution_id={ this.institution_id }
            />
          }
          { this.props.location.pathname.includes('/admin/extra') && (
            <TabRouter
              title="Extracurriculares"
              defaultActiveKey="/admin/extra/index"
              activeKey={ this.props.location.pathname }
              history={ this.props.history }
              tabs={ [
                {
                  route: '/admin/extra/index',
                  label: 'Extracurriculares',
                },
                {
                  route: '/admin/extra/load',
                  label: 'Cargar extracurriculares'
                }] } />
          ) }
          { this.props.location.pathname.includes('/admin/message') && (
            <TabRouter
              title="Mensajes"
              defaultActiveKey="/admin/message/index"
              activeKey={ this.props.location.pathname }
              history={ this.props.history }
              tabs={ [
                {
                  route: '/admin/message/index',
                  label: 'Mensajes',
                },
                {
                  customTabComponent: (
                    <NavDropdown title={
                      <Button
                        bsStyle="default"
                        style={ styles.navButtom }
                        simple
                        bsSize="lg"
                      >
                        <span >Nuevo Mensaje</span> <b className="caret"></b>
                      </Button>
                    } noCaret id="basic-nav-dropdown">
                      <MenuItem onClick={ () => this.props.history.replace("/admin/message/new-student") }>Enviar a estudiante</MenuItem>
                      <MenuItem onClick={ () => this.props.history.replace('/admin/message/new-degree') }>Enviar a nivel, grado, grupo</MenuItem>
                      <MenuItem onClick={ () => this.props.history.replace('/admin/message/new-massive') }>Enviar masivo</MenuItem>
                      <MenuItem onClick={ () => this.props.history.replace('/admin/message/new-specific') }>Enviar específico</MenuItem>
                      <MenuItem onClick={ () => this.props.history.replace('/admin/message/new-extracurricular') }>Enviar extracurricular</MenuItem>
                    </NavDropdown>
                  )
                },
                {
                  route: '/admin/message/types',
                  label: 'Tipos de mensaje',
                },
                {
                  route: '/admin/message/history',
                  label: 'Historial',
                },
                {
                  route: '/admin/message/record',
                  label: 'Registro',
                }
              ] }
            />
          ) }
          { this.props.location.pathname.includes('/admin/monitor') && (
            <TabRouter
              title="Seguimientos"
              defaultActiveKey="/admin/monitor/index"
              activeKey={ this.props.location.pathname }
              history={ this.props.history }
              tabs={ [
                {
                  route: '/admin/monitor/attributes',
                  label: 'Atributos',
                },
                {
                  route: '/admin/monitor/load',
                  label: 'Cargar seguimientos'
                },
                {
                  route: '/admin/monitor/index',
                  label: 'Seguimientos académicos',
                },
                {
                  route: '/admin/monitor/record',
                  label: 'Registro',
                }
              ] } />
          ) }
          { this.props.location.pathname.includes('/admin/course') && (
            <TabRouter
              title="Materias"
              defaultActiveKey="/admin/course/index"
              activeKey={ this.props.location.pathname }
              history={ this.props.history }
              tabs={ [
                {
                  route: '/admin/course/index',
                  label: 'Materias',
                },
                {
                  route: '/admin/course/load',
                  label: 'Asignación de materias'
                }
              ] } />
          ) }
          { this.props.location.pathname.includes('/admin/homework') && (
            <TabRouter
              title="Tareas"
              defaultActiveKey="/homework/index"
              activeKey={ this.props.location.pathname }
              history={ this.props.history }
              tabs={ [] } />
          ) }
          { this.props.location.pathname.includes('/admin/score') && (
            <TabRouter
              title="Calificaciones"
              defaultActiveKey="/admin/score/index"
              activeKey={ this.props.location.pathname }
              history={ this.props.history }
              tabs={ [
                {
                  route: '/admin/score/index',
                  label: 'Calificaciones',
                },
                {
                  route: '/admin/score/load',
                  label: 'Cargar calificaciones',
                }] } />)
          }
          { this.props.location.pathname.includes('/admin/pay') && (
            <TabRouter
              title="Cobranza"
              defaultActiveKey="admin/pay/load"
              activeKey={ this.props.location.pathname }
              history={ this.props.history }
              tabs={ [
                {
                  route: '/admin/pay/index',
                  label: 'Registro de cobranzas',
                },
                {
                  route: '/admin/pay/load',
                  label: 'Cargar cobranza',
                },
                {
                  route: '/admin/pay/new',
                  label: 'Nuevo Pago',
                },
                {
                  route: '/admin/pay/penalty',
                  label: 'Modificar penalidad',
                },
              ] } />) }
          { this.props.location.pathname.includes('/admin/attendance') && (
            < TabRouter
              title="Asistencias"
              defaultActiveKey="/admin/attendance/index"
              activeKey={ this.props.location.pathname }
              history={ this.props.history }
              tabs={ [
                {
                  route: '/admin/attendance/index',
                  label: 'Listar asistencias',
                },
                {
                  route: '/admin/attendance/student',
                  label: 'Listar estudiantes'
                },
                {
                  route: '/admin/attendance/list/scan',
                  label: 'Listar scanners',
                },
                {
                  route: '/admin/attendance/new/scan',
                  label: 'Nuevo scanner',
                }
              ] } />
          ) }
          <Switch>{ this.getRoutes(routes) }</Switch>
        </div>
      </div>
    );
  }
}

export default Admin;
