import React from 'react'
import axios from 'components/axios';
import Button from 'components/CustomButton/CustomButton';
import { gRoute } from "variables/DomainRoutes.jsx";
import { Modal } from "react-bootstrap";

class ModalExcel extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      urlExcel: '',
      loading: true,
      error: false
    };
  }


  HandleExport = async () => {
    console.log(this.props.url)
    let response
    try {
      response = await axios.post(this.props.url) || {};
    } catch (error) {
      console.log(error)
    }
    if(response){
      const { data: { route } } = response;
      console.log(response)
      this.setState({
        urlExcel: route,
        loading: false,
        error: false
      });
    }else{
      this.setState({
        urlExcel: '',
        loading: false,
        error: true
      });
    }
  }

  componentDidUpdate() {
    const { isOpen } = this.props
    const { loading } = this.state
    if (isOpen && loading) {
      this.HandleExport()
    }
  }

  closeModal = () => {
    if (!this.state.loading) {
      this.setState({
        urlExcel: '',
        loading: true,
        error: false
      });
      this.props.controlModal(false)
    }
  }
  render() {
    const {
      isOpen,
    } = this.props;
    const {
      loading,
      urlExcel,
      error
    } = this.state;
    return (
      <Modal
        show={isOpen}
        onHide={this.closeModal}
        dialogClassName="modal-90w"
        aria-labelledby="example-custom-modal-styling-title"
      >
        <Modal.Header closeButton>
          <Modal.Title>
            Reporte
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <h5 className="text-center">
            {loading ? 'Espere un momento... Estamos generando su reporte' : !loading && !error ? 'Su reporte esta listo. Por favor de click en el boton "Descargar Excel"' : !loading && error && 'A ocurrido un error, contacte a soporte'}
          </h5>
          <a
            href={urlExcel !== '' && `${gRoute}${urlExcel}`}
            target="_blank"
            rel="noopener noreferrer">
            <Button
              bsStyle="primary"
              style={{ width: "100%" }}
            >
              {loading && (
                <i
                  className="fa fa-refresh fa-spin"
                  style={{ marginRight: "5px" }}
                />
              )}{loading ? 'Creando Excel' : !loading && !error ? 'Descargar Excel' : !loading && error && 'Error'}</Button>
          </a>
        </Modal.Body>
      </Modal>
    )
  }
}

export default ModalExcel
