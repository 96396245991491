import React from 'react'
import Button from 'components/CustomButton/CustomButton';
import { Form, FormGroup, FormControl, Col } from "react-bootstrap";
import styles from './styles'
import ModalNotification from 'components/Notifications/ModalNotification.jsx'
import { mensajeError500 } from "variables/Variables.jsx";
import axios from 'components/axios';
import Loader from 'react-loader-spinner'

class SearchInput extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      target: '',
    };
  }

  doSearch = async () => {
    const { target } = this.state;
    const { querySearch, search } = this.props;
    search(target,querySearch)
  }

  render() {
    const {
      target,
    } = this.state;
    const {
      fetchDetails,
      closeNotification
    } = this.props
    const {
      searchNotificationMessage,
      searchNotificationType,
      searchIsLoading,
      searchShowNotification,
    }  = fetchDetails
    return (
      <Form>
        <FormGroup>
          <Col md={ 6 }>
            <FormControl
              type="text"
              placeholder="Buscar"
              onChange={ (e) => this.setState({ target: e.target.value }) }
            />
          </Col>
          <Col md={ 2 }>
            <Button
              bsStyle="success"
              fill
              block
              style={ styles.searchButton }
              onClick={ () => this.doSearch() }
              disabled={ ( target === '' || searchIsLoading ) ? true : false }
            >Buscar</Button>
          </Col>
          <Col md={ 4 }>
            { searchIsLoading &&
              <>
                <Col md={ 4 }>
                  <Loader
                    type="Rings"
                    color="#00BFFF"
                    height={ 40 }
                    width={ 40 }
                    style={ { display: 'flex', justifyContent: 'center', marginTop: '2%' } }
                  />
                </Col>
                <Col md={ 6 }
                  style={ { display: 'flex', marginTop: '3%' } }
                >... Buscando</Col>
              </>
            }
          </Col>
        </FormGroup>
        <Col md={ 12 }>
          { searchShowNotification &&
            <ModalNotification
              notificationMessage={ searchNotificationMessage }
              closeNotification={ () => closeNotification() }
              type={ searchNotificationType }
            />
          }
        </Col>
      </Form>

    );
  }
}
export default SearchInput