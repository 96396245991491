import React, { Component } from "react";
import { Row, Table, Col, Pager } from "react-bootstrap";
import Button from 'components/CustomButton/CustomButton';
import ConfirmDeleteModal from 'components/Modal/ConfirmDeleteModal.jsx';
import Loader from 'react-loader-spinner'
import { mensajeError500 } from "variables/Variables.jsx";
import { gRoute } from "variables/DomainRoutes.jsx";
import axios from 'components/axios';
import ExtracurricularModal from 'components/Modal/ExtracurricularModal';
import ErrorNotification from 'components/Notifications/ErrorNotification.jsx'
import styles from './styles'
import SearchInput from 'components/Search/SearchInputFixed';
import ModalExcel from 'components/ModalExcel';

class ExtraList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: false,
      isDeleting: false,
      showConfirmDelete: false,
      actionName: "",
      selectedExtra: null,
      extras: [],
      totalOfPages: 0,
      totalOfRecords: 0,
      showNotification: false,
      notificationMessage: '',
      showExtracurricularModal: false,
      urlExcel: '',
      searchActive: false,
      searchShowNotification: false,
      searchTarget: '',
      searchQuerySearch: undefined,
      searchShowNotification: false,
      searchNotificationType: '',
      searchNotificationMessage: '',
      searchIsLoading: false,
    };
    this.controlModal = this.controlModal.bind(this);
  }

  controlModal(show) {
    this.setState({ openModal: show || false });
  }

  async componentDidMount() {
    this.fetchExtras();
  }

  componentDidUpdate(prevProps) {
    if (prevProps.currentPage !== this.props.currentPage) {
      if (this.state.searchActive === false) {
        this.fetchExtras();
      } else {
        this.onClickPagination();
      }
    }
  }

  fetchExtras = async () => {
    const { currentPage } = this.props;
    this.setState({ isLoading: true });
    try {
      const response = await axios.post('extracurricular/list', {
        current_page: currentPage,
      });
      this.setState({
        extras: response.data.extracurriculars.data,
        totalOfPages: response.data.extracurriculars.last_page,
        totalOfRecords: response.data.extracurriculars.total
      });
    } catch (err) {
      console.log('ERROR', err);
    } finally {
      this.setState({ isLoading: false });
    }
  }


  handleOpenConfirmDelete = (extra) => {
    this.setState({
      showConfirmDelete: true,
      actionName: extra.name,
      selectedExtra: extra,
    });
  };

  handleDelete = async () => {
    const { selectedExtra } = this.state;
    this.setState({ isDeleting: true });
    try {
      await axios.post(`extracurricular/delete/${selectedExtra.id}`);
      this.setState({ showConfirmDelete: false });
      this.fetchExtras();
    } catch (err) {
      if (typeof err.response !== 'undefined' && typeof err.response.data !== 'undefined') {
        if (err.response.status === 400) {
          this.setState({
            showNotification: true,
            notificationMessage: err.response.data.error
          });
        } else {
          this.setState({
            showNotification: true,
            notificationMessage: mensajeError500
          });
        }
        console.log('ERROR', err);
      }
      console.log('ERROR', err);
      this.setState({ isDeleting: false, showConfirmDelete: false });
    } finally {
      this.setState({ isDeleting: false });
    }
  }

  onClickPagination = () => {
    const { currentPage } = this.props;
    const { searchTarget, searchQuerySearch } = this.state
    this.fetchSearch(searchTarget, searchQuerySearch, currentPage);
  }

  onClickButtonSearch = (target, querySearch) => {
    this.setState({ searchTarget: target, currentPage: 1, searchQuerySearch: querySearch });
    this.props.restartPagination()
    this.fetchSearch(target, querySearch, 1);
  }

  fetchSearch = async (target, querySearch, currentPage) => {
    this.setState({ searchActive: true })
    this.setState({ searchIsLoading: true });

    try {
      var response = {}
      if (querySearch === true) {
        response = await axios.post('/extracurricular/search/extra', {
          page: currentPage,
          query: target
        });
      } else {
        response = await axios.post('/extracurricular/search/extra', {
          current_page: currentPage,
          name: target
        });
      }

      if (response.status === 200) {
        this.setState({
          searchShowNotification: true,
          searchNotificationType: "info",
          searchNotificationMessage: "Búsqueda finalizada. Se encontraron " + response.data.extracurricular.total + " coincidecias.",
        });
      }
      this.setState({
        extras: response.data.extracurricular.data,
        totalOfPages: response.data.extracurricular.last_page
      });
    } catch (err) {
      if (typeof err.response !== 'undefined' && typeof err.response.data !== 'undefined') {
        if (err.response.status === 400) {
          this.setState({
            searchShowNotification: true,
            searchNotificationType: "danger",
            searchNotificationMessage: err.response.data.error
          });
        } else {
          this.setState({
            searchShowNotification: true,
            searchNotificationType: "danger",
            searchNotificationMessage: mensajeError500
          });
        }
      } else {
        console.log("ERROR", err)
      }
    } finally {
      this.setState({ searchIsLoading: false });
    }
  }

  render() {
    const {
      detailExtra,
      currentPage,
      goToNextPage,
      goToPreviousPage,
    } = this.props;
    const {
      showConfirmDelete,
      actionName,
      isLoading,
      extras,
      showNotification,
      notificationMessage,
      showExtracurricularModal,
      selectedExtra,
      totalOfPages,
      totalOfRecords,
      urlExcel,
      loading,
      openModal
    } = this.state;
    return !isLoading ? (
      <>
        <Row>
          <ModalExcel url="/extracurricular/excel" isOpen={openModal} controlModal={this.controlModal} />
          <Col md={6}>
            <SearchInput
              search={this.onClickButtonSearch}
              fetchDetails={this.state}
              closeNotification={() => this.setState({ ...this.state, searchShowNotification: false })}
            />
          </Col>
          <Col md={6}>

              <Button
                bsStyle="primary"
                pullRight
                onClick={() => this.controlModal(true)}
                style={styles.topButton}>
                Exportar Excel</Button>
          </Col>
        </Row>
        <ConfirmDeleteModal
          onClose={() => this.setState({ showConfirmDelete: false })}
          show={showConfirmDelete}
          thingToRemoveType="extracurricular"
          thingToRemoveName={actionName}
          onConfirm={this.handleDelete}
        />
        <Row>
          <Col md={12}>
            {showNotification &&
              <Col md={11}>
                <ErrorNotification
                  notificationMessage={notificationMessage}
                  closeNotification={() => this.setState({ showNotification: false })}
                />
              </Col>
            }
            {showExtracurricularModal &&
              <ExtracurricularModal
                onClose={() => this.setState({ showExtracurricularModal: false, selectedExtra: null })}
                show={showExtracurricularModal}
                extraData={selectedExtra}
                reload={() => { this.setState({ showExtracurricularModal: false, selectedExtra: null }); this.fetchExtras() }}
              />}
            <Button
              bsStyle="primary" fill
              style={{ margin: "1%" }}
              onClick={() => this.setState({ showExtracurricularModal: true })}
            >
              Agregar Extracurricular
            </Button>
            <Table striped hover >
              <thead>
                <tr>
                  <th>Nombre</th>
                  <th>N° Estudiantes</th>
                  <th>Acciones</th>
                </tr>
              </thead>
              <tbody>
                {extras.map((extra) => {
                  return (
                    <tr key={extra.id}>
                      <td>{extra.name}</td>
                      <td>{extra.extracurricular_student_count}</td>
                      <td>
                        <Button bsStyle="info" bsSize="sm" fill title="Ver detalles" onClick={() => detailExtra(extra)} ><i className="fa fa-search"></i></Button>
                        <Button
                          bsStyle="success" bsSize="sm" fill title="Editar nombre"
                          onClick={() => this.setState({ showExtracurricularModal: true, selectedExtra: extra })}
                        ><i className="fa fa-edit"></i></Button>
                        <Button bsStyle="danger" bsSize="sm" fill title="Eliminar" onClick={() => this.handleOpenConfirmDelete(extra)} ><i className="fa fa-trash"></i></Button>
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </Table>
            {extras.length > 0 && (
              <>
                <Pager>
                  <Pager.Item
                    previous
                    disabled={currentPage === 1 || this.state.searchIsLoading}
                    onClick={goToPreviousPage}
                  >
                    <i className="fa fa-chevron-left"></i>  Anterior
                  </Pager.Item>
                  <Pager.Item>

                    {currentPage}/{totalOfPages}

                  </Pager.Item>
                  <Pager.Item
                    disabled={currentPage === totalOfPages || this.state.searchIsLoading}
                    next
                    onClick={goToNextPage}
                  >
                    Siguiente <i className="fa fa-chevron-right"></i>
                  </Pager.Item>
                </Pager>
                <Pager>
                  <Pager.Item>
                    Total: {totalOfRecords} registros
                  </Pager.Item>
                </Pager>
              </>
            )}
          </Col>
        </Row>
      </>
    ) : (
      <Loader
        type="Rings"
        color="#00BFFF"
        height={100}
        width={100}
        style={{ display: 'flex', justifyContent: 'center' }}
      />
    );
  }
}

export default ExtraList;
