import React, { Component } from "react";
import {
  Grid,
  Row,
  Col
} from "react-bootstrap";
import { Card } from "components/Card/Card.jsx";
import FullCalendar from '@fullcalendar/react'
import dayGridPlugin from "@fullcalendar/daygrid"
import timeGridPlugin from '@fullcalendar/timegrid'
import interactionPlugin from "@fullcalendar/interaction"
import Loader from 'react-loader-spinner'
import { FormControl, FormGroup, ControlLabel } from "react-bootstrap";
import { mensajeError500 } from "variables/Variables.jsx";
import ErrorNotification from 'components/Notifications/ErrorNotification.jsx'
import Checkbox from 'components/CustomCheckbox/CustomCheckbox';
import Button from 'components/CustomButton/CustomButton';
import axios from 'components/axios';
import Select from 'react-select'
import ConfirmDeleteModal from 'components/Modal/ConfirmDeleteModal.jsx';

class Calendar extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: false,
      isSubmitting: false,
      events: [],
      name: '',
      date: '',
      forAllSchool: true,
      loadSelect: 0,
      levels: [],
      grades: [],
      groups: [],
      levelId: '',
      gradeId: '',
      groupId: '',
      showNotification: false,
      notificationMessage: '',
      isDeleting: false,
      showConfirmDelete: false,
      actionName: "",
      selectedEvent: null,
    };
  }

  async componentDidMount() {
    this.fetchEvents()
  }

  fetchEvents = async () => {
    this.setState({ isLoading: true });
    var date = new Date();
    const primerDia = date.getFullYear() + "-01-01";
    const ultimoDia = date.getFullYear() + "-12-31";
    try {
      const response = await axios.post('calendar/list', {
        start_date: primerDia,
        end_date: ultimoDia
      });
      this.setState({ events: response.data.calendar });
    } catch (err) {
      console.log('ERROR', err);
    } finally {
      this.setState({ isLoading: false });
    }
    this.fetchLevels();
  }

  fetchLevels = async () => {
    this.setState({ loadSelect: 1 });
    try {
      const response = await axios.post('/level/full_list');
      const newData = response.data.Levels.map((item) => {
        return { value: item.id, label: item.name }
      })
      this.setState({ levels: newData })
    } catch (err) {
      console.log('ERROR', err);
    } finally {
      this.setState({ loadSelect: 0 });
    }
  }
  fetchGrades = async (level1) => {
    this.setState({ loadSelect: 2 });
    try {
      const response = await axios.post('/grade/by/level', { level_id: level1 });
      const newData = response.data.Grades.map((item) => {
        return { value: item.id, label: item.name }
      })
      newData.push({ value: '', label: "Todos los grados" })
      this.setState({ grades: newData })
    } catch (err) {
      console.log('ERROR', err);
    } finally {
      this.setState({ loadSelect: 0 });
    }
  }

  fetchGroups = async () => {
    this.setState({ loadSelect: 3 });
    try {
      const response = await axios.post('/group/full_list');
      const newData = response.data.Groups.map((item) => {
        return { value: item.id, label: item.name }
      })
      newData.push({ value: '', label: "Todos los grupos" })
      this.setState({ groups: newData })
    } catch (err) {
      console.log('ERROR', err);
    } finally {
      this.setState({ loadSelect: 0 });
    }
  }

  onChange(e) {
    this.setState({ [e.target.name]: e.target.value })
  }


  handleEventClick = (clickInfo) => {
    this.setState({
      showConfirmDelete: true,
      actionName: `${clickInfo.event.title}`,
      selectedEvent: clickInfo.event,
    });
  };

  handleDelete = async () => {
    const { selectedEvent } = this.state;
    this.setState({ isDeleting: true });
    try {
      await axios.post(`calendar/delete/${selectedEvent.id}`);
      this.setState({ showConfirmDelete: false });
      this.fetchEvents()
    } catch (err) {
      this.setState({
        showNotification: true,
        notificationMessage: mensajeError500
      });
      console.log('ERROR', err);
    } finally {
      this.setState({ isDeleting: false });
    }

  }


  handleSubmit = async () => {
    this.setState({ isSubmitting: true });
    const {
      name,
      date,
      levelId,
      gradeId,
      groupId,
      forAllSchool
    } = this.state;
    try {
      if (forAllSchool === true) {
        await axios.post('calendar/add', {
          title: name,
          date,
        });
      } else {
        if (gradeId === '' && groupId === '') {
          await axios.post('calendar/add', {
            title: name,
            date,
            level_id: levelId,
          });
        } else {
          if (groupId === '') {
            await axios.post('calendar/add', {
              title: name,
              date,
              level_id: levelId,
              grade_id: gradeId,
            });
          } else {
            await axios.post('calendar/add', {
              title: name,
              date,
              level_id: levelId,
              grade_id: gradeId,
              group_id: groupId,
            });
          }
        }
      }
      this.fetchEvents()
    } catch (err) {
      if (typeof err.response !== 'undefined' && typeof err.response.data !== 'undefined') {
        if (err.response.status === 400) {
          this.setState({
            showNotification: true,
            notificationType: "danger",
            notificationMessage: err.response.data.error
          });
        } else {
          this.setState({
            showNotification: true,
            notificationType: "danger",
            notificationMessage: mensajeError500
          });
        }
        console.log('ERROR', err);
      }
      console.log('ERROR', err);
    } finally {
      this.setState({ isSubmitting: false });
    }
    this.fetchLevels();
  }
  render() {
    const {
      events,
      isLoading,
      forAllSchool,
      loadSelect,
      levels,
      grades,
      groups,
      showNotification,
      notificationMessage,
      isSubmitting,
      showConfirmDelete,
      actionName
    } = this.state;
    return (
      <div className="content">
        <Grid fluid>
          <Row>
            <ConfirmDeleteModal
              onClose={ () => this.setState({ showConfirmDelete: false }) }
              show={ showConfirmDelete }
              thingToRemoveType="evento"
              thingToRemoveName={ actionName }
              onConfirm={ this.handleDelete }
            />
            <Col md={ 12 }>
              <Card
                title="Calendario"
                category=""
                stats=""
                statsIcon=""
                content={
                  <Row>
                    <Col md={ 9 }>
                      { !isLoading ?
                        <FullCalendar
                          plugins={ [dayGridPlugin, timeGridPlugin, interactionPlugin] }
                          headerToolbar={ {
                            left: 'prev,next today',
                            center: 'title',
                            right: 'dayGridMonth,timeGridWeek,timeGridDay',

                          } }
                          buttonText={ {
                            today: 'hoy',
                            month: 'mes',
                            week: 'semana',
                            day: 'día',
                          } }
                          allDayText={ "todo el día" }
                          initialView='dayGridMonth'
                          eventClick={ this.handleEventClick }
                          events={ events }
                          locale={ "es" }
                          navLinks={ true }
                        /> :
                        <Loader
                          type="Rings"
                          color="#00BFFF"
                          height={ 100 }
                          width={ 100 }
                          style={ { display: 'flex', justifyContent: 'center' } }
                        />
                      }
                    </Col>
                    <form onSubmit={ this.handleSubmit }>
                      <Col md={ 3 }>
                        <h4>Nuevo evento</h4>
                        { !isSubmitting ?
                          <>
                            { showNotification &&
                              <ErrorNotification
                                notificationMessage={ notificationMessage }
                                closeNotification={ () => this.setState({ showNotification: false }) }
                              />
                            }
                            <FormGroup>
                              <ControlLabel style={ { marginTop: "2%" } }> NOMBRE </ControlLabel>
                              <textarea
                                name="name"
                                style={ { width: "94%", height: "50px", marginLeft: "3%" } }
                                maxlength="120"
                                onChange={ e => this.onChange(e) }
                              />
                              <span><b>Nota:</b> el nombre del evento no puede sobrepasar los 120 caractéres.</span>
                            </FormGroup>
                            <FormGroup>
                              <ControlLabel> FECHA </ControlLabel>
                              <FormControl type="date" name="date" onChange={ e => this.onChange(e) } required />
                            </FormGroup>

                            <Checkbox
                              number="1"
                              checked={ forAllSchool }
                              label=" Para toda la escuela"
                              onChange={ () => this.setState({ forAllSchool: !forAllSchool }) }
                            />
                            <FormGroup>
                              <ControlLabel > NIVEL </ControlLabel>
                              { loadSelect === 1 ?
                                <Loader
                                  type="Rings"
                                  color="#00BFFF"
                                  height={ 30 }
                                  width={ 30 }
                                  style={ { display: 'flex', justifyContent: 'center' } }
                                />
                                :
                                <Select options={ levels }
                                  name="levelId" className="basic-single"
                                  classNamePrefix="select"
                                  placeholder="nivel ..."
                                  onChange={ (e) => { this.setState({ 'levelId': e.value }); this.fetchGrades(e.value) } }
                                  isDisabled={ forAllSchool }
                                />
                              }
                            </FormGroup>
                            <FormGroup>
                              <ControlLabel> GRADO </ControlLabel>
                              { loadSelect === 2 ?
                                <Loader
                                  type="Rings"
                                  color="#00BFFF"
                                  height={ 30 }
                                  width={ 30 }
                                  style={ { display: 'flex', justifyContent: 'center' } }
                                />
                                :
                                <Select options={ grades }
                                  name="gradeId" className="basic-single"
                                  classNamePrefix="select"
                                  onChange={ (e) => { this.setState({ 'gradeId': e.value }); this.fetchGroups() } }
                                  placeholder="grado ..."
                                  isDisabled={ grades.length === 0 || forAllSchool }
                                />
                              }
                            </FormGroup>
                            <FormGroup>
                              <ControlLabel > GRUPO </ControlLabel>
                              { loadSelect === 3 ?
                                <Loader
                                  type="Rings"
                                  color="#00BFFF"
                                  height={ 30 }
                                  width={ 30 }
                                  style={ { display: 'flex', justifyContent: 'center' } }
                                />
                                :
                                <Select options={ groups }
                                  name="groupId" className="basic-single"
                                  classNamePrefix="select"
                                  onChange={ (e) => this.setState({ 'groupId': e.value }) }
                                  placeholder="grupo ..."
                                  isDisabled={ groups.length === 0 || forAllSchool }
                                />
                              }
                            </FormGroup>
                            <Button bsStyle="success" block fill type="submit">
                              <i className="fa fa-send"></i> Guardar
                              </Button>
                          </>
                          :
                          <Loader
                            type="Rings"
                            color="#00BFFF"
                            height={ 100 }
                            width={ 100 }
                            style={ { display: 'flex', justifyContent: 'center' } }
                          />
                        }
                      </Col>
                    </form>
                  </Row>
                }
              />
            </Col>

          </Row>
        </Grid>
      </div>
    );
  }
}

export default Calendar;
