import React from 'react'
import { Modal, Row, Col } from "react-bootstrap";
import Button from 'components/CustomButton/CustomButton';
import jsPDF from 'jspdf'
import QRCode from 'qrcode.react'
import logo from "assets/img/header.png";

class ModalQR extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  generatePDF = () => {
    const canvas = document.getElementById('canvasQR');
    const imgData = canvas.toDataURL('image/png');
    var img = new Image()
    img.src = logo
    const pdf = new jsPDF();
    pdf.addImage(img, 'JPEG', 5, 5, 200, 60);
    pdf.addImage(imgData, 'JPEG', 70, 80, 90, 90);
    pdf.save("loginQR-AEv4.pdf");

  }

  render() {
    const {
      onClose, open, value, tutorData
    } = this.props;
    return (
      <Modal show={ open } onHide={ onClose }>
        <Modal.Header closeButton>
          <Modal.Title>QR para inicio de sesión de { tutorData.name } { tutorData.last_name }</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Row>
            <Col md={ 12 } style={ { marginLeft: "28%" } }>
              <QRCode value={ value } id="canvasQR" size="256" />
            </Col>
            <Col md={ 12 } style={ { marginLeft: "35%", marginTop: "2%" } }>
              <Button bsStyle="primary" fill onClick={ this.generatePDF }><i className="fa fa-file-pdf-o"></i>Descargar en PDF</Button>
            </Col>
          </Row>
        </Modal.Body>
      </Modal>
    );
  }
}
export default ModalQR;