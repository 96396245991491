import React, { Component } from "react";
import { Row, Table, Col, Pager } from "react-bootstrap";
import Button from 'components/CustomButton/CustomButton';
import ConfirmDeleteModal from 'components/Modal/ConfirmDeleteModal.jsx';
import Loader from 'react-loader-spinner'
import { mensajeError500 } from "variables/Variables.jsx";
import { gRoute } from "variables/DomainRoutes.jsx";
import axios from 'components/axios';
import ErrorNotification from 'components/Notifications/ErrorNotification.jsx'
import Checkbox from 'components/CustomCheckbox/CustomCheckbox';
import styles from './styles'
import ModalExcel from 'components/ModalExcel';

class MonitorList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedCheckboxes: new Set(),
      isLoading: false,
      isDeleting: false,
      showConfirmDelete: false,
      actionName: "",
      selectedMonitor: null,
      monitors: [],
      totalOfPages: 0,
      totalOfRecords: 0,
      showNotification: false,
      notificationMessage: '',
      urlExcel: '',
      openModal: false
    };
    this.controlModal = this.controlModal.bind(this);
  }

  controlModal(show) {
    this.setState({ openModal: show || false });
  }

  async componentDidMount() {
    this.fetchMonitors();
  }

  componentDidUpdate(prevProps) {
    if (prevProps.currentPage !== this.props.currentPage) {
      this.fetchMonitors();
    }
  }

  fetchMonitors = async () => {
    const { currentPage } = this.props;
    this.setState({ isLoading: true });
    try {
      const response = await axios.post('monitory/list', {
        current_page: currentPage,
      });
      this.setState({
        monitors: response.data.monitories.data,
        totalOfPages: response.data.monitories.last_page,
        totalOfRecords: response.data.monitories.total
      });
    } catch (err) {
      if (typeof err.response !== 'undefined' && typeof err.response.data !== 'undefined') {
        if (err.response.status === 400) {
          this.setState({
            showNotification: true,
            notificationType: "danger",
            notificationMessage: err.response.data.error
          });
        } else {
          this.setState({
            showNotification: true,
            notificationType: "danger",
            notificationMessage: mensajeError500
          });
        }
      } else {
        console.log('ERROR', err);
      }
    } finally {
      this.setState({ isLoading: false });
    }
  }

  toggleCheckbox(number) {
    const actual = this.state.selectedCheckboxes;
    if (actual.has(number)) {
      actual.delete(number);
    } else {
      actual.add(number);
    }
    this.setState({ selectedCheckboxes: actual })
  }

  handleOpenConfirmDelete = (monitor) => {
    this.setState({
      showConfirmDelete: true,
      actionName: " del estudiante " + monitor.student.name + " enviado el " + monitor.created_at,
      selectedMonitor: monitor,
    });
  };

  handleDelete = async () => {
    const { selectedMonitor } = this.state;
    this.setState({ isDeleting: true });
    try {
      await axios.post(`monitory/delete/${selectedMonitor.id}`);
      this.setState({ showConfirmDelete: false });
      this.fetchMonitors();
    } catch (err) {
      if (typeof err.response !== 'undefined' && typeof err.response.data !== 'undefined') {
        if (err.response.status === 400) {
          this.setState({
            showNotification: true,
            notificationType: "danger",
            notificationMessage: err.response.data.error
          });
        } else {
          this.setState({
            showNotification: true,
            notificationType: "danger",
            notificationMessage: mensajeError500
          });
        }
      } else {
        console.log('ERROR', err);
      }
    } finally {
      this.setState({ isDeleting: false });
    }
  }

  joinIds = () => {
    const { selectedCheckboxes } = this.state;
    let ids = "";
    selectedCheckboxes.forEach((item) => {
      ids = ids + "," + item
    })
    return ids.substring(1);
  }

  handleDeleteMassive = async () => {
    this.setState({ isDeleting: true });
    try {
      await axios.post(`monitory/deletearray/`, {
        id: this.joinIds()
      });
      this.setState({ showConfirmDelete: false });
      this.fetchMonitors();
      this.state.selectedCheckboxes.clear()
    } catch (err) {
      if (typeof err.response !== 'undefined' && typeof err.response.data !== 'undefined') {
        if (err.response.status === 400) {
          this.setState({
            showNotification: true,
            notificationType: "danger",
            notificationMessage: err.response.data.error
          });
        } else {
          this.setState({
            showNotification: true,
            notificationType: "danger",
            notificationMessage: mensajeError500
          });
        }
        console.log('ERROR', err);
      }
      console.log('ERROR', err);
      this.setState({ isDeleting: false, showConfirmDelete: false });
    } finally {
      this.setState({ isDeleting: false });
    }
  }
  
  render() {
    const {
      detailMonitor,
      currentPage,
      goToNextPage,
      goToPreviousPage,
    } = this.props;
    const {
      showConfirmDelete,
      actionName,
      isLoading,
      showNotification,
      notificationMessage,
      totalOfPages,
      totalOfRecords,
      monitors,
      selectedCheckboxes,
      urlExcel,
      loading,
      openModal
    } = this.state;
    return !isLoading ? (
      <>
        <ConfirmDeleteModal
          onClose={() => this.setState({ showConfirmDelete: false })}
          show={showConfirmDelete}
          thingToRemoveType="seguimiento académico"
          thingToRemoveName={actionName}
          onConfirm={this.handleDelete}
        />
        <ModalExcel url="/monitory/excel" isOpen={openModal} controlModal={this.controlModal} />
        <Row>
          <Col md={12}>
            <Button
              bsStyle="primary"
              pullRight
              onClick={() => this.controlModal(true)}
              style={styles.topButton}>
              Exportar Excel</Button>
            <Button
              bsStyle="primary"
              pullRight
              style={styles.topButton}
              disabled={selectedCheckboxes.size === 0}
              onClick={() => this.handleDeleteMassive()}>
              Eliminar seleccionados</Button>
          </Col>
          <Col md={12}>
            {showNotification &&
              <Col md={11}>
                <ErrorNotification
                  notificationMessage={notificationMessage}
                  closeNotification={() => this.setState({ showNotification: false })}
                />
              </Col>
            }
            <Table striped hover >
              <thead>
                <tr>
                  <th></th>
                  <th>Usuario </th>
                  <th>Padre</th>
                  <th>Estudiante</th>
                  <th>Enviado</th>
                  <th>Leído</th>
                  <th>Visto</th>
                  <th>Acciones</th>
                </tr>
              </thead>
              <tbody>
                {monitors.map((monitor) => {
                  return (
                    <tr key={monitor.id}>
                      <td>
                        <Checkbox
                          number={"monitor" + monitor.id}
                          onClick={() => { this.toggleCheckbox(monitor.id) }}
                        />
                      </td>
                      <td>
                        {monitor.user && `${monitor.user.name} ${monitor.user.last_name}`}
                      </td>
                      <td>
                        {monitor.tutor && `${monitor.tutor.name} ${monitor.tutor.last_name}`}
                      </td>
                      <td>
                        {monitor.student && `${monitor.student.name} ${monitor.student.last_name}`}
                      </td>
                      <td>{monitor.created_at}</td>
                      <td>{monitor.state === false ? <span className="label label-danger">No</span> : <span className="label label-success">Si</span>}</td>
                      <td>{monitor.viewed === null ? "-" : monitor.viewed}</td>
                      <td>
                        <Button bsStyle="info" bsSize="sm" fill title="Ver detalles" onClick={() => detailMonitor(monitor)} ><i className="fa fa-search"></i></Button>
                        <Button bsStyle="danger" bsSize="sm" fill title="Eliminar" onClick={() => this.handleOpenConfirmDelete(monitor)} ><i className="fa fa-trash"></i></Button>
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </Table>
            {monitors.length > 0 && (
              <>
                <Pager>
                  <Pager.Item
                    previous
                    disabled={currentPage === 1}
                    onClick={goToPreviousPage}
                  >
                    <i className="fa fa-chevron-left"></i>  Anterior
                  </Pager.Item>
                  <Pager.Item>

                    {currentPage}/{totalOfPages}

                  </Pager.Item>
                  <Pager.Item
                    disabled={currentPage === totalOfPages}
                    next
                    onClick={goToNextPage}
                  >
                    Siguiente <i className="fa fa-chevron-right"></i>
                  </Pager.Item>
                </Pager>
                <Pager>
                  <Pager.Item>
                    Total: {totalOfRecords} registros
                  </Pager.Item>
                </Pager>
              </>
            )}
          </Col>
        </Row>
      </>
    ) : (
      <Loader
        type="Rings"
        color="#00BFFF"
        height={100}
        width={100}
        style={{ display: 'flex', justifyContent: 'center' }}
      />
    );
  }
}

export default MonitorList;
