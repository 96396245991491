import React, { Component } from "react";
import { Grid } from "react-bootstrap";
import { Card } from "components/Card/Card.jsx";
import MessageList from 'views/messages/MessageList';
import MessageDetail from 'views/messages/MessageDetail';

class Message extends Component {
  constructor(props) {
    super(props);
    this.state = {
      key: 0,
      messageData: {},
      currentPage: 1,
    };
  }
  handleBackToList = () => {
    this.setState({ key: 0 });
  };
  handleDetailMessage = (messageData) => {
    this.setState({ key: 1, messageData: messageData });
  };
  handleEnableButtom = () => { this.setState({ areCheckboxSelected: true }); };
  handleDisabledButtom = () => { this.setState({ areCheckboxSelected: false }); };

  goToNextPage = () => {
    this.setState((currentState) => ({ currentPage: currentState.currentPage + 1 }));
  }

  restartPagination = () => {
    this.setState((currentState) => ({ currentPage: 1}));
  }

  goToPreviewsPage = () => {
    this.setState((currentState) => ({ currentPage: currentState.currentPage - 1 }));
  }

  render() {
    const {
      key,
      messageData,
      currentPage
    } = this.state;

    return (
      <div style={ { marginLeft: '30px', marginRight: '30px' } }>
        <Card
          cardStyle={ {
            boxShadow: 'none',
          } }
          content={
            <Grid fluid>
              {
                {
                  0: (
                    <MessageList
                      detailMessage={ this.handleDetailMessage }
                      enableMultipleDelete={ this.handleEnableButtom }
                      disableMultipleDelete={ this.handleDisabledButtom }
                      restartPagination={ this.restartPagination }
                      currentPage={ currentPage }
                      goToNextPage={ this.goToNextPage }
                      goToPreviousPage={ this.goToPreviewsPage }
                    />
                  ),
                  1: <MessageDetail returnToList={ this.handleBackToList } messageData={ messageData } />,
                }[key]
              }
            </Grid>
          }
        />
      </div>
    );
  }
}

export default Message;
