import React, { Component } from "react";
import { Row, Table, Col, Pager } from "react-bootstrap";
import Button from 'components/CustomButton/CustomButton';
import ConfirmDeleteModal from 'components/Modal/ConfirmDeleteModal.jsx';
import Loader from 'react-loader-spinner'
import { mensajeError500 } from "variables/Variables.jsx";
import axios from 'components/axios';
import ErrorNotification from 'components/Notifications/ErrorNotification.jsx'
import { TableView } from "@fullcalendar/daygrid";

class TeachingCourseList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: false,
      isDeleting: false,
      showConfirmDelete: false,
      actionName: "",
      selectedTC: null,
      teachingCourses: [],
      totalOfPages: 0,
      totalOfRecords: 0,
      showNotification: false,
      notificationMessage: '',
    };
  }
  async componentDidMount() {
    this.fetchTC();
  }

  componentDidUpdate(prevProps) {
    if (prevProps.currentPage !== this.props.currentPage) {
      this.fetchTC();
    }
  }

  fetchTC = async () => {
    const { currentPage } = this.props;
    this.setState({ isLoading: true });
    try {
      const response = await axios.post('teaching_course/list', {
        current_page: currentPage,
      });
      this.setState({
        teachingCourses: response.data.teachings_courses.data,
        totalOfPages: response.data.teachings_courses.last_page,
        totalOfRecords: response.data.teachings_courses.total
      });
    } catch (err) {
      console.log('ERROR', err);
    } finally {
      this.setState({ isLoading: false });
    }
  }


  handleOpenConfirmDelete = (tc) => {
    if (tc.course && tc.level && tc.grade && tc.group) {
      this.setState({
        showConfirmDelete: true,
        actionName: `${tc.course.name} de ${tc.level.name} - ${tc.grade.name} - ${tc.group.name}`,
        selectedTC: tc,
      });
    } else {
      if (tc.level && tc.grade && tc.group) {
        this.setState({
          showConfirmDelete: true,
          actionName: `del ${tc.level.name} - ${tc.grade.name} - ${tc.group.name}`,
          selectedTC: tc,
        });
      } else {
        if (tc.grade && tc.group) {
          this.setState({
            showConfirmDelete: true,
            actionName: `del grado ${tc.grade.name} - ${tc.group.name}`,
            selectedTC: tc,
          });
        } else {
          this.setState({
            showConfirmDelete: true,
            actionName: '',
            selectedTC: tc,
          });
        }
      }
    }

  };

  handleDelete = async () => {
    const { selectedTC } = this.state;
    this.setState({ isDeleting: true });
    try {
      await axios.post(`/teaching_course/delete/${selectedTC.id}`);
      this.setState({ showConfirmDelete: false });
      this.fetchTC();
    } catch (err) {
      if (typeof err.response !== 'undefined' && typeof err.response.data !== 'undefined') {
        if (err.response.status === 400) {
          this.setState({
            showNotification: true,
            notificationMessage: err.response.data.error
          });
        } else {
          this.setState({
            showNotification: true,
            notificationMessage: mensajeError500
          });
        }
        console.log('ERROR', err);
      }
      console.log('ERROR', err);
      this.setState({ isDeleting: false, showConfirmDelete: false });
    } finally {
      this.setState({ isDeleting: false });
    }
  }

  render() {
    const {
      editTeachingCourse,
      currentPage,
      goToNextPage,
      goToPreviousPage,
    } = this.props;
    const {
      showConfirmDelete,
      actionName,
      isLoading,
      teachingCourses,
      showNotification,
      notificationMessage,
      totalOfPages,
      totalOfRecords
    } = this.state;
    return !isLoading ? (
      <>
        <ConfirmDeleteModal
          onClose={ () => this.setState({ showConfirmDelete: false }) }
          show={ showConfirmDelete }
          thingToRemoveType="asignación de materia"
          thingToRemoveName={ actionName }
          onConfirm={ this.handleDelete }
          prepo="a la"
        />
        <Row>
          <Col md={ 12 }>
            { showNotification &&
              <Col md={ 11 }>
                <ErrorNotification
                  notificationMessage={ notificationMessage }
                  closeNotification={ () => this.setState({ showNotification: false }) }
                />
              </Col>
            }
            <Button
              bsStyle="primary" fill
              style={ { margin: "1%" } }
              onClick={ () => this.props.history.replace("/admin/course/assign") }
            >
              Asignar Materia
              </Button>
            <Table striped hover >
              <thead>
                <tr>
                  <th>Nivel - grado - grupo</th>
                  <th>Materia</th>
                  <th>Profesor</th>
                  <th>Tareas asignadas</th>
                  <th>Creado</th>
                  <th>Acciones</th>
                </tr>
              </thead>
              <tbody>
                { teachingCourses.map((tc) => {
                  return (
                    <tr key={ tc.id }>
                      <td>{ tc.level && tc.level.name } - { tc.grade && tc.grade.name } - { tc.group && tc.group.name }</td>
                      <td>{ tc.course && tc.course.name }</td>
                      <td>{ tc.user && `${tc.user.name} ${tc.user.last_name}` }</td>
                      <td>{ tc.assigned }</td>
                      <td>{ tc.created_at }</td>
                      <td>
                        <Button
                          bsStyle="success" bsSize="sm" fill title="Editar nombre"
                          onClick={ () => editTeachingCourse(tc) }
                        ><i className="fa fa-edit"></i></Button>
                        <Button
                          bsStyle="danger"
                          bsSize="sm" fill
                          title="Eliminar"
                          disabled={ tc.assigned > 0 }
                          onClick={ () => this.handleOpenConfirmDelete(tc)
                          } ><i className="fa fa-trash"></i></Button>
                      </td>
                    </tr>
                  );
                }) }
              </tbody>
            </Table>
            { teachingCourses.length > 0 && (
              <>
                <Pager>
                  <Pager.Item
                    previous
                    disabled={ currentPage === 1 }
                    onClick={ goToPreviousPage }
                  >
                    <i className="fa fa-chevron-left"></i>  Anterior
                      </Pager.Item>
                  <Pager.Item>

                    { currentPage }/{ totalOfPages }

                  </Pager.Item>
                  <Pager.Item
                    disabled={ currentPage === totalOfPages }
                    next
                    onClick={ goToNextPage }
                  >
                    Siguiente <i className="fa fa-chevron-right"></i>
                  </Pager.Item>
                </Pager>
                <Pager>
                  <Pager.Item>
                    Total: { totalOfRecords } registros
                  </Pager.Item>
                </Pager>
              </>
            ) }
          </Col>
        </Row>
      </>
    ) : (
        <Loader
          type="Rings"
          color="#00BFFF"
          height={ 100 }
          width={ 100 }
          style={ { display: 'flex', justifyContent: 'center' } }
        />
      );
  }
}

export default TeachingCourseList;
