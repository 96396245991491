import React, { Component } from "react";
import { Row, Table, Col, Pager } from "react-bootstrap";
import { mensajeError500 } from "variables/Variables.jsx";
import ErrorNotification from 'components/Notifications/ErrorNotification.jsx'
import Button from 'components/CustomButton/CustomButton';
import ConfirmDeleteModal from 'components/Modal/ConfirmDeleteModal.jsx';
import axios from 'components/axios';
import Loader from 'react-loader-spinner'
import SearchInput from 'components/Search/SearchInputFixed';

class ScannerList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: false,
      isDeleting: false,
      showConfirmDelete: false,
      actionName: "",
      selectedScanner: {},
      scanners: [],
      totalOfPages: 0,
      totalOfRecords: 0,
      showNotification: false,
      notificationMessage: '',
      searchIsLoading:false
    };
  }
  async componentDidMount() {
    this.fetchScanners();
  }

  componentDidUpdate(prevProps) {
    if (prevProps.currentPage !== this.props.currentPage) {
      console.log(this.props.currentPage)
      if(this.state.searchActive===false){
        this.fetchScanners();
      }else{
        this.onClickPagination();
      }
    }
  }

  fetchScanners = async () => {
    this.setState({ isLoading: true });
    try {
      const response = await axios.post('/recorder/list');
      this.setState({
        scanners:
          response.data.Recorders.data,
        totalOfPages: response.data.Recorders.last_page,
        totalOfRecords: response.data.Recorders.total
      });
      console.log(response.data);
    } catch (err) {
      if (typeof err.response !== 'undefined' && typeof err.response.data !== 'undefined') {
        if (err.response.status === 400) {
          this.setState({
            showNotification: true,
            notificationType: "danger",
            notificationMessage: err.response.data.error
          });
        } else {
          this.setState({
            showNotification: true,
            notificationType: "danger",
            notificationMessage: mensajeError500
          });
        }
      } else {
        console.log('ERROR', err);
      }
    } finally {
      this.setState({ isLoading: false });
    }
  }

  handleOpenConfirmDelete = (scanner) => {
    this.setState({
      showConfirmDelete: true,
      actionName: scanner.username,
      selectedScanner: scanner,
    });
  };

  handleDelete = async () => {
    const { selectedScanner } = this.state;
    this.setState({ isDeleting: true });
    try {
      await axios.post(`/recorder/delete/${selectedScanner.id}`);
      this.setState({ showConfirmDelete: false });
      this.fetchScanners();
    } catch (err) {
      if (typeof err.response !== 'undefined' && typeof err.response.data !== 'undefined') {
        if (err.response.status === 400) {
          this.setState({
            showNotification: true,
            notificationType: "danger",
            notificationMessage: err.response.data.error
          });
        } else {
          this.setState({
            showNotification: true,
            notificationType: "danger",
            notificationMessage: mensajeError500
          });
        }
      } else {
        console.log('ERROR', err);
      }
    } finally {
      this.setState({ isDeleting: false });
    }
  }

  onClickPagination = () => {
    const { currentPage } = this.props
    const {searchTarget,searchQuerySearch} = this.state
    this.fetchSearch(searchTarget,searchQuerySearch,currentPage);
  }

  onClickButtonSearch = (target,querySearch) => {
    this.setState({searchTarget:target,currentPage: 1,searchQuerySearch:querySearch});
    this.fetchSearch(target,querySearch,1);
  }

  fetchSearch = async (target,querySearch,currentPage) => {
    this.setState({ searchActive: true})
    this.setState({ searchIsLoading: true });

    try {
      var response = {}
      if (querySearch === true) {
        response = await axios.post('/recorder/search', {
          page: currentPage,
          query: target
        });
      } else {
        response = await axios.post('/recorder/search', {
          current_page: currentPage,
          name: target
        });
      }
      if (response.status === 200) {
        this.setState({
          searchShowNotification:true,
          searchNotificationType:"info",
          searchNotificationMessage:"Búsqueda finalizada. Se encontraron " + response.data.Recorders.total + " coincidecias.",
        });
      }
      this.setState({
        scanners: response.data.Recorders.data,
        totalOfPages: response.data.Recorders.last_page,
        totalOfRecords: response.data.Recorders.total
      });
    } catch (err) {
      if (typeof err.response !== 'undefined' && typeof err.response.data !== 'undefined') {
        if (err.response.status === 400) {
          this.setState({
            searchShowNotification: true,
            searchNotificationType: "danger",
            searchNotificationMessage: err.response.data.error
          });
        } else {
          this.setState({
            searchShowNotification: true,
            searchNotificationType: "danger",
            searchNotificationMessage: mensajeError500
          });
        }
      } else {
        console.log("ERROR", err)
      }
    } finally {
      this.setState({ searchIsLoading: false });
    }
  }

  render() {
    const {
      detailScanner,
      editScanner,
      currentPage,
      goToNextPage,
      goToPreviousPage,
    } = this.props;
    const {
      totalOfPages,
      totalOfRecords,
      showConfirmDelete,
      actionName,
      isLoading,
      scanners,
      showNotification,
      notificationMessage
    } = this.state;
    return !isLoading ? (
      <>
        <ConfirmDeleteModal
          onClose={ () => this.setState({ showConfirmDelete: false }) }
          show={ showConfirmDelete }
          thingToRemoveType=" scanner de usuario "
          thingToRemoveName={ actionName }
          onConfirm={ this.handleDelete }
        />
        <Row style={ { marginTop: "2%" } }>
          <Col md={ 12 }>
            { showNotification &&
              <ErrorNotification
                notificationMessage={ notificationMessage }
                closeNotification={ () => this.setState({ showNotification: false }) }
              />
            }
            <Col md={ 8 }>
              <SearchInput
                search={ this.onClickButtonSearch }
                fetchDetails={ this.state }
                closeNotification={ () => this.setState({...this.state,searchShowNotification:false}) }
                querySearch={ true }
              />
            </Col>
            <Table striped hover style={ { marginTop: "2%" } }>
              <thead>
                <tr>
                  <th>Nombres</th>
                  <th>Apellidos</th>
                  <th>Correo electrónico</th>
                  <th>Usuario</th>
                  <th>Acciones</th>
                </tr>
              </thead>
              <tbody>
                { scanners.map((scanner) => {
                  return (
                    <tr key={ scanner.id }>
                      <td>{ scanner.firstname }</td>
                      <td>{ scanner.lastname }</td>
                      <td>{ scanner.email }</td>
                      <td>{ scanner.username }</td>
                      <td>
                        <Button bsStyle="info" bsSize="sm" fill title="Ver detalles" onClick={ () => detailScanner(scanner) } ><i className="fa fa-search"></i></Button>
                        <Button bsStyle="success" bsSize="sm" fill title="editar" onClick={ () => editScanner(scanner) } ><i className="fa fa-edit"></i></Button>
                        <Button bsStyle="danger" bsSize="sm" fill title="Eliminar" onClick={ () => this.handleOpenConfirmDelete(scanner) } ><i className="fa fa-trash"></i></Button>
                      </td>
                    </tr>
                  );
                }) }
              </tbody>
            </Table>
            { scanners.length > 0 && (
              <>
                <Pager>
                  <Pager.Item
                    previous
                    disabled={ currentPage === 1 || this.state.searchIsLoading }
                    onClick={ goToPreviousPage }
                  >
                    <i className="fa fa-chevron-left"></i>  Anterior
                      </Pager.Item>
                  <Pager.Item>

                    { currentPage }/{ totalOfPages }

                  </Pager.Item>
                  <Pager.Item
                    disabled={ currentPage === totalOfPages || this.state.searchIsLoading }
                    next
                    onClick={ goToNextPage }
                  >
                    Siguiente <i className="fa fa-chevron-right"></i>
                  </Pager.Item>
                </Pager>
                <Pager>
                  <Pager.Item>
                    Total: { totalOfRecords } registros
                </Pager.Item>
                </Pager>
              </>
            ) }
          </Col>
        </Row>
      </>
    ) : (
        <Loader
          type="Rings"
          color="#00BFFF"
          height={ 100 }
          width={ 100 }
          style={ { display: 'flex', justifyContent: 'center' } }
        />
      );
  }
}

export default ScannerList;
