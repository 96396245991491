import React, { Component } from "react";
import { Card } from "components/Card/Card.jsx";
import { Grid } from "react-bootstrap";
import HomeworkList from "views/homeworks/HomeworkList"
import HomeworkDetail from "views/homeworks/HomeworkDetail"
import HomeworkReview from "views/homeworks/HomeworkReview"

class Homework extends Component {
  constructor(props) {
    super(props);
    this.state = {
      key: 0,
      homeworkData: {},
      currentPage: 1,
      homeworkStudent: {}
    };
  }
  handleBackToList = () => {
    this.setState({ key: 0 });
  };

  handleDetailHomework = (homeworkData) => {
    this.setState({ key: 1, homeworkData: homeworkData });
  };
  handleReview = (homeworkStudent, homeworkData) => {
    this.setState({ key: 2, homeworkStudent: homeworkStudent, homeworkData: homeworkData });
  };

  goToNextPage = () => {
    this.setState((currentState) => ({ currentPage: currentState.currentPage + 1 }));
  }

  restartPagination = () => {
    this.setState({ currentPage: 1 });
  }

  goToPreviewsPage = () => {
    this.setState((currentState) => ({ currentPage: currentState.currentPage - 1 }));
  }
  render() {
    const {
      key,
      homeworkData,
      currentPage,
      homeworkStudent
    } = this.state;

    return (
      <div style={ { marginLeft: '30px', marginRight: '30px' } }>
        <Card
          cardStyle={ {
            boxShadow: 'none',
          } }
          content={
            <Grid fluid>
              {
                {
                  0:
                    <HomeworkList
                      detailHomework={ this.handleDetailHomework }
                      currentPage={ currentPage }
                      restartPagination={ this.restartPagination }
                      goToNextPage={ this.goToNextPage }
                      goToPreviousPage={ this.goToPreviewsPage }
                      history={ this.props.history }
                    />
                  ,
                  1: <HomeworkDetail
                    returnToList={ this.handleBackToList }
                    homeworkData={ homeworkData }
                    reviewHomework={ this.handleReview } />
                  ,
                  2: <HomeworkReview
                    detailHomework={ this.handleDetailHomework }
                    homeworkStudent={ homeworkStudent }
                    homeworkData={ homeworkData }
                  />,
                }[key]
              }
            </Grid>
          }
        />
      </div>
    );
  }
}
export default Homework;
