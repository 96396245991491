/*!

=========================================================
* Light Bootstrap Dashboard React - v1.3.0
=========================================================

* Product Page: https://www.creative-tim.com/product/light-bootstrap-dashboard-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/light-bootstrap-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";
import ReactDOM from "react-dom";

import { HashRouter, Route, Switch, Redirect } from "react-router-dom";

import "bootstrap/dist/css/bootstrap.min.css";
import "./assets/css/animate.min.css";
import "./assets/sass/light-bootstrap-dashboard-react.scss?v=1.3.0";
import "./assets/css/demo.css";
import "./assets/css/pe-icon-7-stroke.css";
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";

import AdminLayout from "layouts/Admin.jsx";
import Login from "views/login/Login.jsx";
import LoginParent from "views/login/LoginParent.jsx";
import ForgotPassword from "views/login/ForgotPassword.jsx";

ReactDOM.render(
  <HashRouter>
    <Switch>
      <Route path="/login" render={ props => <Login { ...props } /> } />
      <Route path="/login-parent" render={ props => <LoginParent { ...props } /> } />
      <Route path="/forgot-password" render={ props => <ForgotPassword { ...props } /> } />
      <Route path="/admin" render={ props => <AdminLayout { ...props } /> } />
      <Redirect from="/" to="/login" />
    </Switch>
  </HashRouter>,
  document.getElementById("root")
);
