const rootRoute = "https://admin.aplicacionescolar.net/"
const gRoute = "https://api.aplicacionescolar.net/";
const uploadRoute = "https://uploads.aplicacionescolar.net/api/v1/";
const socketRoute = "https://socket.aplicacionescolar.net";

const generalRoute = gRoute + "general/";
const payRoute = rootRoute + "payment/"

const documentosBase = [
    { path: generalRoute + "general-file-1606369179025.xlsx", title: "CalificacionesV4 - base" },
    { path: generalRoute + "general-file-1601304365982.xlsx", title: "SeguimientosV4 - base" },
    { path: generalRoute + "general-file-1601304456015.xlsx", title: "ExtracurriculareV4 - base" },
    { path: generalRoute + "general-file-1613669186365.xlsx", title: "PadresYTutoresV4 -base" },
    { path: generalRoute + "general-file-1607016584088.xlsx", title: "CobranzasV4 - base" },
]

module.exports = {
    rootRoute,
    generalRoute,
    gRoute,
    uploadRoute,
    socketRoute,
    payRoute,
    documentosBase
};
