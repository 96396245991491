/*!

=========================================================
* Light Bootstrap Dashboard React - v1.3.0
=========================================================

* Product Page: https://www.creative-tim.com/product/light-bootstrap-dashboard-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/light-bootstrap-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React, { Component } from "react";
import {
  Grid,
  Row,
  Col,
  FormGroup, ControlLabel, FormControl, Alert,
} from "react-bootstrap";
import { Card } from "components/Card/Card.jsx";
import styles from './styles'
import imageLogin from "assets/img/part.png"; //login4.png";
import imageFb from "assets/img/logo_face.jpg";
import logo from "assets/img/logo.png";
import Button from 'components/CustomButton/CustomButton';
import axios from 'components/axios';
import Loader from 'react-loader-spinner'
import routes from "routes.js";

class Login extends Component {
  constructor(props) {
    super(props);
    this.state = {
      seePassword: false,
      email: '',
      password: '',
      showNotification: false,
      notificationMessage: '',
      isSubmitting: false,
    };
  }

  getRoutes = (p) => {
    routes.map((prop) => {
      if (prop.letter === p) {
        return prop.layout + prop.path
      }
    });
  };

  handleSubmit = async (e) => {
    e.preventDefault();
    const { email, password } = this.state;
    this.setState({ isSubmitting: true });
    try {
      const response = await axios.post('login', {
        email,
        password,
      });
      localStorage.setItem('TOKEN', response.data.token);
      localStorage.setItem('USER', JSON.stringify(response.data.usuario));
      localStorage.setItem('INSTITUTION', JSON.stringify(response.data.institution));
      //console.log(this.getRoutes(response.data.usuario.rol.privileges[0]))
      var path = '';
      routes.map((prop) => {
        if (prop.letter === response.data.usuario.rol.privileges[0]) {
          path = prop.layout + prop.path
        }
      });
      this.props.history.replace({ pathname: path, showFirstLoginModal: !response.data.usuario.first_session });
    } catch (err) {
      if (typeof err.response !== 'undefined' && typeof err.response.data !== 'undefined') {
        if (err.response.data.error === "invalid_credentials") {
          this.setState({
            showNotification: true,
            notificationMessage: "Correo electrónico y/o contraseña incorrectos"
          });
        } else {
          this.setState({
            showNotification: true,
            notificationMessage: "Error desconocido"
          });
        }
      } else {
        this.setState({
          showNotification: true,
          notificationMessage: "Error desconocido"
        });
      }
      console.log('ERROR', err);
    } finally {
      this.setState({ isSubmitting: false });
    }
  }

  render() {
    const {
      seePassword,
      email,
      password,
      notificationMessage,
      showNotification,
      isSubmitting,
    } = this.state;

    return (
      <form onSubmit={this.handleSubmit} className="content">
        <Grid fluid>
          <Row>
            <Col md={5} style={{ marginTop: "1%" }}>
              <Card
                content={
                  <Row style={{ paddingBottom: "25%" }}>

                    <h2 style={{ textAlign: "center" }}>BIENVENIDO A APLICACIÓN ESCOLAR</h2>

                    <Col md={1}> </Col>
                    <Col md={10} style={{ textAlign: "center", marginTop: "10%" }}>
                      <img
                        src={logo}
                        width="200"
                        alt=""
                      />
                      {showNotification && !isSubmitting && (
                        <Alert bsStyle="danger">
                          <button type="button" aria-hidden="true" className="close" onClick={() => this.setState({ showNotification: false })}>
                            &#x2715;
                          </button>
                          <span>{notificationMessage}</span>
                        </Alert>
                      )}
                      {!isSubmitting ? (
                        <>
                          <FormGroup style={styles.marginTop2}>
                            <Col md={12}>
                              <ControlLabel> <b> CORREO ELECTRÓNICO </b> </ControlLabel>
                              <FormControl required value={email} onChange={(e) => this.setState({ email: e.target.value })} type="email" name="email" />
                            </Col>
                          </FormGroup>
                          <FormGroup >
                            <Col md={12} style={styles.marginTop2}>
                              <ControlLabel> <b> CONTRASEÑA </b> </ControlLabel>
                            </Col>
                            <Col md={11}>
                              <FormControl
                                required
                                value={password}
                                onChange={(e) => this.setState({ password: e.target.value })}
                                type={seePassword === false ? "password" : "text"}
                                name="password"
                              />
                            </Col>
                            <Col md={1}>
                              <Button
                                bsStyle={seePassword ? "primary" : "default"}
                                style={styles.passButton}
                                onClick={() => this.setState((currentState) => ({
                                  seePassword: !currentState.seePassword
                                }))}>
                                <i className={seePassword ? "fa fa-eye-slash" : "fa fa-eye"} />
                              </Button>
                            </Col>
                          </FormGroup>
                          <Row>
                            <Col md={2}></Col>
                            <Col md={8}>
                              <Button bsStyle="info" fill block style={{ marginTop: "10%" }} type="submit" >
                                Ingresar
                              </Button>
                              <Button bsStyle="success" fill block style={{ marginTop: "3%" }} onClick={() => this.props.history.push('/login-parent')}>
                                Ingresar como Padre de Familia
                              </Button>
                            </Col>
                            <Col md={2}></Col>
                          </Row>
                          <Row>
                            <Col md={2}></Col>
                            <Col md={8} style={{ marginTop: "3%", marginLeft: "35%" }}>
                              <Button bsStyle="info" simple onClick={() => this.props.history.push('/forgot-password')}>
                                ¿Olvidaste tu contraseña?
                              </Button>
                            </Col>
                            <Col md={2}></Col>
                          </Row>
                        </>
                      ) : (
                        <Loader
                          type="Rings"
                          color="#00BFFF"
                          height={100}
                          width={100}
                        />
                      )}
                    </Col>
                    <Col md={1}></Col>
                    <Col md={12}>
                      <Col md={3}>
                        <a href="https://www.facebook.com/aplicacionescuelas/">
                          <img
                            src={imageFb}
                            width="60%"
                            alt=""
                            style={{ textAlign: "center", paddingTop: "20%" }}
                          />
                        </a>
                      </Col>
                      <Col md={4}>
                        <h4>TELÉFONO <br></br>
                          7223196667</h4>
                      </Col>
                      <Col md={5}>
                        <h4>CORREO <br></br>
                          appescolar01@gmail.com</h4>
                      </Col>
                    </Col>
                  </Row>
                }
              />
            </Col>
            <Col md={7} style={{ padding: "0px" }}>
              <img
                src={imageLogin}
                width="100%"
                height="945"
                alt=""
                style={{ objectPosition: 'center', objectFit: 'cover' }}
              />
            </Col>
          </Row>
        </Grid>
      </form>
    );
  }
}

export default Login;
