import React from 'react'
import { Modal, Row, Col, ControlLabel, FormGroup } from "react-bootstrap";
import Button from 'components/CustomButton/CustomButton';
import jsPDF from 'jspdf'
import QRCode from 'qrcode.react'
import styles from './styles'

class ModalQRAttendance extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  generatePDF = () => {
    const attendanceData = this.props.attendanceData;
    const canvas = document.getElementById('canvasQR');
    const imgData = canvas.toDataURL('image/png');
    const pdf = new jsPDF();
    pdf.text(50, 20, 'QR de asistencia - Aplicación Escolar v4.0');
    pdf.text(20, 30, 'Nombres: ' + attendanceData.name);
    pdf.text(20, 40, 'Apellidos: ' + attendanceData.last_name);
    pdf.text(20, 50, 'Matrícula: ' + attendanceData.code);
    pdf.text(20, 60, 'Padre de familia: ' + attendanceData.tutor.name + " " + attendanceData.tutor.last_name);
    pdf.text(20, 80, 'Código QR:');
    pdf.addImage(imgData, 'JPEG', 50, 90);
    pdf.save("loginQR-AEv4.pdf");
  }

  render() {
    const {
      onClose, open, value, attendanceData
    } = this.props;
    return (
      <Modal show={ open } onHide={ onClose }>
        <Modal.Header closeButton>
          <Modal.Title>QR de asistencia </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Row>
            <Col md={ 12 }>
              <FormGroup>
                <ControlLabel style={ styles.data }> NOMBRES </ControlLabel>
                <span style={ styles.data }>{ attendanceData.name }</span>
              </FormGroup>
              <FormGroup>
                <ControlLabel style={ styles.data }> APELLIDOS </ControlLabel>
                <span style={ styles.data }>{ attendanceData.last_name }</span>
              </FormGroup>
              <FormGroup>
                <ControlLabel style={ styles.data }> MATRICULA </ControlLabel>
                <span style={ styles.data }>{ attendanceData.code }</span>
              </FormGroup>
              <FormGroup>
                <ControlLabel style={ styles.data }> PADRE DE FAMILIA </ControlLabel>
                <span style={ styles.data }>
                  { attendanceData.tutor && `${attendanceData.tutor.name} ${attendanceData.tutor.last_name}` }
                </span>
              </FormGroup>
              <FormGroup>
                <ControlLabel style={ styles.data }> CÓDIGO QR </ControlLabel>
              </FormGroup>
            </Col>

            <Col md={ 12 } style={ { marginLeft: "25%" } }>

              <QRCode value={ value } id="canvasQR" size="256" />
            </Col>
            <Col md={ 12 } style={ { marginLeft: "35%", marginTop: "2%" } }>
              <Button bsStyle="primary" fill onClick={ this.generatePDF }><i className="fa fa-file-pdf-o"></i>Descargar en PDF</Button>
            </Col>
          </Row>
        </Modal.Body>
      </Modal>
    );
  }
}
export default ModalQRAttendance;