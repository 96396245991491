import React from 'react'
import { Modal, FormControl, Row, Col, FormGroup, ControlLabel } from "react-bootstrap";
import Button from 'components/CustomButton/CustomButton';
import styles from './styles.js'
import axios from 'components/axios';
import Loader from 'react-loader-spinner'
import { mensajeError500 } from "variables/Variables.jsx";
import ErrorNotification from 'components/Notifications/ErrorNotification.jsx'
import Select from 'react-select'

class GradeModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      id: '',
      name: '',
      order: '',
      levelId: '',
      levels: [],
      isSubmitting: false
    };
  }

  componentDidMount() {
    const gradeData = this.props.gradeData;
    if (gradeData !== null) {
      this.setState({
        id: gradeData.id,
        name: gradeData.name,
        order: gradeData.order,
        levelId: gradeData.level_id
      })
    }
    this.fetchLevelsId()
  }
  fetchLevelsId = async () => {
    this.setState({ isLoading: true });
    try {
      const response = await axios.post('/level/full_list');
      const newData = response.data.Levels.map((item) => {
        return { value: item.id, label: item.name }
      })
      this.setState({ levels: newData })
    } catch (err) {
      console.log('ERROR', err);
    } finally {
      this.setState({ isLoading: false });
    }
  }
  handleSubmit = async (e) => {
    e.preventDefault();
    const { id, name, levelId, order } = this.state;
    this.setState({ isSubmitting: true });
    try {
      var url;
      if (this.props.gradeData !== null) {
        url = `grade/edit/${id}`;
      } else {
        url = 'grade/add';
      }
      await axios.post(url, {
        name,
        level_id: levelId,
        order
      });
      this.props.reload();
      this.props.onClose();
    } catch (err) {
      if (typeof err.response !== 'undefined' && typeof err.response.data !== 'undefined') {
        if (err.response.status === 400) {
          this.setState({
            showNotification: true,
            notificationType: "danger",
            notificationMessage: err.response.data.error
          });
        } else {
          this.setState({
            showNotification: true,
            notificationType: "danger",
            notificationMessage: mensajeError500
          });
        }
      }
      console.log('ERROR', err);
    } finally {
      this.setState({ isSubmitting: false });
    }

  }

  getIdValue = (levels) => {
    const { levelId } = this.state;
    const filterResult = levels.filter(({ value }) => value === levelId);
    if (filterResult.length === 0) {
      return []
    }
    return filterResult
  }

  render() {
    const {
      onClose,
      show,
      gradeData
    } = this.props;
    const {
      name,
      order,
      isSubmitting,
      showNotification,
      notificationMessage,
      levels,
      isLoading
    } = this.state;
    return (
      <Modal show={ show } onHide={ onClose } backdrop="static">
        <form onSubmit={ this.handleSubmit }>
          <Modal.Header closeButton >
            <Modal.Title>
              { gradeData === null ?
                "Agregar un grado" : "Editar grado" }
            </Modal.Title>
          </Modal.Header>
          <Modal.Body style={ { wordWrap: "break-word" } }>
            <Row>
              <Col md={ 10 }>
                { showNotification &&
                  <ErrorNotification
                    notificationMessage={ notificationMessage }
                    closeNotification={ () => this.setState({ showNotification: false }) }
                  />
                }
                { !isSubmitting ? (
                  <>
                    <FormGroup>
                      <Col md={ 12 }>
                        <ControlLabel style={ styles.labelModal }> NIVEL </ControlLabel>
                        { !isLoading ?
                          <Col md={ 12 }>
                            <Select options={ levels }
                              name="levelId" className="basic-single"
                              classNamePrefix="select"
                              onChange={ (e) => this.setState({ 'levelId': e.value }) }
                              placeholder="Seleccione un nivel"
                              value={ levels && this.getIdValue(levels) }
                            />
                          </Col>
                          :
                          <Loader
                            type="Rings"
                            color="#00BFFF"
                            height={ 30 }
                            width={ 30 }
                            style={ { display: 'flex', justifyContent: 'center' } }
                          />
                        }
                      </Col>
                    </FormGroup>
                    <FormGroup>
                      <Col md={ 12 }>
                        <ControlLabel style={ styles.labelModal }> NOMBRE</ControlLabel>
                        <Col md={ 12 }>
                          <FormControl
                            required
                            name="name"
                            value={ name }
                            onChange={ (e) => this.setState({ name: e.target.value }) }
                          />
                        </Col>
                      </Col>
                    </FormGroup>
                    <FormGroup>
                      <Col md={ 12 }>
                        <ControlLabel style={ styles.labelModal }> ORDEN</ControlLabel>
                        <Col md={ 11 }>
                          <FormControl
                            required
                            type="number"
                            name="alias"
                            value={ order }
                            onChange={ (e) => this.setState({ order: e.target.value }) }
                          />
                        </Col>
                        <Col md={ 1 } style={ { marginLeft: "-6%" } }>
                          <Button
                            style={ { paddingLeft: "11px", paddingRight: "11px" } }
                            bsStyle="default"
                            title="El número de orden debe ser consecutivo para realizar el paso de ciclo"
                            simple
                          >
                            <b>?</b>
                          </Button>
                        </Col>
                      </Col>

                    </FormGroup>
                  </>
                ) : (
                    <Loader
                      type="Rings"
                      color="#00BFFF"
                      height={ 100 }
                      width={ 100 }
                      style={ { display: 'flex', justifyContent: 'center' } }
                    />
                  ) }


              </Col>
            </Row>
          </Modal.Body>
          <Modal.Footer >
            <Button bsStyle="success" fill type="submit" style={ styles.saveButton }>
              <i className="fa fa-save"></i> Guardar
            </Button>
          </Modal.Footer>
        </form>
      </Modal >
    );
  }
}
export default GradeModal