import React, { Component } from "react";
import { Row, Table, Col, Pager, Alert } from "react-bootstrap";
import Button from 'components/CustomButton/CustomButton';
import { mensajeError500 } from "variables/Variables.jsx";
import ConfirmDeleteModal from 'components/Modal/ConfirmDeleteModal.jsx';
import axios from 'components/axios';
import Loader from 'react-loader-spinner'
import styles from './styles'
import { generalRoute } from "variables/DomainRoutes.jsx";

class TypeMessageList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: false,
      isDeleting: false,
      showConfirmDelete: false,
      actionName: "",
      selectedType: {},
      types: [
        { id: 2, name: "cafe" }
      ],
      totalOfPages: 0,
      totalOfRecords: 0,
      showNotification: false,
      notificationMessage: '',
    };
  }
  async componentDidMount() {
    this.fetchTypes();
  }

  componentDidUpdate(prevProps) {
    if (prevProps.currentPage !== this.props.currentPage) {
      this.fetchTypes();
    }
  }

  fetchTypes = async () => {
    const { currentPage } = this.props;
    this.setState({ isLoading: true });
    try {
      const response = await axios.post('/type/list', {
        current_page: currentPage,
      });
      this.setState({
        types: response.data.Types.data,
        totalOfPages: response.data.Types.last_page,
        totalOfRecords: response.data.Types.total
      });
    } catch (err) {
      this.setState({
        showNotification: true,
        notificationMessage: mensajeError500
      });
      console.log('ERROR', err);
    } finally {
      this.setState({ isLoading: false });
    }
  }

  handleOpenConfirmDelete = (message) => {
    this.setState({
      showConfirmDelete: true,
      actionName: " de nombre " + message.name,
      selectedType: message,
    });
  };

  handleDelete = async () => {
    const { selectedType } = this.state;
    this.setState({ isDeleting: true });
    try {
      await axios.post(`type/delete/${selectedType.id}`);
      this.setState({ showConfirmDelete: false });
      this.fetchTypes();
    } catch (err) {
      this.setState({
        showNotification: true,
        notificationMessage: mensajeError500
      });
      console.log('ERROR', err);
    } finally {
      this.setState({ isDeleting: false });
    }
  }

  render() {
    const {
      editTypeMessage,
      currentPage,
      goToNextPage,
      goToPreviousPage,
    } = this.props;
    const {
      showConfirmDelete,
      actionName,
      isLoading,
      types,
      showNotification,
      notificationMessage,
      totalOfPages,
      totalOfRecords
    } = this.state;
    return !isLoading ? (
      <>
        <ConfirmDeleteModal
          onClose={ () => this.setState({ showConfirmDelete: false }) }
          show={ showConfirmDelete }
          thingToRemoveType="tipo de mensaje"
          thingToRemoveName={ actionName }
          onConfirm={ this.handleDelete }
        />
        <Row style={ { marginTop: "2%" } }>
          <Col md={ 12 }>
            { showNotification && (
              <Alert bsStyle="danger">
                <button type="button" aria-hidden="true" className="close" onClick={ () => this.setState({ showNotification: false }) }>
                  &#x2715;
                </button>
                <span>{ notificationMessage }</span>
              </Alert>
            ) }
            <Table striped hover >
              <thead>
                <tr>
                  <th>Ícono</th>
                  <th>Nombre</th>
                  <th>Acciones</th>
                </tr>
              </thead>
              <tbody>
                { types.map((message) => {
                  return (
                    <tr key={ message.id }>
                      <td>
                        <img alt={ message.icon } src={ generalRoute + message.icon } style={ styles.iconList } />
                      </td>
                      <td>{ message.name }</td>
                      <td>
                        <Button bsStyle="success" bsSize="sm" fill title="editar" onClick={ () => editTypeMessage(message) } ><i className="fa fa-edit"></i></Button>
                        <Button bsStyle="danger" bsSize="sm" fill title="Eliminar" onClick={ () => this.handleOpenConfirmDelete(message) } ><i className="fa fa-trash"></i></Button>
                      </td>
                    </tr>
                  );
                }) }
              </tbody>
            </Table>
            { types.length > 0 && (
              <>
                <Pager>
                  <Pager.Item
                    previous
                    disabled={ currentPage === 1 }
                    onClick={ goToPreviousPage }
                  >
                    <i className="fa fa-chevron-left"></i>  Anterior
                      </Pager.Item>
                  <Pager.Item>

                    { currentPage }/{ totalOfPages }

                  </Pager.Item>
                  <Pager.Item
                    disabled={ currentPage === totalOfPages }
                    next
                    onClick={ goToNextPage }
                  >
                    Siguiente <i className="fa fa-chevron-right"></i>
                  </Pager.Item>
                </Pager>
                <Pager>
                  <Pager.Item>
                    Total: { totalOfRecords } registros
              </Pager.Item>
                </Pager>
              </>
            ) }
          </Col>
        </Row>
      </>
    ) : (
        <Loader
          type="Rings"
          color="#00BFFF"
          height={ 100 }
          width={ 100 }
          style={ { display: 'flex', justifyContent: 'center' } }
        />
      );
  }
}

export default TypeMessageList;
