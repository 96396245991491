/*!

=========================================================
* Light Bootstrap Dashboard React - v1.3.0
=========================================================

* Product Page: https://www.creative-tim.com/product/light-bootstrap-dashboard-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/light-bootstrap-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import Dashboard from "views/Dashboard.jsx";
import UserProfile from "views/UserProfile.jsx";
import UserMenu from "views/users/UserMenu.jsx";
import LoadDatabase from "views/users/LoadDB.jsx";
import RolesMenu from "views/roles/RoleMenu.jsx";
import ParentsMenu from "views/users/ParentMenu";
import StudentsMenu from "views/users/StudenMenu";
import Message from "views/Message.jsx";
import Extracurricular from "views/extras/Extracurricular.jsx";
import LoadDBExtra from "views/extras/LoadDBExtra.jsx"
import Monitor from "views/monitor/Monitor.jsx";
import Attendance from "views/attendance/Attendance.jsx";
import Calendar from "views/calendar/Calendar.jsx";

import TableList from "views/TableList.jsx";
import Typography from "views/Typography.jsx";
import Icons from "views/Icons.jsx";
import Maps from "views/Maps.jsx";
import Notifications from "views/Notifications.jsx";
import Upgrade from "views/Upgrade.jsx";
import ConfSchool from "views/confs/ConfSchool.jsx";
import ConfPass from "views/confs/ConfPass.jsx";
import ConfSchoolEdit from "views/confs/ConfSchoolEdit.jsx";
import Settings from "views/confs/Settings.jsx";
import MessageTypes from "views/messages/MessageTypes.jsx";
import MessageTableHistory from "views/messages/MessageTableHistory.jsx";
import MessageTableRecord from "views/messages/MessageTableRecord";
import MessageTableFact from "views/messages/MessageTableFact";
import NewStudentMessage from "views/messages/NewStudentMessage";
import NewDegreeMessage from "views/messages/NewDegreeMessage";
import NewExtracurricular from "views/messages/NewExtracurricular";
import NewSpecific from "views/messages/NewSpecific";
import NewMassive from "views/messages/NewMassive";
import ConfLevels from "views/confs/ConfLevels";
import ConfProfileEdit from "views/confs/ConfProfileEdit";
import Score from "views/scores/Score";
import LoadDBScore from "views/scores/LoadDBScore";
import ConfCycles from "views/confs/ConfCycles";
import Course from "views/courses/Course";
import NewTeachingCourse from "views/courses/NewTeachingCourse";
import TeachingCourse from "views/courses/TeachingCourse";
import Homework from "views/homeworks/Homework";
import NewHomework from "views/homeworks/NewHomework";
import MyHomework from "views/tutorViews/MyHomework"
import Attribute from "views/monitor/Attribute";
import MonitorRecorList from "views/monitor/MonitorRecordList";
import LoadDBMonitor from "views/monitor/LoadDBMonitor";
import LoadPhotos from "views/users/students/LoadPhotos";
import Pay from "views/pay/Pay";
import PayForm from "views/pay/PayForm";
import PayLoadDB from "views/pay/PayLoadDB";
import PayPenalty from "views/pay/PayPenalty";
import AttendanceStudents from "views/attendance/AttendanceStudents";
import Scanner from "views/attendance/Scanner";
import ScannerForm from "views/attendance/ScannerForm";
import MyMessages from "views/tutorViews/MyMessages";

const dashboardRoutes = [
  {
    path: "/conf/profile",
    name: "CONFIGURACIONES",
    icon: "pe-7s-gleam",
    component: Settings,
    layout: "/admin",
    letter: 'l'
  },
  {
    path: "/dashboard",
    name: "ESTADÍSTICAS",
    icon: "pe-7s-graph",
    component: Dashboard,
    layout: "/admin",
    letter: 'a'
  },
  {
    path: "/userProfile",
    name: "User profile",
    icon: "pe-7s-user",
    component: UserProfile,
    layout: "/admin",
    invisible: true,
  },
  {
    path: "/users-parents/load-database",
    name: "Carga",
    icon: "pe-7s-users",
    component: LoadDatabase,
    layout: "/admin",
    invisible: true,
  },
  {
    path: "/users-parents/users-list",
    name: "USUARIOS Y PADRES",
    icon: "pe-7s-users",
    component: UserMenu,
    layout: "/admin",
    letter: 'b'
  },
  {
    path: "/users-parents/roles-list",
    name: "Lista de roles",
    icon: "pe-7s-users",
    component: RolesMenu,
    layout: "/admin",
    invisible: true,
  },
  {
    path: "/users-parents/parents-list",
    name: "Lista de padres",
    icon: "pe-7s-users",
    component: ParentsMenu,
    layout: "/admin",
    invisible: true,
  },
  {
    path: "/users-parents/students-list",
    name: "Lista de Estudiantes",
    component: StudentsMenu,
    layout: "/admin",
    invisible: true,
  },
  {
    path: "/users-parents/load-photos",
    name: "Cargar fotografías",
    component: LoadPhotos,
    layout: "/admin",
    invisible: true,
  },
  {
    path: "/users-parents/delete-db",
    name: "Borrar base de datos",
    icon: "pe-7s-users",
    component: "Borrar BD",
    layout: "/admin",
    invisible: true,
  },
  {
    path: "/message/index",
    name: "MENSAJES",
    icon: "pe-7s-mail",
    component: Message,
    layout: "/admin",
    letter: 'c',
  },
  {
    path: "/message/new-student",
    component: NewStudentMessage,
    layout: "/admin",
    invisible: true,
  },
  {
    path: "/message/new-degree",
    component: NewDegreeMessage,
    layout: "/admin",
    invisible: true,
  },
  {
    path: "/message/new-massive",
    component: NewMassive,
    layout: "/admin",
    invisible: true,
  },
  {
    path: "/message/new-specific",
    component: NewSpecific,
    layout: "/admin",
    invisible: true,
  },
  {
    path: "/message/new-extracurricular",
    component: NewExtracurricular,
    layout: "/admin",
    invisible: true,
  },
  {
    path: "/message/types",
    component: MessageTypes,
    layout: "/admin",
    invisible: true,
  },
  {
    path: "/message/history",
    component: MessageTableHistory,
    layout: "/admin",
    invisible: true,
  },
  {
    path: "/message/record",
    component: MessageTableRecord,
    layout: "/admin",
    invisible: true,
  },
  {
    path: "/message/fact",
    component: MessageTableFact,
    layout: "/admin",
    invisible: true,
  },
  {
    path: "/extra/index",
    name: "EXTRACURRICULARES",
    icon: "pe-7s-network",
    component: Extracurricular,
    layout: "/admin",
    letter: 'e'
  },
  {
    path: "/extra/load",
    component: LoadDBExtra,
    layout: "/admin",
    invisible: true,
  },
  {
    path: "/monitor/index",
    name: "SEGUIMIENTOS",
    icon: "pe-7s-notebook",
    component: Monitor,
    layout: "/admin",
    letter: 'f'
  },
  {
    path: "/monitor/attributes",
    component: Attribute,
    layout: "/admin",
    invisible: true,
  },
  {
    path: "/monitor/record",
    component: MonitorRecorList,
    layout: "/admin",
    invisible: true,
  },
  {
    path: "/monitor/load",
    component: LoadDBMonitor,
    layout: "/admin",
    invisible: true,
  },
  {
    path: "/score/index",
    name: "CALIFICACIONES",
    icon: "pe-7s-note2",
    component: Score,
    layout: "/admin",
    letter: 'g'
  },
  {
    path: "/score/load",
    component: LoadDBScore,
    layout: "/admin",
    invisible: true,
  },
  {
    path: "/pay/index",
    component: Pay,
    layout: "/admin",
    invisible: true,
  },
  {
    path: "/pay/load",
    name: "COBRANZA",
    icon: "pe-7s-cash",
    component: PayLoadDB,
    layout: "/admin",
    letter: 'j'
  },
  {
    path: "/pay/new",
    component: PayForm,
    layout: "/admin",
    invisible: true,
  },
  {
    path: '/pay/penalty',
    component: PayPenalty,
    layout: "/admin",
    invisible: true,
  },
  {
    path: "/attendance/index",
    name: "ASISTENCIAS",
    icon: "pe-7s-date",
    component: Attendance,
    layout: "/admin",
    letter: 'i'
  },
  {
    path: "/attendance/student",
    component: AttendanceStudents,
    layout: "/admin",
    invisible: true,
  },
  {
    path: "/attendance/list/scan",
    component: Scanner,
    layout: "/admin",
    invisible: true,
  },
  {
    path: "/attendance/new/scan",
    component: ScannerForm,
    layout: "/admin",
    invisible: true,
  },
  {
    path: "/calendar",
    name: "CALENDARIO",
    icon: "pe-7s-date",
    component: Calendar,
    layout: "/admin",
    letter: 'd'
  },
  {
    path: "/table",
    name: "Table List",
    icon: "pe-7s-note2",
    component: TableList,
    layout: "/admin",
    invisible: true
  },
  {
    path: "/typography",
    name: "Typography",
    icon: "pe-7s-news-paper",
    component: Typography,
    layout: "/admin",
    invisible: true,
  },
  {
    path: "/icons",
    name: "Icons",
    icon: "pe-7s-science",
    component: Icons,
    layout: "/admin",
    invisible: true,
  },
  {
    path: "/maps",
    name: "Maps",
    icon: "pe-7s-map-marker",
    component: Maps,
    layout: "/admin",
    invisible: true,
  },
  {
    path: "/notifications",
    name: "Notifications",
    icon: "pe-7s-bell",
    component: Notifications,
    layout: "/admin",
    invisible: true,
  },
  {
    upgrade: true,
    path: "/upgrade",
    name: "Upgrade to PRO",
    icon: "pe-7s-rocket",
    component: Upgrade,
    layout: "/admin",
    invisible: true,
  },
  {
    path: "/course/index",
    name: "MATERIAS",
    icon: "pe-7s-graph",
    component: Course,
    layout: "/admin",
    letter: 'h'
  },
  {
    path: "/course/load",
    component: TeachingCourse,
    layout: "/admin",
    invisible: true
  },
  {
    path: "/course/assign",
    component: NewTeachingCourse,
    layout: "/admin",
    invisible: true
  },
  {
    path: "/my-homework/index",
    name: "MIS TAREAS",
    icon: "pe-7s-users",
    component: MyHomework,
    layout: "/admin",
    tutor: true
  },
  {
    path: "/my-messages/index",
    name: "MIS MENSAJES",
    icon: "pe-7s-mail",
    component: MyMessages,
    layout: "/admin",
    tutor: true
  },
  {
    path: "/homework/index",
    name: "TAREAS",
    icon: "pe-7s-notebook",
    component: Homework,
    layout: "/admin",
    letter: 'k'
  },

  {
    path: "/homework/assign",
    component: NewHomework,
    layout: "/admin",
    invisible: true
  },

  {
    path: "/conf/edit-profile",
    component: ConfProfileEdit,
    layout: "/admin",
    invisible: true,
  },
  {
    path: "/conf/school",
    component: ConfSchool,
    layout: "/admin",
    invisible: true,
  },
  {
    path: "/conf/edit-school",
    component: ConfSchoolEdit,
    layout: "/admin",
    invisible: true,
  },
  {
    path: "/conf/pass",
    component: ConfPass,
    layout: "/admin",
    invisible: true,
  },
  {
    path: "/conf/levels",
    component: ConfLevels,
    layout: "/admin",
    invisible: true,
  },
  {
    path: "/conf/cycles",
    component: ConfCycles,
    layout: "/admin",
    invisible: true,
  }
];

export default dashboardRoutes;
