import React from 'react'
import { Modal, ProgressBar } from "react-bootstrap";
import Button from 'components/CustomButton/CustomButton';
import axios from 'components/axios';
import Loader from 'react-loader-spinner'
import ModalNotification from 'components/Notifications/ModalNotification.jsx'
import { mensajeError500 } from "variables/Variables.jsx";
import { uploadRoute } from "variables/DomainRoutes.jsx";

class LoadFileModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      file: null,
      percentage: 0,
      isLoading: false,
      loadFinished: false,
    };
    this.onChange = this.onChange.bind(this)
  }

  onChange(e) {
    this.setState({ file: e.target.files[0] })
    console.log('FILE', e.target.files[0]);
  }

  handleSendFile() {
    this.setState({ isLoading: true });
    const { actionTitle } = this.props;
    const body = new FormData();
    this.setState({ percentage: 56, isLoading: 1 });
    body.append('file_upload', this.state.file);
    body.append('institution_id', this.props.institutionId);
    body.append('type', this.props.type);
    axios
      .post(uploadRoute + 'uploads', body, {
        headers: {
          'content-type': 'multipart/form-data',
        },
      })
      .then(response => this.setState({
        loadFinished: true,
        showNotification: true,
        notificationType: "success",
        notificationMessage: actionTitle + " exitosa."
      }))
      .catch(err => {
        if (typeof err.response !== 'undefined' && typeof err.response.data !== 'undefined') {
          if (err.response.status === 400) {
            this.setState({
              showNotification: true,
              notificationType: "danger",
              notificationMessage: err.response.data.error
            });
          } else {
            this.setState({
              showNotification: true,
              notificationType: "danger",
              notificationMessage: mensajeError500
            });
          }
        }
        console.log('ERROR', err);
        this.setState({ loadFinished: false });
      })
      .finally(() => this.setState({ isLoading: false }))
  }

  render() {
    const {
      show, onFinish, onClose, awaitMessage,
      accept
    } = this.props;

    const {
      percentage, isLoading, file, loadFinished,
      showNotification,
      notificationMessage,
      notificationType
    } = this.state;

    return (
      <Modal show={ show } backdrop="static">
        <Modal.Header>
          <Modal.Title>
            { !isLoading && !loadFinished && "Elija su archivo" }
            { loadFinished &&
              "Archivo subido con éxito"
            }
            {
              isLoading && (
                <span>Espere, se está { awaitMessage } </span>
              )
            }
          </Modal.Title>
        </Modal.Header>
        <Modal.Body style={ { wordWrap: "break-word" } }>
          <div style={ { margin: "5px" } }>
            { showNotification &&
              < ModalNotification
                type={ notificationType }
                notificationMessage={ notificationMessage }
                closeNotification={ () => this.setState({ showNotification: false }) }
              />
            }
            {
              !isLoading && !loadFinished ?
                <input
                  type="file"
                  name="file_csv"
                  class="form-control"
                  onChange={ this.onChange }
                  accept={ accept ? accept : ".xlsx" }
                />
                :
                (
                  <>
                    {

                      isLoading && (
                        <>
                          <Loader
                            type="Rings"
                            color="#00BFFF"
                            height={ 100 }
                            width={ 100 }
                            style={ { marginLeft: "40%" } }
                          />
                        </>
                      )
                    }
                    {/* TODO: Add progressbar <span>Enviando archivo</span>
                    <ProgressBar now={ percentage } active label={ `${percentage}%` } /> */}
                  </>
                )
            }
          </ div>


        </Modal.Body>
        <Modal.Footer>
          {
            !isLoading && !loadFinished ?
              (
                <>
                  <Button bsStyle="default" fill onClick={ onClose }>
                    <i className="fa fa-times"></i> Cerrar
                  </Button>
                  <Button
                    bsStyle="info"
                    fill
                    onClick={ () => this.handleSendFile() }
                    disabled={ file === null ? true : false }
                  >
                    <i className="fa fa-send"></i> Enviar
                  </Button>
                </>
              )
              :
              <Button
                bsStyle="success"
                fill pullRight
                onClick={ onFinish }
                style={ { display: 'block' } }
              >
                <i className="fa fa-check"></i> Continuar
              </Button>
          }
        </Modal.Footer>
      </Modal>
    );
  };
}
export default LoadFileModal;