import React, { Component } from "react";
import { Row, Table, Col, Pager } from "react-bootstrap";
import { thMessageArray, deliveryTypes, mensajeError500 } from "variables/Variables.jsx";
import ErrorNotification from 'components/Notifications/ErrorNotification.jsx'
import Button from 'components/CustomButton/CustomButton';
import Checkbox from 'components/CustomCheckbox/CustomCheckbox';
import ConfirmDeleteModal from 'components/Modal/ConfirmDeleteModal.jsx';
import axios from 'components/axios';
import Loader from 'react-loader-spinner'
import HeaderMessageTable from 'views/messages/components/HeaderMessageTableFixed';

class MessageList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedCheckboxes: new Set(),
      showConfirmDelete: false,
      actionName: "",
      selectedMessage: '',
      messages: [],
      totalOfPages: 0,
      totalOfRecords: 0,
      isLoading: false,
      isDeleting: false,
      showNotification: false,
      notificationMessage: '',
      areCheckboxSelected: false,
      searchActive:false,
      searchShowNotification:false,
      searchQuestion:'',
      searchTimeStart:'',
      searchTimeEnd:'',
      searchShowNotification: false,
      searchNotificationType: '',
      searchNotificationMessage: '',
      searchIsLoading:false,
      searchIsHistory:false,
      openModal: false
    };
  }
  handleOpenConfirmDelete = (message) => {
    this.setState({
      showConfirmDelete: true,
      actionName: " con tipo " + message.type_details.name,
      selectedMessage: message.id,
    });
  };

  handleDelete = async () => {
    const { selectedMessage } = this.state;
    this.setState({ isDeleting: true });
    try {
      await axios.post('/message/delete/' + selectedMessage);
      this.setState({ showConfirmDelete: false });
      this.fetchMessages();
    } catch (err) {
      if (typeof err.response !== 'undefined' && typeof err.response.data !== 'undefined') {
        if (err.response.status === 400) {
          this.setState({
            showNotification: true,
            notificationType: "danger",
            notificationMessage: err.response.data.error
          });
        } else {
          this.setState({
            showNotification: true,
            notificationType: "danger",
            notificationMessage: mensajeError500
          });
        }
        console.log('ERROR', err);
      }
      console.log('ERROR', err);
      this.setState({ isDeleting: false, showConfirmDelete: false });
    } finally {
      this.setState({ isDeleting: false });
    }
  }

  async componentDidMount() {
    this.fetchMessages();
  }

  fetchMessages = async () => {
    const { currentPage } = this.props;
    this.setState({ isLoading: true });
    try {
      const response = await axios.post('messages/list', {
        current_page: currentPage,
      });
      this.setState({
        messages: response.data.messages.data,
        totalOfPages: response.data.messages.last_page,
        totalOfRecords: response.data.messages.total
      });
    } catch (err) {
      console.log('ERROR', err);
    } finally {
      this.setState({ isLoading: false });
    }
  }
  
  componentDidUpdate(prevProps) {
    if (prevProps.currentPage !== this.props.currentPage) {
      if(this.state.searchActive===false){
        this.fetchMessages();
      }else{
        this.onClickPagination();
      }
    }
  }

  renderNumber = (message) => {
    if (message.url5) {
      return 5;
    }
    if (message.url4) {
      return 4;
    }
    if (message.url3) {
      return 3;
    }
    if (message.url2) {
      return 2;
    }
    if (message.url1) {
      return 1;
    }
    return 0
  }

  toggleCheckbox(number) {
    const actual = this.state.selectedCheckboxes;
    if (actual.has(number)) {
      actual.delete(number);
    } else {
      actual.add(number);
    }
    this.setState({ selectedCheckboxes: actual })
  }

  joinIds = () => {
    const { selectedCheckboxes } = this.state;
    let ids = "";
    selectedCheckboxes.forEach((item) => {
      ids = ids + "," + item
    })
    return ids.substring(1);
  }

  handleDeleteMassive = async () => {
    this.setState({ isDeleting: true });
    try {
      await axios.post('message/masive/delete', {
        id: this.joinIds()
      });
      this.setState({ showConfirmDelete: false });
      this.fetchMessages();
      this.state.selectedCheckboxes.clear()
    } catch (err) {
      if (typeof err.response !== 'undefined' && typeof err.response.data !== 'undefined') {
        if (err.response.status === 400) {
          this.setState({
            showNotification: true,
            notificationType: "danger",
            notificationMessage: err.response.data.error
          });
        } else {
          this.setState({
            showNotification: true,
            notificationType: "danger",
            notificationMessage: mensajeError500
          });
        }
        console.log('ERROR', err);
      }
      console.log('ERROR', err);
      this.setState({ isDeleting: false, showConfirmDelete: false });
    } finally {
      this.setState({ isDeleting: false });
    }
  }

  onClickPagination = () => {
    const { currentPage } = this.props;
    const { searchQuestion,searchTimeStart,searchTimeEnd,searchIsHistory } = this.state
    this.fetchSearch(searchQuestion, searchTimeStart, searchTimeEnd, currentPage, searchIsHistory);
  }

  onClickButtonSearch = (question,start,end,isHistory) => {
    if (start == '') {
      this.setState({
        searchShowNotification: true,
        searchNotificationType: "danger",
        searchNotificationMessage: "Para buscar es necesario elegir una fecha de inicio."
      });
      return;
    }
    if (end == '') {
      this.setState({
        searchShowNotification: true,
        searchNotificationType: "danger",
        searchNotificationMessage: "Para buscar es necesario elegir una fecha de fin."
      });
      return;
    }
    this.setState({searchQuestion:question ,searchTimeStart:start ,searchTimeEnd:end, searchIsHistory:isHistory });
    this.props.restartPagination()
    this.fetchSearch(question,start,end,1,isHistory);
  }

  fetchSearch = async (question,start,end,currentPage, isHistory) => {
    this.setState({ searchActive: true})
    this.setState({ searchIsLoading: true });
    
    try {
      let response;
      if (isHistory) {
        response = await axios.post('/message/search', {
          current_page: currentPage,
          message: question,
          from: start + " 00:00:00",
          to: end + " 23:59:59"
        });
      } else {
        response = await axios.post('/message/search', {
          current_page: currentPage,
          subject: question,
          from: start + " 00:00:00",
          to: end + " 23:59:59"
        });
      }
      if (response.status === 200) {
        this.setState({
          searchShowNotification: true,
          searchNotificationType: "info",
          searchNotificationMessage: "Búsqueda finalizada. Se encontraron " + response.data.messages.total + " coincidecias.",
        });
      }
      this.setState({
        messages: response.data.messages.data,
        totalOfPages: response.data.messages.last_page
      });
    } catch (err) {
      if (typeof err.response !== 'undefined' && typeof err.response.data !== 'undefined') {
        if (err.response.status === 400) {
          this.setState({
            searchShowNotification: true,
            searchNotificationType: "danger",
            searchNotificationMessage: err.response.data.error
          });
        } else {
          this.setState({
            searchShowNotification: true,
            searchNotificationType: "danger",
            searchNotificationMessage: mensajeError500
          });
        }
      } else {
        this.setState({
          searchShowNotification: true,
          searchNotificationType: "danger",
          searchNotificationMessage: mensajeError500
        });
      }
    } finally {
      this.setState({ searchIsLoading: false });
    }
  }


  render() {
    const {
      detailMessage,
      currentPage,
      goToNextPage,
      goToPreviousPage,
    } = this.props;
    const {
      showConfirmDelete,
      actionName,
      isLoading,
      messages,
      notificationMessage,
      showNotification,
      totalOfPages,
      totalOfRecords,
      selectedCheckboxes
    } = this.state;
    return !isLoading ? (
      <>
        <HeaderMessageTable
          allowMassiveDelete="true"
          deleteDisabled={ selectedCheckboxes.size === 0 }
          deleteMasive={ this.handleDeleteMassive }
          excelRoute={ '/messages/excel' }
          search={ this.onClickButtonSearch }
          fetchDetails={ this.state }
        />
        <ConfirmDeleteModal
          onClose={ () => this.setState({ showConfirmDelete: false }) }
          show={ showConfirmDelete }
          thingToRemoveType="mensaje"
          thingToRemoveName={ actionName }
          onConfirm={ this.handleDelete }
        />
        <Row style={ { marginTop: "2%" } }>
          <Col md={ 12 }>
            { showNotification &&
              <ErrorNotification
                notificationMessage={ notificationMessage }
                closeNotification={ () => this.setState({ showNotification: false }) }
              />
            }
            <Table striped hover >
              <thead>
                <tr>
                  <th></th>
                  { thMessageArray.map((prop, key) => {
                    return <th key={ key }>{ prop }</th>;
                  }) }
                </tr>
              </thead>
              <tbody>
                { messages.map((message) => {
                  const deliveryAsString = typeof deliveryTypes[message.type] !== 'undefined' ? deliveryTypes[message.type] : '';
                  return (
                    <tr key={ message.id }>
                      <td>
                        <Checkbox
                          number={ "message" + message.id }
                          onClick={ () => { this.toggleCheckbox(message.id) } }
                        />
                      </td>
                      <td>{ message.type_details && message.type_details.name }</td>
                      <td>{ deliveryAsString }</td>
                      <td>{ message.count_destiny }</td>
                      <td>{ message.subject }</td>
                      <td>
                        <span className="label label-info">
                          <i className="fa fa-link"></i>
                          {
                            this.renderNumber(message)
                          }
                        </span>
                      </td>
                      <td>
                        <span className="label label-primary">
                          <i className="fa fa-paperclip"></i>{
                            message.attachment ? message.attachment.split(',').length : 0
                          }
                        </span>
                      </td>
                      <td>{ message.created_at }</td>
                      <td>{ message.programmed === true ? "Si" : "No" }</td>
                      <td>
                        <Button bsStyle="info" bsSize="sm" fill title="Ver detalles" onClick={ () => detailMessage(message) } ><i className="fa fa-search"></i></Button>
                        <Button bsStyle="danger" bsSize="sm" fill title="Eliminar" onClick={ () => this.handleOpenConfirmDelete(message) } ><i className="fa fa-trash"></i></Button>
                      </td>
                    </tr>
                  );
                }) }
              </tbody>
            </Table>
            { messages.length > 0 && (
              <>
                <Pager>
                  <Pager.Item
                    previous
                    disabled={ currentPage === 1 || this.state.searchIsLoading }
                    onClick={ goToPreviousPage }
                  >
                    <i className="fa fa-chevron-left"></i>  Anterior
                      </Pager.Item>
                  <Pager.Item>

                    { currentPage }/{ totalOfPages }

                  </Pager.Item>
                  <Pager.Item
                    disabled={ currentPage === totalOfPages || this.state.searchIsLoading }
                    next
                    onClick={ goToNextPage }
                  >
                    Siguiente <i className="fa fa-chevron-right"></i>
                  </Pager.Item>
                </Pager>
                <Pager>
                  <Pager.Item>
                    Total: { totalOfRecords } registros
                  </Pager.Item>
                </Pager>
              </>
            ) }
          </Col>
        </Row>
      </>
    ) : (
        <Loader
          type="Rings"
          color="#00BFFF"
          height={ 100 }
          width={ 100 }
          style={ { display: 'flex', justifyContent: 'center' } }
        />
      );
  }
}

export default MessageList;
