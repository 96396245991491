import React, { Component } from "react";
import { Card } from "components/Card/Card.jsx";
import { Grid, Row, Table, Col, Pager } from "react-bootstrap";
import { thMessageRecordArray } from "variables/Variables.jsx";
import Button from 'components/CustomButton/CustomButton';
import HeaderMessageTable from 'views/messages/components/HeaderMessageTableFixed';
import axios from 'components/axios';
import Loader from 'react-loader-spinner'
import ModalNotification from 'components/Notifications/ModalNotification.jsx'
import { mensajeError500 } from "variables/Variables.jsx";

class MessageTableRecord extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: false,
      messages: [],
      totalOfPages: 0,
      totalOfRecords: 0,
      currentPage: 1,
      searchActive:false,
      searchShowNotification:false,
      searchQuestion:'',
      searchTimeStart:'',
      searchTimeEnd:'',
      searchShowNotification: false,
      searchNotificationType: '',
      searchNotificationMessage: '',
      searchIsLoading:false,
      searchIsHistory:false
    };
  }
  async componentDidMount() {
    this.fetchMessages();
  }

  fetchMessages = async () => {
    const { currentPage } = this.props;
    this.setState({ isLoading: true });
    try {
      const response = await axios.post('messages/register/deleted', {
        current_page: currentPage,
      });
      this.setState({
        messages: response.data.messages.data,
        totalOfPages: response.data.messages.last_page,
        totalOfRecords: response.data.messages.total,
      });
    } catch (err) {
      console.log('ERROR', err);
    } finally {
      this.setState({ isLoading: false });
    }
  }

  goToNextPage = () => {
    this.setState(
      (currentState) => ({ currentPage: currentState.currentPage + 1 }),
      this.state.searchActive ? this.onClickPagination : this.fetchMessages
    );
  }

  goToPreviousPage = () => {
    this.setState((currentState) => ({ currentPage: currentState.currentPage - 1 }),
    this.state.searchActive ? this.onClickPagination : this.fetchMessages  
    );
  }

  onClickPagination = () => {
    const { currentPage, searchQuestion,searchTimeStart,searchTimeEnd,searchIsHistory } = this.state
    this.fetchSearch(searchQuestion, searchTimeStart, searchTimeEnd, currentPage, searchIsHistory);
  }

  onClickButtonSearch = (question,start,end,isHistory) => {
    if (start == '') {
      this.setState({
        searchShowNotification: true,
        searchNotificationType: "danger",
        searchNotificationMessage: "Para buscar es necesario elegir una fecha de inicio."
      });
      return;
    }
    if (end == '') {
      this.setState({
        searchShowNotification: true,
        searchNotificationType: "danger",
        searchNotificationMessage: "Para buscar es necesario elegir una fecha de fin."
      });
      return;
    }
    this.setState({searchQuestion:question ,searchTimeStart:start ,searchTimeEnd:end, searchIsHistory:isHistory });
    this.setState({ currentPage: 1 })
    this.fetchSearch(question,start,end,1,isHistory);
  }

  fetchSearch = async (question,start,end,currentPage,isHistory) => {
    this.setState({ searchActive: true})
    this.setState({ searchIsLoading: true });
    try {
      let response;
      if (isHistory) {
        response = await axios.post('/message/search/register', {
          current_page: currentPage,
          message: question,
          from: start + " 00:00:00",
          to: end + " 23:59:59"
        });
      } else {
        response = await axios.post('/message/search/register', {
          current_page: currentPage,
          subject: question,
          from: start + " 00:00:00",
          to: end + " 23:59:59"
        });
      }
      if (response.status === 200) {
        this.setState({
          searchShowNotification: true,
          searchNotificationType: "info",
          searchNotificationMessage: "Búsqueda finalizada. Se encontraron " + response.data.messages.total + " coincidecias.",
        });
      }
      this.setState({
        messages: response.data.messages.data,
        totalOfPages: response.data.messages.last_page
      });
    } catch (err) {
      if (typeof err.response !== 'undefined' && typeof err.response.data !== 'undefined') {
        if (err.response.status === 400) {
          this.setState({
            searchShowNotification: true,
            searchNotificationType: "danger",
            searchNotificationMessage: err.response.data.error
          });
        } else {
          this.setState({
            searchShowNotification: true,
            searchNotificationType: "danger",
            searchNotificationMessage: mensajeError500
          });
        }
      } else {
        this.setState({
          searchShowNotification: true,
          searchNotificationType: "danger",
          searchNotificationMessage: mensajeError500
        });
      }
    } finally {
      this.setState({ searchIsLoading: false });
    }
  }

  renderNumber = (message) => {
    if (message.url5 !== null && message.url5 !== "") {
      return 5;
    }
    if (message.url4 !== null && message.url4 !== "") {
      return 4;
    }
    if (message.url3 !== null && message.url3 !== "") {
      return 3;
    }
    if (message.url2 !== null && message.url2 !== "") {
      return 2;
    }
    if (message.url1 !== null && message.url1 !== "") {
      return 1;
    }
    return 0
  }


  restoreMessages = async (messageId) => {
    this.setState({ isLoading: true });
    try {
      const response = await axios.post('/message/restore', {
        message_id: messageId,
      });
      this.fetchMessages();
      this.setState({
        showNotification: true,
        notificationType: "success",
        notificationMessage: "Mensaje restaurado"
      });

    } catch (err) {
      if (typeof err.response !== 'undefined' && typeof err.response.data !== 'undefined') {
        if (err.response.status === 400) {
          this.setState({
            showNotification: true,
            notificationType: "danger",
            notificationMessage: err.response.data.error
          });
        } else {
          this.setState({
            showNotification: true,
            notificationType: "danger",
            notificationMessage: mensajeError500
          });
        }
        console.log('ERROR', err);
      }
      console.log('ERROR', err);
    } finally {
      this.setState({ isLoading: false });
    }
  }

  render() {
    const {
      isLoading,
      messages,
      totalOfPages,
      totalOfRecords,
      currentPage,
      showNotification,
      notificationMessage,
      notificationType
    } = this.state;

    return !isLoading ? (
      <div style={ { marginLeft: '30px', marginRight: '30px' } }>
        <Card
          cardStyle={ {
            boxShadow: 'none',
          } }
          content={
            <Grid fluid>
              <HeaderMessageTable
                allowMassiveDelete="false"
                deleteDisabled={ false }
                currentPage={ currentPage }
                excelRoute={ '/messages/excel_register' }
                search={ this.onClickButtonSearch }
                fetchDetails={ this.state }
              />
              <Row style={ { marginTop: "2%" } }>
                <Col md={ 12 }>
                  { showNotification &&
                    <ModalNotification
                      notificationMessage={ notificationMessage }
                      closeNotification={ () => this.setState({ showNotification: false }) }
                      type={ notificationType }
                    />
                  }
                </Col>
                <Col md={ 12 }>
                  <Table striped hover >
                    <thead>
                      <tr>
                        { thMessageRecordArray.map((prop, key) => {
                          return <th key={ key }>{ prop }</th>;
                        }) }
                      </tr>
                    </thead>
                    <tbody>
                      { messages.map((message) => {
                        return (
                          <tr key={ message.message_id }>
                            <td>{ message.subject }</td>
                            <td> { message.emisor && message.emisor }</td>
                            <td>{ message.send_date }</td>
                            <td>
                              <span className="label label-info"><i className="fa fa-link"></i>
                                {
                                  this.renderNumber(message)
                                }
                              </span>
                            </td>
                            <td>
                              <span className="label label-primary"><i className="fa fa-paperclip"></i>
                                {
                                  message.attachment ? message.attachment.split(',').length : 0
                                }
                              </span>
                            </td>
                            <td>{ message.responsable && `${message.responsable.name} ${message.responsable.last_name}` }</td>
                            <td>{ message.created_at }</td>
                            <td><Button bsStyle="primary" bsSize="sm" fill onClick={ () => this.restoreMessages(message.id) } >Restaurar</Button></td>
                          </tr>
                        );
                      }) }
                    </tbody>
                  </Table>
                  { messages.length > 0 && (
                    <>
                      <Pager>
                        <Pager.Item
                          previous
                          disabled={ currentPage === 1 }
                          onClick={ this.goToPreviousPage }
                        >
                          <i className="fa fa-chevron-left"></i>  Anterior
                      </Pager.Item>
                        <Pager.Item>

                          { currentPage }/{ totalOfPages }

                        </Pager.Item>
                        <Pager.Item
                          disabled={ currentPage === totalOfPages }
                          next
                          onClick={ this.goToNextPage }
                        >
                          Siguiente <i className="fa fa-chevron-right"></i>
                        </Pager.Item>
                      </Pager>
                      <Pager>
                        <Pager.Item>
                          Total: { totalOfRecords } registros
                        </Pager.Item>
                      </Pager>
                    </>
                  ) }
                </Col>
              </Row>
            </Grid>
          }
        />
      </div>
    ) : (
        <Loader
          type="Rings"
          color="#00BFFF"
          height={ 100 }
          width={ 100 }
          style={ { display: 'flex', justifyContent: 'center' } }
        />
      );
  }
}

export default MessageTableRecord;
