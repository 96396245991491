import React, { Component } from "react";
import ChartistGraph from "react-chartist";
import { Grid, Row, Col } from "react-bootstrap";
import { Card } from "components/Card/Card.jsx";
import {
  legendPie,
  legendPie1,
  optionsBar,
  legendBar
} from "variables/Variables.jsx";
import FirstLoginModal from "components/Modal/FirstLoginModal";
import axios from 'components/axios';

class Dashboard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showModalFirstLogin: typeof props.location.showFirstLoginModal !== 'undefined' ? props.location.showFirstLoginModal : false,
      isTutor: false,
      msgSent: 0,
      msgRead: 0,
      msgNotRead: 0,
      fcmsTotal: 0,
      fcmsAndroid: 0,
      fcmsIos: 0,
      usersTotal: 0,
      tutorsTotal: 0,
      diff: 0,
      dataBar: {}
    };
    const institutionData = JSON.parse(localStorage.getItem('INSTITUTION'));
    this.startLicense = institutionData.license_start_date;
    this.endLicense = institutionData.free_limit;
  }

  async componentDidMount() {
    const localUser = JSON.parse(localStorage.getItem('USER'))
    this.setState({ isTutor: localUser.role_id === 4 });
    this.fetchData();
    var fechaInicio = new Date().getTime();
    var fechaFin = new Date(this.endLicense).getTime();
    var seconds_left = (fechaFin - fechaInicio) / 1000;
    var days = parseInt(seconds_left / 86400);
    this.fetchRecords();
    this.setState({ diff: days });
  }

  fetchData = async () => {
    this.setState({ isLoading: true });
    try {
      const response = await axios.post('/message/statistics');
      this.setState({
        msgSent: response.data.msg_sent,
        msgRead: response.data.msg_read,
        msgNotRead: response.data.msg_not_read,
        fcmsTotal: response.data.fcms_total,
        fcmsAndroid: response.data.fcms_android,
        fcmsIos: response.data.fcms_ios,
        usersTotal: response.data.users_total,
        tutorsTotal: response.data.tutors_total
      });
    } catch (err) {
      console.log('ERROR', err);
    } finally {
      this.setState({ isLoading: false });
    }
  }

  fetchRecords = async () => {
    this.setState({ isLoading: true });
    try {
      const response = await axios.post('/assist/countassists', {
        from: this.startLicense
      });

      var dataBar = {
        labels: [
          "Módulo Scan"
        ],
        series: [
          [response.data.entradas],
          [response.data.salidas]
        ]
      };
      this.setState({ dataBar: dataBar });
    } catch (err) {
      console.log('ERROR', err);
    } finally {
      this.setState({ isLoading: false });
    }
  }

  createLegend(json) {
    var legend = [];
    for (var i = 0; i < json["names"].length; i++) {
      var type = "fa fa-circle text-" + json["types"][i];
      legend.push(<i className={type} key={i} />);
      legend.push(" ");
      legend.push(json["names"][i]);
    }
    return legend;
  }

  getPieDataMessages() {
    const {
      msgSent,
      msgRead,
      msgNotRead
    } = this.state;
    const per1 = Math.round(msgRead * 100 / msgSent);
    const per2 = Math.round(msgNotRead * 100 / msgSent);
    return {
      labels: [`${per1}%`, `${per2}%`],
      series: [msgRead, msgNotRead],
      colors: ["#333", "#222"]
    }
  }

  getPieDataFcm() {
    const {
      fcmsTotal,
      fcmsAndroid,
      fcmsIos,
    } = this.state;
    const per1 = Math.round(fcmsIos * 100 / fcmsTotal);
    const per2 = Math.round(fcmsAndroid * 100 / fcmsTotal);
    return {
      labels: [`${per1}%`, `${per2}%`],
      series: [fcmsIos, fcmsAndroid]
    }
  }

  handleCloseModalFirstLogin = () => { this.setState({ showModalFirstLogin: false }); };

  render() {
    const {
      usersTotal,
      tutorsTotal,
      msgSent,
      dataBar,
      diff
    } = this.state;
    return this.state.isTutor ?
      <div className="content" style={{ height: "1000px" }}>

      </div>
      : (
        <div className="content">
          {this.state.showModalFirstLogin && <FirstLoginModal history={this.props.history} onClose={this.handleCloseModalFirstLogin} open={this.state.showModalFirstLogin} />}
          <Grid fluid>
            <Row>
              <Row>
                <Col md={4}>
                  <Card
                    title="N° de mensajes"
                    content={
                      msgSent === 0 ? <h4>No tiene mensajes  </h4> :
                        <div
                          id="chartPreferences"
                          className="ct-chart ct-perfect-fourth"
                        >
                          <ChartistGraph data={this.getPieDataMessages()} type="Pie" />
                        </div>
                    }
                    legend={
                      <div className="legend">{this.createLegend(legendPie)}</div>
                    }
                  />
                </Col>
                <Col md={4}>
                  <Card
                    title="Cantidad de dispositivos instalados"
                    content={
                      <div
                        id="chartPreferences"
                        className="ct-chart ct-perfect-fourth"
                      >
                        <ChartistGraph data={this.getPieDataFcm()} type="Pie" />
                      </div>
                    }
                    legend={
                      <div className="legend">{this.createLegend(legendPie1)}</div>
                    }
                  />
                </Col>
                <Col md={4}>
                  <Card
                    id="chartActivity"
                    title="Cantidad de entradas y salidas por fecha"
                    content={
                      <div className="ct-chart">
                        <ChartistGraph
                          data={dataBar}
                          type="Bar"
                          options={optionsBar}
                        />
                      </div>
                    }
                    legend={
                      <div className="legend">{this.createLegend(legendBar)}</div>
                    }
                  />
                </Col>
              </Row>
              <Row>
                <Col md={4}>
                  <Card
                    title="Cantidad de usuarios administradores"
                    hCenter={true}
                    content={
                      <div style={{ textAlign: "center", marginTop: "-6px" }}>
                        <h1> <i className="pe-7s-user text-success"></i> {usersTotal}</h1>
                      </div>
                    }
                  />
                </Col>
                <Col md={4}>
                  <Card
                    title="Cuantos padres de familia"
                    hCenter={true}
                    content={
                      <div style={{ textAlign: "center", marginTop: "-6px" }}>
                        <h1> <i className="pe-7s-users text-primary"></i> {tutorsTotal}</h1>
                      </div>
                    }
                  />
                </Col>
                <Col md={4}>
                  <Card
                    title="Licencia de la escuela"
                    hCenter={true}
                    content={
                      <Row>
                        <Col md={4}>
                          <div style={{ textAlign: "center", marginTop: "-6px" }}>
                            <h1> <i className="pe-7s-gleam text-warning"></i></h1>
                          </div>
                        </Col>
                        <Col md={8} style={{ marginTop: "5%" }}>
                          <h5>
                            Fecha de inicio: <b>{this.startLicense}</b>
                          </h5>
                          <h5>
                            Fecha de fin: <b>{this.endLicense}</b>
                          </h5>
                        </Col>
                      </Row>
                    }
                  />
                </Col>
              </Row>
              <Row>
                <Col md={4}>
                  <Card
                    title={diff >= 0 ?`Falta ${diff} días para que se acabe su licencia.` : '¡Su licencia ha expirado!' }
                    hCenter={true}
                    content={
                      <>
                        <Row>
                          <Col md={4}>
                            <div style={{ textAlign: "center", marginTop: "-6px" }}>
                              <h1> <i className="pe-7s-stopwatch"></i></h1>
                            </div>
                          </Col>
                          <Col md={8} style={{ marginTop: "4%" }}>
                            <h5 style={{ display: "flex", gap: '15px', alignItems: 'center' }}>
                              <div style={{ width: '130px', textAlign: 'right' }}>Soporte Directo:</div> <b style={{ display: "flex", flexDirection: "column" }}><b>7292775116</b> <b>7225269873</b></b>
                            </h5>
                            <h5 style={{ display: "flex", gap: '15px', alignItems: 'center' }}>
                              <div style={{ width: '130px', textAlign: 'right' }}>Oficina:</div> <b>7229247249 opcion 3</b>
                            </h5>
                          </Col>
                        </Row>
                      </>
                    }
                  />
                </Col>
                <Col md={3} style={{ marginLeft: "4%" }}>
                  <div
                    className="fb-page fb_iframe_widget"
                    data-href="https://www.facebook.com/aplicacionescuelas/"
                    data-tabs="timeline"
                    data-small-header="false"
                    data-adapt-container-width="true"
                    data-hide-cover="false"
                    data-show-facepile="true"
                    fb-xfbml-state="rendered"
                    fb-iframe-plugin-query="adapt_container_width=true&amp;app_id=1451370551831606&amp;container_width=487&amp;hide_cover=false&amp;href=https%3A%2F%2Fwww.facebook.com%2Faplicacionescuelas%2F&amp;locale=es_LA&amp;sdk=joey&amp;show_facepile=true&amp;small_header=false&amp;tabs=timeline">
                    <span style={{ verticalAlign: "bottom", width: "440px", height: "500px" }}>
                      <iframe
                        name="f9a30117531b6c"
                        width="1000px"
                        height="1000px"
                        data-testid="fb:page Facebook Social Plugin"
                        title="fb:page Facebook Social Plugin"
                        frameBorder="0"
                        allowtransparency="true"
                        allowFullScreen={true}
                        scrolling="no"
                        allow="encrypted-media"
                        src="https://www.facebook.com/v2.8/plugins/page.php?adapt_container_width=true&amp;app_id=1451370551831606&amp;channel=https%3A%2F%2Fstaticxx.facebook.com%2Fx%2Fconnect%2Fxd_arbiter%2F%3Fversion%3D46%23cb%3Df3f05a29539c44%26domain%3Dapp.aplicacionescolar.com.mx%26origin%3Dhttps%253A%252F%252Fapp.aplicacionescolar.com.mx%252Ff1fad78c93c19c%26relation%3Dparent.parent&amp;container_width=487&amp;hide_cover=false&amp;href=https%3A%2F%2Fwww.facebook.com%2Faplicacionescuelas%2F&amp;locale=es_LA&amp;sdk=joey&amp;show_facepile=true&amp;small_header=false&amp;tabs=timeline"
                        style={{ border: "none", visibility: "visible", width: "440px", height: "500px" }}
                      ></iframe>
                    </span>
                  </div>
                </Col>
                <Col md={4} style={{ marginLeft: "4%" }}>
                  <Card
                    title="Padres que pagaron con pasarela de pago"
                    hCenter={true}
                    content={
                      <div style={{ textAlign: "center", marginTop: "-6px" }}>
                        <h1> <i className="pe-7s-users text-primary"></i> 0</h1>
                      </div>
                    }
                  />
                </Col>
              </Row>
            </Row>
          </Grid>
        </div >
      );
  }
}

export default Dashboard;
