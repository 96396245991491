import React from 'react'
import Button from 'components/CustomButton/CustomButton';
import { Form, FormGroup, FormControl, Col } from "react-bootstrap";
import styles from './styles'
import ModalNotification from 'components/Notifications/ModalNotification.jsx'
import { mensajeError500 } from "variables/Variables.jsx";
import axios from 'components/axios';
import Loader from 'react-loader-spinner'
import Select from 'react-select'

class SearchInputStudent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      target: '',
      levelId: '',
      levels: [],
      gradeId: '',
      grades: [],
      groupId: '',
      groups: [],
      loadSelect: 0
    };
  }

  async componentDidMount() {
    this.fetchLevels();
  }

  fetchLevels = async () => {
    this.setState({ loadSelect: 1 });
    try {
      const response = await axios.post('/level/full_list');
      const newData = response.data.Levels.map((item) => {
        return { value: item.id, label: item.name }
      })
      newData.push({ value: 0, label: "Todos los niveles" })
      this.setState({ levels: newData })
    } catch (err) {
      console.log('ERROR', err);
    } finally {
      this.setState({ loadSelect: 0 });
    }
  }
  fetchGrades = async (level1) => {
    this.setState({ loadSelect: 2 });
    try {
      const response = await axios.post('/grade/by/level', { level_id: level1 });
      const newData = response.data.Grades.map((item) => {
        return { value: item.id, label: item.name }
      })
      newData.push({ value: 0, label: "Todos los grados" })
      this.setState({ grades: newData })
    } catch (err) {
      console.log('ERROR', err);
    } finally {
      this.setState({ loadSelect: 0 });
    }
  }

  fetchGroups = async () => {
    this.setState({ loadSelect: 3 });
    try {
      const response = await axios.post('/group/full_list');
      const newData = response.data.Groups.map((item) => {
        return { value: item.id, label: item.name }
      })
      newData.push({ value: 0, label: "Todos los grupos" })
      this.setState({ groups: newData })
    } catch (err) {
      console.log('ERROR', err);
    } finally {
      this.setState({ loadSelect: 0 });
    }
  }

  doSearch = async () => {
    const {
      target,
      gradeId,
      groupId,
      levelId
    } = this.state;
    const {
      search
    } = this.props;
    search(target,gradeId,groupId,levelId)
  }

  render() {
    const {
      levels,
      grades,
      groups,
      loadSelect
    } = this.state;
    const {
      fetchDetails,
      closeNotification
    } = this.props
    const {
      searchNotificationMessage,
      searchNotificationType,
      searchIsLoading,
      searchShowNotification,
    }  = fetchDetails
    return (
      <Form>
        <FormGroup>
          <Col md={ 9 }>
            <Col md={ 6 }>
              <FormControl
                type="text"
                placeholder="Buscar"
                onChange={ (e) => this.setState({ target: e.target.value }) }
              />
            </Col>
            <Col md={ 2 }>

              { loadSelect === 1 ?
                <Loader
                  type="Rings"
                  color="#00BFFF"
                  height={ 30 }
                  width={ 30 }
                  style={ { display: 'flex', justifyContent: 'center' } }
                />
                :
                <Select options={ levels }
                  name="levelId" className="basic-single"
                  classNamePrefix="select"
                  placeholder="nivel ..."
                  onChange={ (e) => { this.setState({ 'levelId': e.value }); this.fetchGrades(e.value) } }
                />
              }
            </Col>
            <Col md={ 2 }>
              { loadSelect === 2 ?
                <Loader
                  type="Rings"
                  color="#00BFFF"
                  height={ 30 }
                  width={ 30 }
                  style={ { display: 'flex', justifyContent: 'center' } }
                />
                :
                <Select options={ grades }
                  name="gradeId" className="basic-single"
                  classNamePrefix="select"
                  onChange={ (e) => { this.setState({ 'gradeId': e.value }); this.fetchGroups() } }
                  placeholder="grado ..."
                  isDisabled={ grades.length === 0 }
                />
              }
            </Col>
            <Col md={ 2 }>
              { loadSelect === 3 ?
                <Loader
                  type="Rings"
                  color="#00BFFF"
                  height={ 30 }
                  width={ 30 }
                  style={ { display: 'flex', justifyContent: 'center' } }
                />
                :
                <Select options={ groups }
                  name="groupId" className="basic-single"
                  classNamePrefix="select"
                  onChange={ (e) => this.setState({ 'groupId': e.value }) }
                  placeholder="grupo ..."
                  isDisabled={ groups.length === 0 }
                />
              }
            </Col>
          </Col>
          <Col md={ 1 }>
            <Button
              bsStyle="success"
              fill
              block
              disabled={ (this.state.target==='' && this.state.levelId==='') || searchIsLoading }
              style={ styles.searchButton }
              onClick={ () => this.doSearch() }
            >Buscar</Button>
          </Col>
          <Col md={ 2 }>
            { searchIsLoading &&
              <>
                <Col md={ 4 }>
                  <Loader
                    type="Rings"
                    color="#00BFFF"
                    height={ 40 }
                    width={ 40 }
                    style={ { display: 'flex', justifyContent: 'center', marginTop: '2%' } }
                  />
                </Col>
                <Col md={ 6 }
                  style={ { display: 'flex', marginTop: '3%' } }
                >... Buscando</Col>
              </>
            }
          </Col>
        </FormGroup>
        <Col md={ 12 }>
          { searchShowNotification &&
            <ModalNotification
              notificationMessage={ searchNotificationMessage }
              closeNotification={ () => closeNotification() }
              type={ searchNotificationType }
            />
          }
        </Col>
      </Form>

    );
  }
}
export default SearchInputStudent