import React, { Component } from "react";
import { Row, Col, Form, FormControl, FormGroup, ControlLabel } from "react-bootstrap";
import Button from 'components/CustomButton/CustomButton';
import styles from "./styles";
import Select from 'react-select'
import { mensajeError500 } from "variables/Variables.jsx";
import { gRoute } from "variables/DomainRoutes.jsx";
import axios from 'components/axios';
import ModalNotification from 'components/Notifications/ModalNotification.jsx';
import ModalExcel from 'components/ModalExcel';

class PayHeader extends Component {
  constructor(props) {
    super(props);
    this.state = {
      start: '',
      end: '',
      question: '',
      urlExcel: '',
      openModal: false
    };
    this.options = [
      { value: 0, label: "Todos" },
      { value: 1, label: "Pagado" },
      { value: 2, label: "En espera" }
    ];
    this.controlModal = this.controlModal.bind(this);
  }

  controlModal(show) {
    this.setState({ openModal: show || false });
  }

  onChange(e) {
    this.setState({ [e.target.name]: e.target.value })
  }

  doSearch = async () => {
    const { question, start, end, status } = this.state;
    const { search } = this.props;
    search(question, start, end, status);
  }

  render() {
    const {
      deleteDisabled,
      deleteMasive,
      loading,
      urlExcel,
      fetchDetails
    } = this.props;
    const {
      openModal
    } = this.state;
    const {
      searchNotificationMessage,
      searchNotificationType,
      searchIsLoading,
      searchShowNotification,
    } = fetchDetails
    return (
      <Row>
        <ModalExcel url="/payment/excel" isOpen={openModal} controlModal={this.controlModal} />
        <Col md={8} style={{ paddingLeft: "2%" }}>
          <Form inline>
            <FormGroup style={{ width: "50%" }}>
              <ControlLabel style={{ marginRight: "5%" }}> Desde </ControlLabel>
              <FormControl type="date" name="start" onChange={e => this.onChange(e)} />
            </FormGroup>
            <FormGroup style={{ width: "50%" }} >
              <ControlLabel style={{ marginRight: "5%" }}> Hasta </ControlLabel>
              <FormControl type="date" name="end" onChange={e => this.onChange(e)} />
            </FormGroup>
            <FormGroup style={{ width: "95%" }}>
              <Col md={4} style={{ paddingLeft: "0px" }}>
                <FormControl type="text" name="question" placeholder="Buscar" style={{ width: "100%" }} onChange={e => this.onChange(e)} />
              </Col>
              <Col md={3}>
                <Select options={this.options} name="status" className="basic-single" isSearchable={false}
                  classNamePrefix="select" onChange={(e) => this.setState({ 'status': e.value })} />
              </Col>
              <Col md={3}>
                <Button bsStyle="success" fill block onClick={() => this.doSearch()} >Filtrar</Button>
              </Col>
            </FormGroup>
          </Form>
        </Col>
        <Col md={4}>
          <Button
            bsStyle="primary"
            pullRight
            style={styles.topButton}
            disabled={urlExcel == '' ? true : false}
            onClick={() => this.controlModal(true)}
          >
            Exportar Excel
          </Button>
          <Button
            disabled={deleteDisabled}
            bsStyle="primary"
            pullRight
            style={styles.topButton}
            onClick={() => deleteMasive()}>
            Eliminar Seleccionados</Button>
        </Col>
        <Col md={12}>
          {searchShowNotification &&
            <ModalNotification
              notificationMessage={searchNotificationMessage}
              closeNotification={() => this.setState({ showNotification: false })}
              type={searchNotificationType}
            />
          }
        </Col>
      </Row >
    );
  }
}

export default PayHeader;
