import React from 'react'
import ErrorNotification from 'components/Notifications/ErrorNotification.jsx'
import { thRoleArray } from "variables/Variables.jsx";
import { Table, Col, Pager } from "react-bootstrap";
import SearchInput from 'components/Search/SearchInputFixed';
import Button from 'components/CustomButton/CustomButton';
import ModalEditRole from 'views/roles/modalEditRole';
import { mensajeError500 } from "variables/Variables.jsx";
import ConfirmDeleteModal from 'components/Modal/ConfirmDeleteModal.jsx';
import axios from 'components/axios';
import Loader from 'react-loader-spinner'

class RoleList extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showEditRoleModal: false,
      activeRole: {},
      roles: [],
      totalOfPages: 0,
      currentPage: 1,
      totalOfRecords: 0,
      searchActive:false,
      searchShowNotification:false,
      searchTarget:'',
      searchQuerySearch:undefined,
      searchShowNotification: false,
      searchNotificationType: '',
      searchNotificationMessage: '',
      searchIsLoading:false,
    };
  }

  componentDidMount() {
    this.fetchRoles();
  }

  fetchRoles = async () => {
    const { currentPage } = this.state;
    this.setState({ isLoading: true });
    try {
      const response = await axios.post('/rol/list', {
        current_page: currentPage,
      });
      this.setState({
        roles: response.data.Roles.data,
        totalOfPages: response.data.Roles.last_page,
        totalOfRecords: response.data.Roles.total,
      });
    } catch (err) {
      this.setState({
        showNotification: true,
        notificationMessage: mensajeError500
      });
      console.log('ERROR', err);
    } finally {
      this.setState({ isLoading: false });
    }
  }

  check(item) {
    if (item === -1) {
      return <td> </td>
    } else {
      return <td style={ { textAlign: "center" } }> <i className="fa fa-check"></i> </td>
    }
  }

  handleShowEditRoleModal = (roleSelected) => {
    this.setState({
      showEditRoleModal: true,
      activeRole: roleSelected
    });
  };

  handleCloseEditRoleModal = () => {
    this.setState({ showEditRoleModal: false });
  };

  handleOpenConfirmDelete = (role) => {
    this.setState({
      showConfirmDelete: true,
      actionName: role.name,
      selectedRole: role,
    });
  };

  handleDelete = async () => {
    const { selectedRole } = this.state;
    this.setState({ isDeleting: true });
    try {
      await axios.post(`rol/delete/${selectedRole.id}`);
      this.setState({ showConfirmDelete: false });
      this.fetchRoles()
    } catch (err) {
      this.setState({
        showNotification: true,
        notificationMessage: mensajeError500
      });
      console.log('ERROR', err);
    } finally {
      this.setState({ isDeleting: false });
    }
  }

  onClickPagination = () => {
    const {searchTarget,searchQuerySearch,currentPage} = this.state
    this.fetchSearch(searchTarget,searchQuerySearch,currentPage);
  }

  onClickButtonSearch = (target,querySearch) => {
    this.setState({searchTarget:target,currentPage: 1,searchQuerySearch:querySearch});
    this.fetchSearch(target,querySearch,1);
  }

  fetchSearch = async (target,querySearch,currentPage) => {
    this.setState({ searchActive: true})
    this.setState({ searchIsLoading: true });

    try {
      var response = {}
      if (querySearch === true) {
        response = await axios.post('/rol/search', {
          page: currentPage,
          query: target
        });
      } else {
        response = await axios.post('/rol/search', {
          current_page: currentPage,
          name: target
        });
      }

      if (response.status === 200) {
        this.setState({
          searchShowNotification:true,
          searchNotificationType:"info",
          searchNotificationMessage:"Búsqueda finalizada. Se encontraron " + response.data.roles.total + " coincidecias.",
        });
      }
      this.setState({
        roles: response.data.roles.data,
        totalOfPages: response.data.roles.last_page,
        totalOfRecords: response.data.roles.total,
      });
    } catch (err) {
      if (typeof err.response !== 'undefined' && typeof err.response.data !== 'undefined') {
        if (err.response.status === 400) {
          this.setState({
            searchShowNotification: true,
            searchNotificationType: "danger",
            searchNotificationMessage: err.response.data.error
          });
        } else {
          this.setState({
            searchShowNotification: true,
            searchNotificationType: "danger",
            searchNotificationMessage: mensajeError500
          });
        }
      } else {
        console.log("ERROR", err)
      }
    } finally {
      this.setState({ searchIsLoading: false });
    }
  }

  render() {
    const {
      activeRole,
      showEditRoleModal,
      roles,
      showConfirmDelete,
      actionName,
      isLoading,
      showNotification,
      notificationMessage,
      currentPage,
      totalOfPages,
      totalOfRecords
    } = this.state;

    return !isLoading ? (
      <>

        <ConfirmDeleteModal
          onClose={ () => this.setState({ showConfirmDelete: false }) }
          show={ showConfirmDelete }
          thingToRemoveType="rol"
          thingToRemoveName={ actionName }
          onConfirm={ this.handleDelete }
        />
        {
          showEditRoleModal &&
          <ModalEditRole
            onClose={ this.handleCloseEditRoleModal }
            open={ showEditRoleModal }
            activeRole={ activeRole }
            fetchRoles={ this.fetchRoles }
          />
        }

        <Col md={ 6 }>
          <SearchInput
            search={ this.onClickButtonSearch }
            fetchDetails={ this.state }
            closeNotification={ () => this.setState({...this.state,searchShowNotification:false}) }
          />
        </Col>
        <Col md={ 12 } style={ { padding: "2%", overflowY: "scroll" } }>
          { showNotification &&
            <ErrorNotification
              notificationMessage={ notificationMessage }
              closeNotification={ () => this.setState({ showNotification: false }) }
            />
          }
          <Table striped hover>
            <thead>
              <tr>
                { thRoleArray.map((prop, key) => {
                  return <th key={ key }>{ prop }</th>;
                }) }
              </tr>
            </thead>
            <tbody>
              { roles.map((role) => {
                return (
                  <tr key={ role.id }>
                    <td>{ role.name }</td>
                    <td>{ role.user_count }</td>
                    <td>{ this.check(role.privileges.search('a')) }</td>
                    <td>{ this.check(role.privileges.search('b')) }</td>
                    <td>{ this.check(role.privileges.search('c')) }</td>
                    <td>{ this.check(role.privileges.search('d')) }</td>
                    <td>{ this.check(role.privileges.search('e')) }</td>
                    <td>{ this.check(role.privileges.search('f')) }</td>
                    <td>{ this.check(role.privileges.search('g')) }</td>
                    <td>{ this.check(role.privileges.search('h')) }</td>
                    <td>{ this.check(role.privileges.search('i')) }</td>
                    <td>{ this.check(role.privileges.search('j')) }</td>
                    <td>{ this.check(role.privileges.search('k')) }</td>
                    <td>{ this.check(role.privileges.search('l')) }</td>
                    <td>
                      {
                        role.id > 3 &&
                        <>
                          <Button bsStyle="success" bsSize="sm" fill title="Editar" onClick={ () => this.handleShowEditRoleModal(role) }><i className="fa fa-pencil"></i></Button>
                          <Button bsStyle="danger" bsSize="sm" fill title="Eliminar" onClick={ () => this.handleOpenConfirmDelete(role) }><i className="fa fa-trash"></i></Button>
                        </>
                      }
                    </td>
                  </tr>
                );
              }) }
            </tbody>
          </Table>

          { roles.length > 0 && (
            <>
              <Pager>
                <Pager.Item
                  previous
                  disabled={ currentPage === 1 }
                  onClick={ () => {
                    this.setState((currentState) => ({ currentPage: currentState.currentPage - 1 }),  this.state.searchActive ? this.onClickPagination : () => this.fetchRoles());
                  }}
                >
                  <i className="fa fa-chevron-left"></i>  Anterior
                      </Pager.Item>
                <Pager.Item>

                  { currentPage }/{ totalOfPages }

                </Pager.Item>
                <Pager.Item
                  disabled={ currentPage === totalOfPages }
                  next
                  onClick={ () => {
                    this.setState((currentState) => ({ currentPage: currentState.currentPage + 1 }), this.state.searchActive ? this.onClickPagination : () => this.fetchRoles());
                  }}
                >
                  Siguiente <i className="fa fa-chevron-right"></i>
                </Pager.Item>
              </Pager>
              <Pager>
                <Pager.Item>
                  Total: { totalOfRecords } registros
                        </Pager.Item>
              </Pager>
            </>
          ) }
        </Col>
      </>

    ) : (
        <Loader
          type="Rings"
          color="#00BFFF"
          height={ 100 }
          width={ 100 }
          style={ { display: 'flex', justifyContent: 'center' } }
        />
      );
  }
}
export default RoleList