

import React from 'react'
import {
  Row,
  Col,
} from "react-bootstrap";
import { FormGroup, ControlLabel, FormControl } from "react-bootstrap";
import { mensajeError500 } from "variables/Variables.jsx";
import ErrorNotification from 'components/Notifications/ErrorNotification.jsx'
import Button from 'components/CustomButton/CustomButton';
import styles from './styles'
import axios from 'components/axios';
import Loader from 'react-loader-spinner'
import Select from 'react-select'

class StudentForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      name: '',
      lastname: '',
      code: '',
      gender: '',
      tutorId: '',
      levels: [],
      level1: '',
      grades: [],
      level2: '',
      groups: [],
      level3: '',
      isSubmitting: false,
      showNotification: false,
      notificationMessage: '',
      parents: [],
      isLoading: false,
      loadSelect: 0
    };
    this.genderOptions = [
      { value: false, label: "Masculino" },
      { value: true, label: "Femenino" }
    ]
  }

  async componentDidMount() {
    const { parentData, fromParents } = this.props;
    if (fromParents === "true") {
      this.setState({
        tutorId: parentData.id
      })
    }
    this.fetchParentsId();
    this.fetchLevels();
  }

  fetchParentsId = async () => {
    this.setState({ isLoading: true });
    try {
      const response = await axios.post('/tutor/list_select');
      const newData = response.data.tutors.map((item) => {
        return { value: item.id, label: `${item.name} ${item.last_name}` }
      })
      this.setState({ parents: newData })
    } catch (err) {
      console.log('ERROR', err);
    } finally {
      this.setState({ isLoading: false });
    }
  }

  fetchLevels = async () => {
    this.setState({ loadSelect: 1 });
    try {
      const response = await axios.post('/level/full_list');
      const newData = response.data.Levels.map((item) => {
        return { value: item.id, label: item.name }
      })
      this.setState({ levels: newData })
    } catch (err) {
      console.log('ERROR', err);
    } finally {
      this.setState({ loadSelect: 0 });
    }
  }

  fetchGrades = async (level1) => {
    this.setState({ loadSelect: 2 });
    try {
      const response = await axios.post('/grade/by/level', { level_id: level1 });
      const newData = response.data.Grades.map((item) => {
        return { value: item.id, label: item.name }
      })
      this.setState({ grades: newData })
    } catch (err) {
      console.log('ERROR', err);
    } finally {
      this.setState({ loadSelect: 0 });
    }
  }

  fetchGroups = async () => {
    this.setState({ loadSelect: 3 });
    try {
      const response = await axios.post('/group/full_list');
      console.log(response)
      const newData = response.data.Groups.map((item) => {
        return { value: item.id, label: item.name }
      })
      this.setState({ groups: newData })
    } catch (err) {
      console.log('ERROR', err);
    } finally {
      this.setState({ loadSelect: 0 });
    }
  }

  onChange(e) {
    this.setState({ [e.target.name]: e.target.value })
  }

  handleSubmit = async (e) => {
    e.preventDefault();
    const {
      name,
      lastname,
      code,
      gender,
      tutorId,
      level1,
      level2,
      level3,
    } = this.state;
    if (gender === '') {
      this.setState({
        showNotification: true,
        notificationMessage: "Debe seleccionar una opción para el campo SEXO"
      });
      return;
    }
    if (tutorId === '') {
      this.setState({
        showNotification: true,
        notificationMessage: "Debe seleccionar una opción para el campo PADRE"
      });
      return;
    }
    if (level1 === '') {
      this.setState({
        showNotification: true,
        notificationMessage: "Debe seleccionar una opción para el campo NIVEL"
      });
      return;
    }
    if (level2 === '') {
      this.setState({
        showNotification: true,
        notificationMessage: "Debe seleccionar una opción para el campo GRADO"
      });
      return;
    }
    if (level3 === '') {
      this.setState({
        showNotification: true,
        notificationMessage: "Debe seleccionar una opción para el campo GRUPO"
      });
      return;
    }
    this.setState({ isSubmitting: true });
    try {
      await axios.post('student/add', {
        name,
        last_name: lastname,
        code,
        gender,
        tutor_id: tutorId,
        level1,
        level2,
        level3,
      });
      this.props.returnToList(this.props.currentPage);
    } catch (err) {
      if (typeof err.response !== 'undefined' && typeof err.response.data !== 'undefined') {
        if (err.response.status === 400) {
          this.setState({
            showNotification: true,
            notificationType: "danger",
            notificationMessage: err.response.data.error
          });
        } else {
          this.setState({
            showNotification: true,
            notificationType: "danger",
            notificationMessage: mensajeError500
          });
        }
      }
      console.log('ERROR', err);
    } finally {
      this.setState({ isSubmitting: false });
    }
  }

  render() {
    const {
      returnToList,
      fromParents,
      parentData
    } = this.props;

    const {
      isSubmitting,
      showNotification,
      notificationMessage,
      parents,
      isLoading,
      loadSelect,
      levels,
      grades,
      groups
    } = this.state;
    return !isSubmitting ? (
      <>
        <form onSubmit={ this.handleSubmit }>
          <Row>
            <Col md={ 12 } style={ { padding: "2%" } }>
              { fromParents === "true" ?
                <h4>Asignar un hijo a <b>{ parentData.name } { parentData.last_name }</b></h4>
                :
                <h4>Registrar un estudiante</h4> }
              { showNotification &&
                <ErrorNotification
                  notificationMessage={ notificationMessage }
                  closeNotification={ () => this.setState({ showNotification: false }) }
                />
              }
              <Row style={ { marginTop: "2%" } }>
                <Col md={ 4 }>
                  <FormGroup>
                    <ControlLabel> NOMBRES </ControlLabel>
                    <FormControl type="text" name="name" onChange={ e => this.onChange(e) } required />
                  </FormGroup>
                  <FormGroup>
                    <ControlLabel> APELLIDOS </ControlLabel>
                    <FormControl type="text" name="lastname" onChange={ e => this.onChange(e) } required />
                  </FormGroup>
                  <FormGroup>
                    <ControlLabel> MATRICULA </ControlLabel>
                    <FormControl type="text" name="code" onChange={ e => this.onChange(e) } required />
                  </FormGroup>
                  <FormGroup>
                    <ControlLabel> SEXO </ControlLabel>
                    <Select options={ this.genderOptions } name="gender" className="basic-single" isSearchable={ false }
                      classNamePrefix="select" onChange={ (e) => this.setState({ 'gender': e.value }) }
                      placeholder="Seleccione una opción" />
                  </FormGroup>
                </Col>
                <Col md={ 2 }></Col>
                <Col md={ 4 }>
                  { fromParents !== "true" &&
                    <FormGroup>
                      <ControlLabel> PADRE </ControlLabel>
                      { !isLoading ?
                        <Select options={ parents }
                          name="tutorId" className="basic-single"
                          classNamePrefix="select"
                          onChange={ (e) => this.setState({ 'tutorId': e.value }) }
                          placeholder="Seleccione una opción" />
                        :
                        <Loader
                          type="Rings"
                          color="#00BFFF"
                          height={ 30 }
                          width={ 30 }
                          style={ { display: 'flex', justifyContent: 'center' } }
                        />
                      }
                    </FormGroup>
                  }
                  <FormGroup>
                    <ControlLabel> NIVEL </ControlLabel>
                    { loadSelect === 1 ?
                      <Loader
                        type="Rings"
                        color="#00BFFF"
                        height={ 30 }
                        width={ 30 }
                        style={ { display: 'flex', justifyContent: 'center' } }
                      />
                      :
                      <Select options={ levels }
                        name="level1" className="basic-single"
                        classNamePrefix="select"
                        onChange={ (e) => { this.setState({ 'level1': e.value }); this.fetchGrades(e.value) } }
                        placeholder="Seleccione una opción"
                      />
                    }
                  </FormGroup>
                  <FormGroup>
                    <ControlLabel> GRADO </ControlLabel>
                    { loadSelect === 2 ?
                      <Loader
                        type="Rings"
                        color="#00BFFF"
                        height={ 30 }
                        width={ 30 }
                        style={ { display: 'flex', justifyContent: 'center' } }
                      />
                      :
                      <Select options={ grades }
                        name="level2" className="basic-single"
                        classNamePrefix="select"
                        onChange={ (e) => { this.setState({ 'level2': e.value }); this.fetchGroups() } }
                        placeholder="Seleccione una opción"
                        isDisabled={ grades.length === 0 }
                      />
                    }
                  </FormGroup>
                  <FormGroup>
                    <ControlLabel> GRUPO </ControlLabel>
                    { loadSelect === 3 ?
                      <Loader
                        type="Rings"
                        color="#00BFFF"
                        height={ 30 }
                        width={ 30 }
                        style={ { display: 'flex', justifyContent: 'center' } }
                      />
                      :
                      <Select options={ groups }
                        name="level3" className="basic-single"
                        classNamePrefix="select"
                        onChange={ (e) => this.setState({ 'level3': e.value }) }
                        placeholder="Seleccione una opción"
                        isDisabled={ groups.length === 0 }
                      />
                    }
                  </FormGroup>
                </Col>
                <Col md={ 2 }></Col>
              </Row>
              <Row>
                <Col md={ 12 } style={ { marginLeft: "0px" } }>
                  <Button bsStyle="success" pullRight fill type="submit" style={ styles.saveButton }>
                    <i className="fa fa-save"></i> Guardar
                                </Button>
                  <Button bsStyle="danger" pullRight fill type="button" onClick={ () => returnToList(this.props.currentPage) } style={ styles.saveButton }>
                    <i className="fa fa-chevron-left"></i> Regresar
                                </Button>
                </Col>
              </Row>
            </Col>
          </Row>
        </form>
      </>
    ) : (
        <Loader
          type="Rings"
          color="#00BFFF"
          height={ 100 }
          width={ 100 }
          style={ { display: 'flex', justifyContent: 'center' } }
        />
      );
  }
}
export default StudentForm