import React, { Component } from "react";
import {
  Grid,
  Row,
  Col,
  Table,
  Pager
} from "react-bootstrap";
import { Card } from "components/Card/Card.jsx";
import Loader from 'react-loader-spinner';
import Button from 'components/CustomButton/CustomButton';
import SpinnerModal from 'components/Modal/SpinnerModal.jsx';
import LoadFileModal from 'components/Modal/LoadFileModal.jsx';
import ConfirmDeleteModal from 'components/Modal/ConfirmDeleteModal.jsx';
import { mensajeError500 } from "variables/Variables.jsx";
import { documentosBase } from "variables/DomainRoutes.jsx";
import HelpModal from 'components/Modal/HelpModal.jsx';
import axios from 'components/axios';
import calificaciones from "assets/img/califica.png";
class LoadDBScore extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showModalHelp: false,
      showModalUpload: false,
      showModalValidate: false,
      showModalSaveBD: false,
      showConfirmDelete: false,
      actionName: "",
      isLoading: false,
      selectedDocument: {},
      currentPage: 1,
      totalOfPages: 0,
      documents: [],
    };
    const userData = JSON.parse(localStorage.getItem('USER'));
    this.institutionId = userData.institution_id;
  }

  async componentDidMount() {
    this.fetchDocuments();
  }

  fetchDocuments = async () => {
    const { currentPage } = this.state;
    this.setState({ isLoading: true });
    try {
      const response = await axios.post('/score/list/document', {
        current_page: currentPage,
      });
      this.setState({ documents: response.data.uploads.data, totalOfPages: response.data.uploads.last_page });
      console.log(response.data);
    } catch (err) {
      console.log('ERROR', err);
    } finally {
      this.setState({ isLoading: false });
    }
  }
  goToNextPage = () => {
    this.setState(
      (currentState) => ({ currentPage: currentState.currentPage + 1 }),
      () => { this.fetchDocuments() }
    );
  }

  goToPreviousPage = () => {
    this.setState((currentState) => ({ currentPage: currentState.currentPage - 1 }),
      () => { this.fetchDocuments() }
    );
  }

  handleOpenConfirmDelete = (document) => {
    this.setState({
      showConfirmDelete: true,
      actionName: document.original_name === "0" ? document.name : document.original_name,
      selectedDocument: document,
    });
  };

  handleValidationModal = (document) => {
    this.setState({
      showModalValidate: true,
      selectedDocument: document,
    });
  }

  handleSaveDBModal = (document) => {
    this.setState({
      showModalSaveBD: true,
      selectedDocument: document,
    });
  }

  handleDelete = async () => {
    const { selectedDocument } = this.state;
    this.setState({ isDeleting: true });
    try {
      await axios.post('delete/document/', {
        id: selectedDocument.id,
      });
      this.setState({ showConfirmDelete: false });
      this.fetchDocuments();
    } catch (err) {
      if (typeof err.response !== 'undefined' && typeof err.response.data !== 'undefined') {
        if (err.response.status === 400) {
          this.setState({
            showNotification: true,
            notificationType: "danger",
            notificationMessage: err.response.data.error
          });
        } else {
          this.setState({
            showNotification: true,
            notificationType: "danger",
            notificationMessage: mensajeError500
          });
        }
        console.log('ERROR', err);
      }
      console.log('ERROR', err);
      this.setState({ isDeleting: false, showConfirmDelete: false });
    } finally {
      this.setState({ isDeleting: false });
    }
  }

  render() {
    const {
      showModalUpload,
      showModalValidate,
      showModalSaveBD,
      showConfirmDelete,
      actionName,
      documents,
      isLoading,
      selectedDocument,
      totalOfPages,
      currentPage
    } = this.state;
    return (
      <div style={{ marginLeft: '30px', marginRight: '30px' }}>
        <Card
          cardStyle={{
            boxShadow: 'none',
          }}
          content={!isLoading ? (
            <Grid fluid>
              <LoadFileModal
                onClose={() => this.setState({ showModalUpload: false })}
                show={showModalUpload}
                onFinish={() => {
                  this.setState({ showModalUpload: false });
                  this.fetchDocuments();
                }}
                actionTitle="Carga de archivo"
                awaitMessage="cargando el archivo"
                institutionId={this.institutionId}
                type={5}
              />
              {showModalValidate && <SpinnerModal
                onClose={() => this.setState({ showModalValidate: false })}
                show={showModalValidate}
                onFinish={() => {
                  this.setState({ showModalValidate: false });
                  this.fetchDocuments()
                }}
                actionTitle="Validación"
                awaitMessage={"validando el archivo "}
                nameDocument={selectedDocument.original_name === "0" ? selectedDocument.name : selectedDocument.original_name}
                route={"/score/validate/document"}
                target={selectedDocument.id}
              />}
              {showModalSaveBD && <SpinnerModal
                onClose={() => this.setState({ showModalSaveBD: false })}
                show={showModalSaveBD}
                onFinish={() => {
                  this.setState({ showModalSaveBD: false });
                  this.fetchDocuments()
                }
                }
                actionTitle="Guardado en la Base de Datos"
                awaitMessage={"guardando el archivo "}
                nameDocument={selectedDocument.original_name === "0" ? selectedDocument.name : selectedDocument.original_name}
                route={"/score/import/document"}
                target={selectedDocument.id}
              />}
              <ConfirmDeleteModal
                onClose={() => this.setState({ showConfirmDelete: false })}
                show={showConfirmDelete}
                thingToRemoveType="archivo"
                thingToRemoveName={actionName}
                onConfirm={this.handleDelete}
              />
              <Row style={{ paddingTop: "2%" }}>
                <HelpModal
                  colsReqArray={["Matricula", "Promedio general", "Final", "Ciclo", "Periodo", "Materia1", "Materia2", "...", "MateriaN"]}
                  colsOpArray={null}
                  onClose={() => this.setState({ showModalHelp: false })}
                  show={this.state.showModalHelp}
                  doc={{ "path": documentosBase[0].path, "title": documentosBase[0].title }}
                  image={calificaciones}
                />
                <Col md={9}>
                  <Col md={3}>
                    <Button type="button" bsStyle="info" fill onClick={() => this.setState({ showModalUpload: true })}>
                      <i className="fa fa-upload"></i> Subir Archivo
                    </Button>
                  </Col>
                </Col>
                <Col md={3}>
                  <Button type="button" onClick={() => this.setState({ showModalHelp: true })}>
                    ¿Necesitas ayuda?
                  </Button>
                </Col>
              </Row>
              <Row style={{ padding: "2%" }}>
                <Table striped hover >
                  <thead>
                    <th> Archivos </th>
                    <th> Fecha de subida </th>
                    <th> Estado </th>
                    <th> Acciones </th>
                  </thead>
                  <tbody>
                    {documents.map((document) => {
                      return (
                        <tr key={document.id}>
                          <td>{document.original_name === "0" ? document.name : document.original_name}</td>
                          <td>{document.created_at}</td>
                          <td>
                            {
                              {
                                0: <span className="label label-default">Subido</span>,
                                1: <span className="label label-warning">Validado</span>,
                                2: <span className="label label-success">Guardado</span>
                              }[document.status]
                            }
                          </td>
                          <td>
                            <Button
                              bsStyle="warning"
                              bsSize="sm"
                              fill
                              title="Validar"
                              onClick={() => this.handleValidationModal(document)}
                              disabled={document.status === 0 ? false : true} >
                              <i className="fa fa-eye"></i>
                            </Button>
                            <Button
                              bsStyle="success"
                              bsSize="sm"
                              fill
                              title="Guardar"
                              onClick={() => this.handleSaveDBModal(document)}
                              disabled={document.status === 1 ? false : true} >
                              <i className="fa fa-save"></i>
                            </Button>
                            <Button
                              bsStyle="danger"
                              bsSize="sm"
                              fill
                              title="Eliminar"
                              disabled={document.status === 2 ? true : false}
                              onClick={() => this.handleOpenConfirmDelete(document)} >
                              <i className="fa fa-trash"></i>
                            </Button>
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </Table>
                {documents.length > 0 && (
                  <Pager>
                    <Pager.Item
                      previous
                      disabled={currentPage === 1}
                      onClick={this.goToPreviousPage}
                    >
                      <i className="fa fa-chevron-left"></i>  Anterior
                    </Pager.Item>
                    <Pager.Item>

                      {currentPage}/{totalOfPages}

                    </Pager.Item>
                    <Pager.Item
                      disabled={currentPage === totalOfPages}
                      next
                      onClick={this.goToNextPage}
                    >
                      Siguiente <i className="fa fa-chevron-right"></i>
                    </Pager.Item>
                  </Pager>
                )}
                <Col md={10}>
                  <h4> Pasos para subir un archivo</h4>
                  <Col md={4}>
                    <Button type="button" bsStyle="info" fill >
                      <i className="fa fa-upload"></i> Subir Archivo
                    </Button>
                    <i
                      className="fa fa-chevron-right"
                      style={{ paddingLeft: "27%" }}
                    ></i>
                  </Col>
                  <Col md={4}>
                    <Button bsStyle="warning" bsSize="sm" fill>
                      <i className="fa fa-eye"></i>
                    </Button> Validar archivo
                    <i
                      className="fa fa-chevron-right"
                      style={{ paddingLeft: "27%" }}
                    ></i>
                  </Col>
                  <Col md={4}>
                    <Button bsStyle="success" bsSize="sm" fill>
                      <i className="fa fa-save"></i>
                    </Button> Guardar en la Base de Datos
                  </Col>
                </Col>
              </Row>
            </Grid>
          ) : (
            <Loader
              type="Rings"
              color="#00BFFF"
              height={100}
              width={100}
              style={{ display: 'flex', justifyContent: 'center' }}
            />
          )
          } />
      </div>
    );
  }
}

export default LoadDBScore;
