/*!

=========================================================
* Light Bootstrap Dashboard React - v1.3.0
=========================================================

* Product Page: https://www.creative-tim.com/product/light-bootstrap-dashboard-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/light-bootstrap-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
//
// //
// // // For notifications
// //
//
var defaultWidth =
  window.screen.width > 768
    ? (window.screen.width * 1) / 3
    : window.screen.width;

var style = {
  Wrapper: {},
  Containers: {
    DefaultStyle: {
      position: "fixed",
      width: defaultWidth,
      padding: "10px 10px 10px 20px",
      zIndex: 9998,
      WebkitBoxSizing: "",
      MozBoxSizing: "",
      boxSizing: "",
      height: "auto",
      display: "inline-block",
      border: "0",
      fontSize: "14px",
      WebkitFontSmoothing: "antialiased",
      fontFamily: '"Roboto","Helvetica Neue",Arial,sans-serif',
      fontWeight: "400",
      color: "#FFFFFF"
    },

    tl: {
      top: "0px",
      bottom: "auto",
      left: "0px",
      right: "auto"
    },

    tr: {
      top: "0px",
      bottom: "auto",
      left: "auto",
      right: "0px"
    },

    tc: {
      top: "0px",
      bottom: "auto",
      margin: "0 auto",
      left: "50%",
      marginLeft: -(defaultWidth / 2)
    },

    bl: {
      top: "auto",
      bottom: "0px",
      left: "0px",
      right: "auto"
    },

    br: {
      top: "auto",
      bottom: "0px",
      left: "auto",
      right: "0px"
    },

    bc: {
      top: "auto",
      bottom: "0px",
      margin: "0 auto",
      left: "50%",
      marginLeft: -(defaultWidth / 2)
    }
  },

  NotificationItem: {
    DefaultStyle: {
      position: "relative",
      width: "100%",
      cursor: "pointer",
      borderRadius: "4px",
      fontSize: "14px",
      margin: "10px 0 0",
      padding: "10px",
      display: "block",
      WebkitBoxSizing: "border-box",
      MozBoxSizing: "border-box",
      boxSizing: "border-box",
      opacity: 0,
      transition: "all 0.5s ease-in-out",
      WebkitTransform: "translate3d(0, 0, 0)",
      transform: "translate3d(0, 0, 0)",
      willChange: "transform, opacity",

      isHidden: {
        opacity: 0
      },

      isVisible: {
        opacity: 1
      }
    },

    success: {
      borderTop: 0,
      backgroundColor: "#a1e82c",
      WebkitBoxShadow: 0,
      MozBoxShadow: 0,
      boxShadow: 0
    },

    error: {
      borderTop: 0,
      backgroundColor: "#fc727a",
      WebkitBoxShadow: 0,
      MozBoxShadow: 0,
      boxShadow: 0
    },

    warning: {
      borderTop: 0,
      backgroundColor: "#ffbc67",
      WebkitBoxShadow: 0,
      MozBoxShadow: 0,
      boxShadow: 0
    },

    info: {
      borderTop: 0,
      backgroundColor: "#63d8f1",
      WebkitBoxShadow: 0,
      MozBoxShadow: 0,
      boxShadow: 0
    }
  },

  Title: {
    DefaultStyle: {
      fontSize: "30px",
      margin: "0",
      padding: 0,
      fontWeight: "bold",
      color: "#FFFFFF",
      display: "block",
      left: "15px",
      position: "absolute",
      top: "50%",
      marginTop: "-15px"
    }
  },

  MessageWrapper: {
    DefaultStyle: {
      marginLeft: "55px",
      marginRight: "30px",
      padding: "0 12px 0 0",
      color: "#FFFFFF",
      maxWidthwidth: "89%"
    }
  },

  Dismiss: {
    DefaultStyle: {
      fontFamily: "inherit",
      fontSize: "21px",
      color: "#000",
      float: "right",
      position: "absolute",
      right: "10px",
      top: "50%",
      marginTop: "-13px",
      backgroundColor: "#FFFFFF",
      display: "block",
      borderRadius: "50%",
      opacity: ".4",
      lineHeight: "11px",
      width: "25px",
      height: "25px",
      outline: "0 !important",
      textAlign: "center",
      padding: "6px 3px 3px 3px",
      fontWeight: "300",
      marginLeft: "65px"
    },

    success: {
      // color: '#f0f5ea',
      // backgroundColor: '#a1e82c'
    },

    error: {
      // color: '#f4e9e9',
      // backgroundColor: '#fc727a'
    },

    warning: {
      // color: '#f9f6f0',
      // backgroundColor: '#ffbc67'
    },

    info: {
      // color: '#e8f0f4',
      // backgroundColor: '#63d8f1'
    }
  },

  Action: {
    DefaultStyle: {
      background: "#ffffff",
      borderRadius: "2px",
      padding: "6px 20px",
      fontWeight: "bold",
      margin: "10px 0 0 0",
      border: 0
    },

    success: {
      backgroundColor: "#a1e82c",
      color: "#ffffff"
    },

    error: {
      backgroundColor: "#fc727a",
      color: "#ffffff"
    },

    warning: {
      backgroundColor: "#ffbc67",
      color: "#ffffff"
    },

    info: {
      backgroundColor: "#63d8f1",
      color: "#ffffff"
    }
  },

  ActionWrapper: {
    DefaultStyle: {
      margin: 0,
      padding: 0
    }
  }
};

//
// //
// // // For tables
// //
//
const thArray = ["Nombres", "Apellidos", "Correo eléctrónico", "rol", "Acciones"];
const tdArray = [
  ["Dakota", "Rice", "Niger@yopmail.com", "Profesor"],
  ["Minerva", " Hooper", "Curaçao@yopmail.com", "Profesor"],
  ["Sage", " Rodriguez", "Netherlands@yopmail.com", "Coordinador"],
  ["Philip ", "Chaney", "South@yopmail.com", "Profesor"],
  ["Doris", " Greene", "Malawi@yopmail.com", "Profesor"],
  ["Dakota ", "Rice", "Niger@yopmail.com", "Coordinador"],
  ["Minerva ", "Hooper", "Curaçao@yopmail.com", "Profesor"],
  ["Sage", " Rodriguez", "Netherlands@yopmail.com", "Coordinador"],
];

const thRoleArray = [
  "Nombre", "Usuarios que lo usan", "Estadísticas", "Usuarios y padres", "Mensajes", "Calendario",
  "Extracurriculares", "Seguimientos", "Calificaciones", "Materias", "Asistencias", "Cobranza", "Tareas",
  "Configuraciones", "Acciones"
];
const RoleModulesArray1 = [
  { name: "Estadísticas", letter: 'a' },
  { name: "Usuarios y padres", letter: 'b' },
  { name: "Mensajes", letter: 'c' },
  { name: "Calendario", letter: 'd' },
  { name: "Extracurriculares", letter: 'e' },
  { name: "Seguimientos", letter: 'f' },
];
const RoleModulesArray2 = [
  { name: "Calificaciones", letter: 'g' },
  { name: "Materias", letter: 'h' },
  { name: "Asistencias", letter: 'i' },
  { name: "Cobranza", letter: 'j' },
  { name: "Tareas", letter: 'k' },
  { name: "Configuraciones", letter: 'l' },
];

const tdRoleArray = [
  ["Director", 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, false],
  ["Coordinador", 2, 1, 1, 1, 1, 1, 0, 1, 1, 1, 1, false],
  ["Profesor", 3, 1, 1, 0, 1, 1, 1, 1, 1, 1, 1, false],
  ["Secretaria", 0, 0, 0, 1, 1, 1, 0, 1, 1, 1, 1, true],
  ["Tesorero", 4, 1, 0, 1, 1, 1, 1, 0, 1, 0, 0, true],
];

const thParentArray = [
  "", "Nombres", "Apellidos", "Correo eléctrónico", "Inició Sesión", "Acciones"
];
const tdParentArray = [
  ["Dakota", "Rice", "Niger@hotmail.com", "Si"],
  ["Minerva", " Hooper", "Curaçao@hotmail.com", "No"],
  ["Sage", " Rodriguez", "Netherlands@hotmail.com", "Si"],
];
const thParentMessageDetailArray = [
  "Asunto", "Estudiante", "Mensaje", "Tipo de mensaje", "Leído", "Enviado", "Visto"
]
const tdParentMessageDetailArray = [
  ["Adeudo pendiente excursion", "Usted tiene un adeudo pendiente de $800.00, para realizar el pago online presione el botón amarillo de este mensaje.", "No", "12/05/20 13:22"],
  ["Link de Facturacion", "Si requiere factura, favor de presionar el botón amarillo que se encuentra al final de este mensaje.", "No", "12/05/20 13:22"]
];

const thStudentArray = [
  "Nombres", "Apellidos	", "Matrícula", "Sexo", "Padre", "Nivel	", "Grado", "Grupo", "Acciones"
];

const tdStudentArray = [
  ["Enoch", "Mondragon	", "B0000001", "Dakota Rice", "Primaria", "Sexto", "B"],
  ["Maria", "Mondragon	", "B0000001", "Dakota Rice", "Secundaria", "Cuarto", "A"],
]
const listaReqHelp = [
  "NombresP (Nombres del padre)", "ApellidosP (Apellidos del padre)", "Correo", "NombresH (Nombres del estudiante)", "ApellidosH (Apellidos del estudiante)",
  "Matricula", "Nivel", "Grado", "Grupo", "Sexo (M / F)"
];
const listaOpHelp = [
  "Contacto de emergencia 1", "telefono 1", "Contacto de emergencia 2", "telefono 2", "tipo de Sangre", "Alergias"
];
const roleOptions = [
  { "id": 1, "name": "Director" },
  { "id": 2, "name": "Coordinador" },
  { "id": 3, "name": "Profesor" },
];

const roles = {
  1: "Director",
  2: "Coordinador",
  3: "Profesor",
  4: "Padre"
};

const periodTimes = [
  { "id": 1, "name": "Diario" },
  { "id": 2, "name": "Semanalmente" },
  { "id": 3, "name": "Mensualmente" },
  { "id": 4, "name": "Anualmente" },
];
const deliveryTypes = {
  1: "Mensaje a estudiante",
  2: "Mensaje masivo",
  3: "Mensaje específico",
  4: "Mensaje a nivel, grado, grupo",
  5: "Extracurricular",
};

const homeworkStatus = {
  0: "Pendiente",
  1: "Enviado",
  2: "Revisado",
  3: "Revisado con observación"
}

const qrVal = "eyJpdiI6IjAycldTcTI1Wnp6MVZkcFJqWGRoVEE9PSIsInZhbHVlIjoiVmw0ejVXK1djQjZKV2xLXC8xcW1xdzVTQVQ0cCswS3Z3azFudHlaWGREaENoT2FMMEZEVnJvMDJXbDU3XC9KRmFZUEFVaVpIMlNWSWJlNU51Y0syc0hLSzJ3OG1ReE9sWWdBcHpnZ2xrOWNaQ3p1cDZiV1dpNkdOcTB2cmRWakRNYnlhcDFadU83WTFoM2J0MjArVnFOQUFqUzhiSXI2K0J2ZHZKUkxVV05XbHdaOGM5N0t1MVpHMVB5UnR1Y2MxbDdDakhJdUozeCthVFRkbThJYXArenBkRksrblVjU1FkVkRzUWZOTytia2pFVWNHQmFQS05CM0trUWNDb1M3bE81UkM0OXBFZm13TjkyeENEZDRVbllkVkZ3RzE1c29qdUxpVkE5TDVRTDhQWDA1aTlUVFRZOStzdnVsbHZ6Qk1saFd2c3VaTllJd082TVVnK0pJZVh5eXc9PSIsIm1hYyI6Ijg3YzNkZDNjMTkyODNmMzQ0Y2IwNjVkM2Y0YTFkMGFkNmExYWUxZjFkYmYxOGU4MDIzMGVhZDc0Zjc2Mjc5MGEifQ==";
const tdArrayDB = [
  ["archivo10-03-20.xls", 1],
  ["archivo-07-05-20.xls", 0],
  ["archivo15-07-20.xls", 1]
];

const thMessageArray = [
  "Tipo", "Tipo de envío", "N. Destinatarios", "Asunto", "Urls", "Adjuntos", "Enviado", "Programado", "Acciones"
];

const tdMessageArray = [
  ["0", "Invitación", "Masivo", "360", "Bienvenido", "Mensaje", ["www.google", "www.youtube.com"], ["link adjunto1", "link adjunto2"], "08/08/2020"],
  ["1", "Invitación", "Masivo", "360", "Bienvenido", "Mensaje", ["www.google"], [], "08/08/2020"],
  ["2", "Invitación", "Masivo", "360", "Bienvenido", "Mensaje", [], ["link adjunto1", "link adjunto2", "link3"], "08/08/2020"],
];

const tdTypeMessageArray = [
  ["pe-7s-delete-user", "Eliminar usuario"],
  ["pe-7s-coffee", "Cafeteria"],
  ["pe-7s-gym", "Rutina"],
  ["pe-7s-radio", "Danza"],
];
const thMessageHistoryArray = [
  "Emisor", "Padre", "Estudiante", "Nivel", "Grado", "Grupo", "Leído", "Respuesta", "Enviado", "Visto"
];
const tdMessageHistoryArray = [
  ["Buenos dias", "Raul Orive", "Emilio Mondragon", "Julio Mondragon", "Primaria", "Primero", "A", "Si", "Respuesta1 ", "20/07/2020", "20/07/2020"],
  ["Buenos dias", "Raul Orive", "Emilio Garcia", "Julio Garcia", "Primaria", "Segundo", "B", "No", " ", "20/07/2020", "-"],
  ["Buenos dias", "Raul Orive", "Emilio Mondragon", "Julio Mondragon", "Primaria", "Primero", "A", "Si", "Respuesta1 ", "20/07/2020", "20/07/2020"],
];

const thMessageRecordArray = [
  "Asunto", "Emisor", "Fecha de envío", "Urls", "Adjuntos", "Responsable", "Fecha de eliminación", "Acción"
];

const tdMessageRecordArray = [
  ["Hola Mundo", "Prueba Test", "Emilio Juaz", "23/07/2020", ["www.google", "www.youtube.com"], ["link adjunto1", "link adjunto2"], "Jorge Gracia", "23/08/2020"],
  ["Hola Mundo", "Prueba Test", "Emilio Juaz", "23/07/2020", ["www.google", "www.youtube.com"], ["link adjunto1"], "Jorge Gracia", "23/08/2020"],
  ["Hola Mundo", "Prueba Test", "Emilio Juaz", "23/07/2020", [], ["link adjunto1", "link adjunto2"], "Jorge Gracia", "23/08/2020"]
];
const mensajeError500 = "Ocurrió un error en el servidor. Contacte con soporte.";
const tdExtras = [
  { id: 1, name: "Futbol", count: 12 },
  { id: 2, name: "Ajedrez", count: 4 },
  { id: 3, name: "Voley", count: 10 },
];
const tdStudentsExtras = [
  { id: 1, name: "Pipo", last_name: "Perez", code: "as-234" },
  { id: 2, name: "Maru", last_name: "Paco", code: "as-234" },
  { id: 3, name: "Ines", last_name: "Pimentel", code: "as-234" },
]
const tdCourses = [
  { id: 1, name: "Matemática", assigned: "1", label: "matemáticas" },
  { id: 2, name: "Comunicación", assigned: "2", label: "comunicación" },
  { id: 3, name: "Teatro", assigned: "0", label: "teatro" },
]
const tdTcs = [
  { id: 1, course: "Matemática", assigned: "1", level: "primaria - segundo - A", teacher: "Luz Pinto", created_at: "12-10-2020" },
  { id: 2, course: "Matemática", assigned: "0", level: "primaria - segundo - B", teacher: "Luz Pinto", created_at: "12-10-2020" },
  { id: 2, course: "Matemática", assigned: "0", level: "primaria - segundo - C", teacher: "Luz Pinto", created_at: "12-10-2020" },
]
const homeworkDetail = [
  {
    id: 1,
    course: "matematica",
    student: "Omar Duran",
    images: ["pic_1596649588.jpg"],
    attachment: "doc_1596649578.pdf, otro.pdf",
    observation: "hay que rehacer la segunda sección.",
    message: "No entendí la tarea", status: "Enviado", created_at: "06/07/20 10:13"
  },
  {
    id: 2,
    course: "matematica",
    student: "Omar Duran",
    images: ["pic_1596649588.jpg"],
    attachment: "doc_1596649578.pdf",
    message: "", status: "Pendiente", created_at: "06/07/20 10:13"
  },
]

const homework = {
  message: "Hacer de la pagina 10  a la 15",
  url1: "www.googlle.com",
  url2: "www.googlle.com",
  url3: "",
  url4: "",
  url5: "",
  attachment: "doc_1596649578.pdf, otro.pdf",
}
const tutorMessage = [{
  type_details: { name: "oficina" },
  subject: "asunto del mensaje",
  body: "Hacer de la pagina 10  a la 15",
  url1: "https://www.google.fr/",
  url2: "https://www.youtube.com/watch?v=Mk9Q08AeYxw",
  url3: "",
  url4: "",
  url5: "",
  files: "doc_1596649578.pdf, otro.pdf",
  viewed: "Si",
  answer: "Respuesta1 ",
}
]
const tdMonitoring = [
  { id: 1, user: "Pipo Casas", tutor: "Perez Juarez", student: "Juan Juarez", state: true, viewed: "2020-02-12", created_at: "2020-02-12" },
  { id: 2, user: "Pipo Casas", tutor: "Perez Juarez", student: "Juan Juarez", state: false, viewed: "", created_at: "2020-02-12" },
  { id: 3, user: "Pipo Casas", tutor: "Perez Juarez", student: "Juan Juarez", state: true, viewed: "2020-02-12", created_at: "2020-02-12" },
];

const tdMonitoringDetails = [
  { id: 1, name_attribute: "Deficiente", name: "Aseo" },
  { id: 2, name_attribute: "Supera las expectativas", name: "Solidaridad" },
  { id: 3, name_attribute: "Sobresaliente", name: "Puntualidad" },
];
const tdScores = [
  { id: 1, student: { name: "hola", last_name: "mundo" }, level: { name: "primaria" }, grade: { name: "tercero" }, group: { name: "A" }, cycle: "2020-10" },
  { id: 2, student: { name: "fito", last_name: "paez" }, level: { name: "primaria" }, grade: { name: "tercero" }, group: { name: "B" }, cycle: "2020-10" },
];

const tdPays = [
  {
    id: 1,
    student: { name: "hola", last_name: "mundo", code: "12345" },
    tutor: { name: "hugo", last_name: "perex" },
    concept: "Soporte", amount: "12.22", status: true, type: "manual", mode: "efectivo", send_date: "2020-10"
  },
  {
    id: 2,
    student: { name: "fito", last_name: "paez", code: "12345" },
    tutor: { name: "hugo", last_name: "Salas" },
    concept: "Colegiatura", amount: "10.00", status: false, type: "csv", mode: "soporte", send_date: "2020-10"
  },
];
//
// //
// // // For icons
// //
//

const stickers = [
  { path: "general-file-1601063543535.svg", title: "calendario" },
  { path: "general-file-1601063648621.svg", title: "calificaciones" },
  { path: "general-file-1601064010024.svg", title: "foto" },
  { path: "general-file-1601064381311.svg", title: "navidad" },
  { path: "general-file-1601064449472.svg", title: "correo" },
  { path: "general-file-1601064472701.svg", title: "arte" },
  { path: "general-file-1601064990057.svg", title: "trofeo" },
  { path: "general-file-1601065045068.svg", title: "reloj" },
  { path: "general-file-1601065085599.svg", title: "mochila" },
  { path: "general-file-1601065789792.svg", title: "megafono" },
  { path: "general-file-1601065813451.svg", title: "manzana" },
  { path: "general-file-1601065843340.svg", title: "link" },
  { path: "general-file-1601065877764.svg", title: "libreta" },
  { path: "general-file-1601065899353.svg", title: "enfermería" },
  { path: "general-file-1601065924589.svg", title: "dinero" },
  { path: "general-file-1601065947155.svg", title: "clases en línea" },
  { path: "general-file-1601065986932.svg", title: "carpeta" },
  { path: "general-file-1601066007597.svg", title: "camión" },
  { path: "general-file-1601066032877.svg", title: "balón" },
  { path: "general-file-1601066058833.svg", title: "adjunto" },
];

const monitorStickers = [
  { path: "general-file-1611277901547.svg", title: "sticker-1" },
  { path: "general-file-1611278016288.svg", title: "sticker-2" },
  { path: "general-file-1611278035552.svg", title: "sticker-3" },
  { path: "general-file-1611278057936.svg", title: "sticker-4" },
  { path: "general-file-1611278119414.svg", title: "sticker-5" },
  { path: "general-file-1611278142112.svg", title: "sticker-6" },
  { path: "general-file-1611278188599.svg", title: "sticker-7" },
  { path: "general-file-1611278297480.svg", title: "sticker-8" },
  { path: "general-file-1611278318678.svg", title: "sticker-9" },
  { path: "general-file-1611278333837.svg", title: "sticker-10" },
  { path: "general-file-1611278346993.svg", title: "sticker-11" },
  { path: "general-file-1611278362832.svg", title: "sticker-12" },
  { path: "general-file-1611278382689.svg", title: "sticker-13" },
  { path: "general-file-1611278395930.svg", title: "sticker-14" },
  { path: "general-file-1611278412498.svg", title: "sticker-15" },
  { path: "general-file-1611278425224.svg", title: "sticker-16" },
  { path: "general-file-1611278438625.svg", title: "sticker-17" },
  { path: "general-file-1611278456391.svg", title: "sticker-18" },
  { path: "general-file-1611278469198.svg", title: "sticker-19" },
  /* stickers segunda version
  { path: "general-file-1608343013919.svg", title: "sticker-1" },
  { path: "general-file-1608343038837.svg", title: "sticker-2" },
  { path: "general-file-1608343082533.svg", title: "sticker-3" },
  { path: "general-file-1608343091566.svg", title: "sticker-4" },
  { path: "general-file-1608343129702.svg", title: "sticker-5" },
  { path: "general-file-1608343140650.svg", title: "sticker-6" },
  { path: "general-file-1608343151808.svg", title: "sticker-7" },
  { path: "general-file-1608343162620.svg", title: "sticker-8" },
  { path: "general-file-1608343174260.svg", title: "sticker-9" },
  { path: "general-file-1608343252702.svg", title: "sticker-10" },
  { path: "general-file-1608343266479.svg", title: "sticker-11" },
  { path: "general-file-1608343284321.svg", title: "sticker-12" },
  { path: "general-file-1608343295701.svg", title: "sticker-13" },
  { path: "general-file-1608343311314.svg", title: "sticker-14" },
  { path: "general-file-1608343323192.svg", title: "sticker-15" },
  { path: "general-file-1608343334772.svg", title: "sticker-16" },
  { path: "general-file-1608343349485.svg", title: "sticker-17" },
  { path: "general-file-1608343357623.svg", title: "sticker-18" },
  { path: "general-file-1608343375377.svg", title: "sticker-19" },
  */
  /* stickers primera version
  { path: "general-file-1601914394716.svg", title: "sticker-a-tiempo" },
  { path: "general-file-1601914432171.svg", title: "sticker-buen-trabajo" },
  { path: "general-file-1601914523896.svg", title: "sticker-corregir" },
  { path: "general-file-1601955402854.svg", title: "estrella" },
  { path: "general-file-1601955760988.svg", title: "excelente" },
  { path: "general-file-1601956348562.svg", title: "felicitaciones" },
  { path: "general-file-1601956369875.svg", title: "fuera de tiempo" },
  { path: "general-file-1601956388304.svg", title: "great job" },
  { path: "general-file-1601956406505.svg", title: "lograrlo" },
  { path: "general-file-1601956429906.svg", title: "logro" },
  { path: "general-file-1601956444137.svg", title: "mejorar" },
  { path: "general-file-1601956458892.svg", title: "puedes mejorar" },
  { path: "general-file-1601956475513.svg", title: "revisado" },
  { path: "general-file-1601956495110.svg", title: "sigue así" },
  { path: "general-file-1601956513781.svg", title: "sigue esforzandote" },
  { path: "general-file-1601956531709.svg", title: "super" },
  { path: "general-file-1601956547867.svg", title: "tarea" },
  { path: "general-file-1601956562529.svg", title: "trabajo" },
  { path: "general-file-1601956578156.svg", title: "trabajo incompleto" },
  { path: "general-file-1601956596271.svg", title: "vacaciones" },
  { path: "general-file-1601956609885.svg", title: "vas muy bien" },
  { path: "general-file-1601956623297.svg", title: "wow" }*/
];

const iconsTestArray = [
  "pe-7s-album",
  "pe-7s-arc",
  "pe-7s-back-2",
  "pe-7s-bandaid",
  "pe-7s-car",
  "pe-7s-diamond",
  "pe-7s-coffee",
  "pe-7s-eyedropper",
  "pe-7s-delete-user",
  "pe-7s-gym",
  "pe-7s-hammer",
  "pe-7s-headphones",
  "pe-7s-helm",
  "pe-7s-radio",
  "pe-7s-magic-wand",
  "pe-7s-male",
  "pe-7s-next-2",
  "pe-7s-add-user",
  "pe-7s-refresh-cloud",
  "pe-7s-paperclip",
  "pe-7s-ticket",
  "pe-7s-note2",
  "pe-7s-print",
  "pe-7s-network",
]
const iconsArray = [
  "pe-7s-album",
  "pe-7s-arc",
  "pe-7s-back-2",
  "pe-7s-bandaid",
  "pe-7s-car",
  "pe-7s-diamond",
  "pe-7s-door-lock",
  "pe-7s-eyedropper",
  "pe-7s-female",
  "pe-7s-gym",
  "pe-7s-hammer",
  "pe-7s-headphones",
  "pe-7s-helm",
  "pe-7s-hourglass",
  "pe-7s-leaf",
  "pe-7s-magic-wand",
  "pe-7s-male",
  "pe-7s-map-2",
  "pe-7s-next-2",
  "pe-7s-paint-bucket",
  "pe-7s-pendrive",
  "pe-7s-photo",
  "pe-7s-piggy",
  "pe-7s-plugin",
  "pe-7s-refresh-2",
  "pe-7s-rocket",
  "pe-7s-settings",
  "pe-7s-shield",
  "pe-7s-smile",
  "pe-7s-usb",
  "pe-7s-vector",
  "pe-7s-wine",
  "pe-7s-cloud-upload",
  "pe-7s-cash",
  "pe-7s-close",
  "pe-7s-bluetooth",
  "pe-7s-cloud-download",
  "pe-7s-way",
  "pe-7s-close-circle",
  "pe-7s-id",
  "pe-7s-angle-up",
  "pe-7s-wristwatch",
  "pe-7s-angle-up-circle",
  "pe-7s-world",
  "pe-7s-angle-right",
  "pe-7s-volume",
  "pe-7s-angle-right-circle",
  "pe-7s-users",
  "pe-7s-angle-left",
  "pe-7s-user-female",
  "pe-7s-angle-left-circle",
  "pe-7s-up-arrow",
  "pe-7s-angle-down",
  "pe-7s-switch",
  "pe-7s-angle-down-circle",
  "pe-7s-scissors",
  "pe-7s-wallet",
  "pe-7s-safe",
  "pe-7s-volume2",
  "pe-7s-volume1",
  "pe-7s-voicemail",
  "pe-7s-video",
  "pe-7s-user",
  "pe-7s-upload",
  "pe-7s-unlock",
  "pe-7s-umbrella",
  "pe-7s-trash",
  "pe-7s-tools",
  "pe-7s-timer",
  "pe-7s-ticket",
  "pe-7s-target",
  "pe-7s-sun",
  "pe-7s-study",
  "pe-7s-stopwatch",
  "pe-7s-star",
  "pe-7s-speaker",
  "pe-7s-signal",
  "pe-7s-shuffle",
  "pe-7s-shopbag",
  "pe-7s-share",
  "pe-7s-server",
  "pe-7s-search",
  "pe-7s-film",
  "pe-7s-science",
  "pe-7s-disk",
  "pe-7s-ribbon",
  "pe-7s-repeat",
  "pe-7s-refresh",
  "pe-7s-add-user",
  "pe-7s-refresh-cloud",
  "pe-7s-paperclip",
  "pe-7s-radio",
  "pe-7s-note2",
  "pe-7s-print",
  "pe-7s-network",
  "pe-7s-prev",
  "pe-7s-mute",
  "pe-7s-power",
  "pe-7s-medal",
  "pe-7s-portfolio",
  "pe-7s-like2",
  "pe-7s-plus",
  "pe-7s-left-arrow",
  "pe-7s-play",
  "pe-7s-key",
  "pe-7s-plane",
  "pe-7s-joy",
  "pe-7s-photo-gallery",
  "pe-7s-pin",
  "pe-7s-phone",
  "pe-7s-plug",
  "pe-7s-pen",
  "pe-7s-right-arrow",
  "pe-7s-paper-plane",
  "pe-7s-delete-user",
  "pe-7s-paint",
  "pe-7s-bottom-arrow",
  "pe-7s-notebook",
  "pe-7s-note",
  "pe-7s-next",
  "pe-7s-news-paper",
  "pe-7s-musiclist",
  "pe-7s-music",
  "pe-7s-mouse",
  "pe-7s-more",
  "pe-7s-moon",
  "pe-7s-monitor",
  "pe-7s-micro",
  "pe-7s-menu",
  "pe-7s-map",
  "pe-7s-map-marker",
  "pe-7s-mail",
  "pe-7s-mail-open",
  "pe-7s-mail-open-file",
  "pe-7s-magnet",
  "pe-7s-loop",
  "pe-7s-look",
  "pe-7s-lock",
  "pe-7s-lintern",
  "pe-7s-link",
  "pe-7s-like",
  "pe-7s-light",
  "pe-7s-less",
  "pe-7s-keypad",
  "pe-7s-junk",
  "pe-7s-info",
  "pe-7s-home",
  "pe-7s-help2",
  "pe-7s-help1",
  "pe-7s-graph3",
  "pe-7s-graph2",
  "pe-7s-graph1",
  "pe-7s-graph",
  "pe-7s-global",
  "pe-7s-gleam",
  "pe-7s-glasses",
  "pe-7s-gift",
  "pe-7s-folder",
  "pe-7s-flag",
  "pe-7s-filter",
  "pe-7s-file",
  "pe-7s-expand1",
  "pe-7s-exapnd2",
  "pe-7s-edit",
  "pe-7s-drop",
  "pe-7s-drawer",
  "pe-7s-download",
  "pe-7s-display2",
  "pe-7s-display1",
  "pe-7s-diskette",
  "pe-7s-date",
  "pe-7s-cup",
  "pe-7s-culture",
  "pe-7s-crop",
  "pe-7s-credit",
  "pe-7s-copy-file",
  "pe-7s-config",
  "pe-7s-compass",
  "pe-7s-comment",
  "pe-7s-coffee",
  "pe-7s-cloud",
  "pe-7s-clock",
  "pe-7s-check",
  "pe-7s-chat",
  "pe-7s-cart",
  "pe-7s-camera",
  "pe-7s-call",
  "pe-7s-calculator",
  "pe-7s-browser",
  "pe-7s-box2",
  "pe-7s-box1",
  "pe-7s-bookmarks",
  "pe-7s-bicycle",
  "pe-7s-bell",
  "pe-7s-battery",
  "pe-7s-ball",
  "pe-7s-back",
  "pe-7s-attention",
  "pe-7s-anchor",
  "pe-7s-albums",
  "pe-7s-alarm",
  "pe-7s-airplay"
];

//
// //
// // // // For dashboard's charts
// //
//
// Data for Pie Chart
var dataPie = {
  labels: ["80%", "20%"],
  series: [80, 20]
};
var legendPie = {
  names: ["Mensajes leídos ", "Mensajes no leídos"],
  types: ["info", "danger"]
};

var dataPie1 = {
  labels: ["27%", "73%"],
  series: [27, 73]
};
var legendPie1 = {
  names: ["Dipositivos IOS ", "Dispositivos Android"],
  types: ["info", "danger"]
};

// Data for Line Chart
var dataSales = {
  labels: [
    "9:00AM",
    "12:00AM",
    "3:00PM",
    "6:00PM",
    "9:00PM",
    "12:00PM",
    "3:00AM",
    "6:00AM"
  ],
  series: [
    [287, 385, 490, 492, 554, 586, 698, 695],
    [67, 152, 143, 240, 287, 335, 435, 437],
    [23, 113, 67, 108, 190, 239, 307, 308]
  ]
};
var optionsSales = {
  low: 0,
  high: 800,
  showArea: false,
  height: "245px",
  axisX: {
    showGrid: false
  },
  lineSmooth: true,
  showLine: true,
  showPoint: true,
  fullWidth: true,
  chartPadding: {
    right: 50
  }
};
var responsiveSales = [
  [
    "screen and (max-width: 640px)",
    {
      axisX: {
        labelInterpolationFnc: function (value) {
          return value[0];
        }
      }
    }
  ]
];
var legendSales = {
  names: ["Open", "Click", "Click Second Time"],
  types: ["info", "danger", "warning"]
};

// Data for Bar Chart
var dataBar = {
  labels: [
    "Módulo Scan"
  ],
  series: [
    [42],
    [42]
  ]
};
var optionsBar = {
  //seriesBarDistance: 10,
  axisX: {
    showGrid: false
  },
  height: "245px"
};
var responsiveBar = [
  [
    "screen and (max-width: 640px)",
    {
      /*seriesBarDistance: 5,
      axisX: {
        labelInterpolationFnc: function (value) {
          return value[0];
        }
      }*/
    }
  ]
];
var legendBar = {
  names: ["Cantidad de entradas", "Cantidad de salidas"],
  types: ["info", "danger"]
};

module.exports = {
  style, // For notifications (App container and Notifications view)
  thArray,
  tdArray, // For tables (TableList view)
  thRoleArray,
  tdRoleArray,
  thParentArray,
  tdParentArray,
  RoleModulesArray1,
  RoleModulesArray2,
  thParentMessageDetailArray,
  tdParentMessageDetailArray,
  thStudentArray,
  tdStudentArray,
  listaReqHelp,
  listaOpHelp,
  roleOptions,
  roles,
  periodTimes,
  deliveryTypes,
  homeworkStatus,
  qrVal,
  tdArrayDB,
  thMessageArray,
  tdMessageArray,
  tdTypeMessageArray,
  thMessageHistoryArray,
  tdMessageHistoryArray,
  thMessageRecordArray,
  tdMessageRecordArray,
  mensajeError500,
  tdExtras,
  tdStudentsExtras,
  tdScores,
  tdPays,
  stickers,
  tdCourses,
  tdTcs,
  homeworkDetail,
  homework,
  tdMonitoring,
  tdMonitoringDetails,
  tutorMessage,
  iconsArray, // For icons (Icons view)
  iconsTestArray,
  monitorStickers,
  dataPie,
  legendPie,
  dataPie1,
  legendPie1,
  dataSales,
  optionsSales,
  responsiveSales,
  legendSales,
  dataBar,
  optionsBar,
  responsiveBar,
  legendBar // For charts (Dashboard view)
};
