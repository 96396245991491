

import React from 'react'
import {
  Row,
  Col,
} from "react-bootstrap";
import { FormGroup, ControlLabel, FormControl } from "react-bootstrap";
import Button from 'components/CustomButton/CustomButton';
import styles from './styles'
import Loader from 'react-loader-spinner'
import { mensajeError500, monitorStickers } from "variables/Variables.jsx";
import { generalRoute } from "variables/DomainRoutes.jsx";
import axios from 'components/axios';
import ErrorNotification from 'components/Notifications/ErrorNotification.jsx'

class AttributeEdit extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      name: '',
      icon: '',
      val: '',
      isSubmitting: false,
      showNotification: false,
      notificationMessage: '',
      showNewRoleModal: false
    };
  }

  componentDidMount() {
    const { typeData: { name, sticker, value } } = this.props;
    this.setState({
      name,
      icon: sticker,
      val: value
    })
  }

  onChange(e) {
    this.setState({ [e.target.name]: e.target.value })
  }

  handleSubmit = async (e) => {
    e.preventDefault();
    const {
      name,
      icon,
      val
    } = this.state;
    const { typeData: { id } } = this.props;
    if (icon === '') {
      this.setState({
        showNotification: true,
        notificationMessage: "Debe seleccionar una sticker para el atributo."
      });
      return;
    }
    this.setState({ isSubmitting: true });
    try {
      await axios.post(`attribute/edit/${id}`, {
        name,
        sticker: icon,
        value: val
      });
      this.props.returnToList();
    } catch (err) {
      if (typeof err.response !== 'undefined' && typeof err.response.data !== 'undefined') {
        if (err.response.status === 400) {
          this.setState({
            showNotification: true,
            notificationType: "danger",
            notificationMessage: err.response.data.error
          });
        } else {
          this.setState({
            showNotification: true,
            notificationType: "danger",
            notificationMessage: mensajeError500
          });
        }
      }
      console.log('ERROR', err);
    } finally {
      this.setState({ isSubmitting: false });
    }
  }

  setNameIcon(nameIcon) {
    this.setState({ icon: nameIcon })
  }

  render() {
    const {
      icon,
      name,
      val,
      isSubmitting,
      showNotification,
      notificationMessage,
    } = this.state;
    const { returnToList } = this.props;
    return !isSubmitting ? (
      <form onSubmit={ this.handleSubmit }>
        <Row>
          <Col md={ 12 } style={ { padding: "2%" } }>
            <h4>Editar un atributo</h4>
            { showNotification &&
              <ErrorNotification
                notificationMessage={ notificationMessage }
                closeNotification={ () => this.setState({ showNotification: false }) }
              />
            }
            <Row >
              <Col md={ 6 }>
                <FormGroup>
                  <ControlLabel> ELIJA UN STICKER</ControlLabel>
                  <FormControl required type="hidden" name="icon" />
                </FormGroup>
                <Col md={ 12 }>
                  { monitorStickers.map((sticker) => {
                    return (
                      <img
                        alt={ sticker.path }
                        src={ generalRoute + sticker.path }
                        style={ icon === sticker.path ? styles.iconSelect : styles.iconStyle }
                        onClick={ () => this.setNameIcon(sticker.path) }
                        title={ sticker.title }
                      />
                    )
                  }) }
                </Col>
              </Col>
              <Col md={ 6 }>
                <FormGroup>
                  <ControlLabel> NOMBRE </ControlLabel>
                  <FormControl required type="text" name="name" onChange={ e => this.onChange(e) } value={ name } />
                </FormGroup>
              </Col>
              <Col md={ 6 }>
                <FormGroup>
                  <ControlLabel> VALOR </ControlLabel>
                  <FormControl required type="number" name="val" onChange={ e => this.onChange(e) } value={ val } />
                </FormGroup>
              </Col>

            </Row>
            <Row>
              <Col md={ 12 } style={ { marginLeft: "0px" } }>
                <Button bsStyle="success" pullRight fill type="submit" style={ styles.saveButton }>
                  <i className="fa fa-save"></i> Guardar
                </Button>
                <Button bsStyle="danger" pullRight fill type="button" onClick={ returnToList } style={ styles.saveButton }>
                  <i className="fa fa-chevron-left"></i> Regresar
                </Button>
              </Col>
            </Row>
          </Col>
        </Row>
      </form>
    ) : (
        <Loader
          type="Rings"
          color="#00BFFF"
          height={ 100 }
          width={ 100 }
          style={ { display: 'flex', justifyContent: 'center' } }
        />
      );
  }
}
export default AttributeEdit